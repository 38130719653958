import React, { useState } from "react";
import styles from "./WebConferencingTab.module.css";
import Education from "../../../icons/Education";
import Healthcare from "../../../icons/Healthcare";
import ProfessionalServices from "../../../icons/ProfessionalServices";
import img from "./../../../../assets/img/WebConferenceSolutions/Secureweb.png";
import Sales from "../../../icons/Sales";
import ImgTextFlexComponent from "../../../partials/ImgTextFlexComponent/ImgTextFlexComponent";
import effectnumber from "./../../../../assets/img/WebConferenceSolutions/EffectNumber.png";
import effectnumber2 from "./../../../../assets/img/WebConferenceSolutions/EffectNumber2.png";
import effecimg from "./../../../../assets/img/WebConferenceSolutions/bgImgEffect.png";

import TickIcon from "../../../icons/TickIcon";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";

const TabData = [
  {
    icon: <Education />,
    heading: "Education",
  },
  {
    icon: <Healthcare />,
    heading: "Healthcare",
  },
  {
    icon: <ProfessionalServices />,
    heading: "Professional Services",
  },
  {
    icon: <Sales />,
    heading: "Sales",
  },
];

const points = [
  {
    heading: "Vueporteq as your partner",
    text: "With Vueporteq as your partner, we can leap your organization forward with highly effective IT strategies and implement innovative Cloud solutions.",
  },
  {
    heading: "Our Focus",
    text: "Vueporteq is focused on developing highly secure and scalable Mobile and Web based cloud solutions for our Global Clients with complete Vertical support.",
  },
];
const WebConferencingTab = () => {
  const [activeBtn, setActiveBtn] = useState("");
  return (
    <div className={styles.innerContainer}>
      <div>
        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            See how the world uses our Web Conferencing Platform
          </h3>
        </div>

        <div className={`${styles.Show}`}>
          <div className={`${styles.TabContainer} ${styles.Show}`}>
            {TabData.map((item, i) => (
              <div
                className={`${styles.tabBox} ${
                  activeBtn === item.heading ? styles.blueBorder : ""
                }`}
                onClick={() => {
                  setActiveBtn(item.heading);
                }}
                key={i}
              >
                <div className={styles.icon}>{item.icon}</div>
                <h4 className={styles.heading2}>{item.heading}</h4>
              </div>
            ))}
          </div>
          <div className={styles.imgClass}>
            <div className={styles.imgContainer}>
              <img src={img} alt="" className={styles.img} />
            </div>
            <div>
              {points.map((item) => (
                <div className={styles.pointItem}>
                  <span>
                    <TickIcon />
                  </span>
                  <div>
                    <p className={styles.pointItemHeading}>{item.heading}</p>
                    <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                      {item.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={`${styles.Hide}`}>
          {TabData.map((item) => (
            <div style={{ width: "100%" }}>
              <div className={styles.tabBox}>
                <div
                  style={{
                    marginBottom: "2rem",
                    display: "flex",
                    gap: "25px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className={styles.icon}>{item.icon}</div>
                  <h4 className={styles.heading2}>{item.heading}</h4>

                  {activeBtn === item.heading ? (
                    <KeyboardArrowDownIcon
                      style={{ fontSize: "4rem", marginLeft: "auto" }}
                      onClick={() =>
                        setActiveBtn((p) =>
                          p === item.heading ? "" : item.heading
                        )
                      }
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      style={{ fontSize: "4rem", marginLeft: "auto" }}
                      onClick={() =>
                        setActiveBtn((p) =>
                          p === item.heading ? "" : item.heading
                        )
                      }
                    />
                  )}
                </div>
              </div>
              {activeBtn === item.heading && (
                <div className={styles.imgClass}>
                  <div className={styles.imgContainer}>
                    <img src={img} alt="" className={styles.img} />
                  </div>
                  <div>
                    {points.map((item) => (
                      <div className={styles.pointItem}>
                        <span>
                          <TickIcon />
                        </span>
                        <div>
                          <p className={styles.pointItemHeading}>
                            {item.heading}
                          </p>
                          <p
                            className={`fontRoboto ${styles.aboutDescriptionText}`}
                          >
                            {item.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.innerContainer}>
        <div className={styles.BusinessCard}>
          <p className={styles.BusinessHeading}>BUSINESS BENEFITS</p>
          <h3 className={styles.title}>
            Add flexibility, not <br /> complexity
          </h3>
          <h4 className="heading2 caption">
            A virtual meeting platform designed for flexible work.
          </h4>
          <Link to={"/contact"}>
            <button className={`${styles.btn} btnPrimary`}>GET STARTED</button>
          </Link>
        </div>

        <img src={effectnumber} alt="" className={styles.effectnumber} />
        <img src={effectnumber2} alt="" className={styles.effectnumber2} />
        <img src={effecimg} alt="" className={styles.effecimg} />
      </div>
    </div>
  );
};

export default WebConferencingTab;
