import React from "react";
import styles from "./RankHigher.module.css";
import img from "./../../../../assets/img/SEOSupport/Seorank.png";
import { Link } from "react-router-dom";

const RankHigher = () => {
  return (
    <section className={styles.borderBottom}>
      <div className={styles.innerContainer}>
        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            Rank Higher with Better SEO and SMM Support
          </h3>
          <h4 className="heading2 caption">
            With Vueporteq’s Artificial Intelligence & Machine Learning
            services, you can automate processes, strengthen customer relations,
            leverage enhanced analytics, and future-proof your business
            processes; ensuring you stay relevant to the evolving market and
            customer needs. Leveraging our AI & ML consulting and solutions, the
            possibilities become endless, where you can draw new insights and
            tap into new business revenue streams by making the best use of your
            data.
          </h4>
          <Link to={"/contact"}>
            <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
          </Link>
        </div>

        <img src={img} alt="" className={styles.img} />
      </div>
    </section>
  );
};

export default RankHigher;
