// import React from "react";
import styles from "./SalesAutomation.module.css";
import ProphetCRMintegrationComponet from "../../../../partials/nssplPartials/ProphetCRMintegrationComponet/ProphetCRMintegrationComponet";
import OpportunityManagementCard from "../../../../partials/nssplPartials/OpportunityManagementCard/OpportunityManagementCard";
import TickIcon from "../../../../icons/TickIcon";
import img from "./../../../../../assets/img/avidianCrm/SalesAutomation.png";

import img2 from "./../../../../../assets/img/avidianCrm/ProphetCRMintegratio.png";
import img3 from "./../../../../../assets/img/avidianCrm/Campaignmanagerfeature.png";
import Emailtracking from "./../../../../../assets/img/avidianCrm/Email tracking.png";
import salesimg from "./../../../../../assets/img/nssplimg/Sales.png";
import EmbeddedOutlookCard from "../../../../partials/nssplPartials/EmbeddedOutlook/EmbeddedOutlookCard";

const data = [
  {
    text: "Automate when and what actions are taken at every sales stage.",
  },
  {
    text: "Sets reminders for key steps that keep you on track.",
  },
  {
    text: "Group Email function allows you to send personalized group emails through Outlook.",
  },
];
const points = [
  {
    text: "Developers can create and integrate applications that automatically perform tasks in Prophet.",
  },
  {
    text: "Helps to avoid spam filters and to prevent emails from bouncing back.",
  },
  {
    text: "Emails are personalized for each recipient using auto-filled content fields.",
  },
  {
    text: "Keeps track of sent, open, click-through, and unsubscribe rates.",
  },
];
const points1 = [
  {
    text: "Allows you to send out and track email campaigns through Prophet in Outlook.",
  },
  {
    text: "Helps to avoid spam filters and to prevent emails from bouncing back.",
  },
  {
    text: "Emails are personalized for each recipient using auto-filled content fields.",
  },
  {
    text: " Keeps track of sent, open, click-through, and unsubscribe rates.",
  },
];
const points2 = [
  {
    text: "Allows you to send out and track email campaigns through Prophet in Outlook.",
  },
  {
    text: "Helps to avoid spam filters and to prevent emails from bouncing back.",
  },
  {
    text: "Emails are personalized for each recipient using auto-filled content fields.",
  },
  {
    text: "Keeps track of sent, open, click-through, and unsubscribe rates.",
  },
];
const points3 = [
  {
    text: "Link emails, meetings, and tasks to records in your CRM database.",
  },
  {
    text: "Share emails with coworkers without having to forward them.",
  },
  {
    text: "Auto-link future communications to specified contact, company, or opportunity.",
  },
  {
    text: "Delete emails from your Outlook inbox and keep them stored in Prophet CRM.",
  },
];
const points4 = [
  {
    text: "Visualize a relationship map of your connections to unlock warm referrals.",
  },
  {
    text: "Come to understand someone before even reaching out.",
  },
  {
    text: "Pull prospects from websites, web traffic, LinkedIn, and KnowledgeNet.ai’s database.",
  },
  {
    text: "Use AI to write intuitive emails targeted towards specific contacts.",
  },
];

const SalesAutomation = () => {
  return (
    <>
      <section className={styles.section}>
        <div className={styles.innerContainer}>
          <div className={styles.imageContainer}>
            <img src={img} alt="img" />
          </div>

          <div className={styles.textContainer}>
            <h3 className={styles.heading}>Sales automation and workflow.</h3>

            <div className={`caption`}>
              {data.map((item) => (
                <div className={styles.pointItem}>
                  <span>
                    <TickIcon />
                  </span>

                  <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                    {item.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.border}></div>
      </section>
      <ProphetCRMintegrationComponet
        Heading="Prophet CRM integration"
        img={img2}
        points={points}
      />
      <div className={styles.border}></div>
      <ProphetCRMintegrationComponet
        img={img3}
        Heading="Campaign manager feature"
        points={points1}
        classes={styles.flexDirection}
      />
      <div className={styles.border}></div>

      <OpportunityManagementCard
        img={Emailtracking}
        Heading="Email tracking"
        points={points3}
      />

      <EmbeddedOutlookCard
        img={salesimg}
        effectImg={""}
        Heading="AI Sales Inelligence"
        points={points4}
      />
    </>
  );
};

export default SalesAutomation;
