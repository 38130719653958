import React from "react";
import styles from "./../components/app/CrmPage/Main.module.css";
import CrmHeader from "../components/app/CrmPage/CrmHeader";
import CrmSystemsCard from "../components/app/CrmPage/CrmSystemsCard/CrmSystemsCard";
import ChooseVueporteq from "../components/app/CrmPage/ChooseVueporteq/ChooseVueporteq";
import CustomerService from "../components/app/CrmPage/CustomerService/CustomerService";
import CustomizeCard from "../components/app/CrmPage/CustomizeCard/CustomizeCard";
import Pricing from "../components/app/CrmPage/Pricing/Pricing";
import SideBarHeader from "../components/app/CrmPage/SideBarHeader/SideBarHeader";
import CrmIntegrations from "../components/app/CrmPage/CRMIntegrations/CrmIntegrations";

const CrmPages = () => {
  return (
    <>
      <CrmHeader />
      <main id="mainFlexWrapper" className={styles.mainContainer}>
        <div className={styles.left_side}>
          <CrmSystemsCard />
          <ChooseVueporteq />
          <CustomerService />
          <CustomizeCard />
          {/* <Pricing /> */}
          <CrmIntegrations />
        </div>
        {/* <div className={styles.right_side}>
          <SideBarHeader />
        </div> */}
      </main>
    </>
  );
};

export default CrmPages;
