import React from "react";

const CustomerServiceIcon1 = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.3286 18.4715C16.1 12.8572 8.42857 17.1429 2 23.7429L16.2286 32.3143M41.3857 35.5286C47 43.7572 42.7143 51.4286 36.1143 57.8572L27.5429 43.6286"
        stroke="#1CB9C8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.4857 21.5143L38.3429 37.3714M16.2286 32.2714L27.5857 43.6286C36.6714 38.1857 47 32.9143 51.7143 28.0286C61.9143 17.8286 56 3.85713 56 3.85713C56 3.85713 42.0286 -2.05716 31.8286 8.14284C26.9429 12.8571 21.6286 23.2286 16.2286 32.2714Z"
        stroke="#1CB9C8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.8571 17.1429C45.4255 17.1429 45.9705 16.9171 46.3724 16.5153C46.7742 16.1134 47 15.5684 47 15C47 14.4317 46.7742 13.8867 46.3724 13.4848C45.9705 13.0829 45.4255 12.8572 44.8571 12.8572C44.2888 12.8572 43.7438 13.0829 43.3419 13.4848C42.94 13.8867 42.7143 14.4317 42.7143 15C42.7143 15.5684 42.94 16.1134 43.3419 16.5153C43.7438 16.9171 44.2888 17.1429 44.8571 17.1429ZM15.6286 53.3143C13.1429 55.7143 2 57.8572 2 57.8572C2 57.8572 4.14286 46.7143 6.54286 44.2286C7.43038 43.2798 8.58441 42.6219 9.85304 42.3417C11.1217 42.0614 12.4454 42.1718 13.6501 42.6584C14.8547 43.145 15.8838 43.9849 16.6019 45.0677C17.3199 46.1505 17.6933 47.4253 17.6729 48.7243C17.6572 49.5867 17.4681 50.4372 17.1169 51.225C16.7657 52.0128 16.2595 52.7262 15.6286 53.3143Z"
        stroke="#1CB9C8"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CustomerServiceIcon1;
