import React from "react";
import styles from "./CaseStudyHeader.module.css";
import BgContainerImg from "../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../assets/img/CaseStudies/header.png";

const CaseStudyHeader = () => {
  return (
    <>
      <BgContainerImg
        img={img}
        heading="CASE STUDIES"
        isTab={true}
        btns={["HOME ", "CASE STUDIES"]}
      />

      <div className={styles.innerContainer}>
        <h3 className={styles.heading}>
          Dive into our design stories, where we unravel the
          <span className="colorPrimary"> challenges, strategies</span> , and
          <span className="colorPrimary"> creative solutions </span>
          that brought our projects to life
        </h3>
      </div>
    </>
  );
};

export default CaseStudyHeader;
