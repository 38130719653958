// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_mainContainer__K45OR {
  display: flex;
}
.Main_left_side__VBbBs {
  width: 100%;
  margin: auto;
}
/* .right_side {
  width: 20%;
} */
.Main_sideBar__3eQpw {
  border: 2px solid red;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CrmPage/Main.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;;GAEG;AACH;EACE,qBAAqB;AACvB","sourcesContent":[".mainContainer {\n  display: flex;\n}\n.left_side {\n  width: 100%;\n  margin: auto;\n}\n/* .right_side {\n  width: 20%;\n} */\n.sideBar {\n  border: 2px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `Main_mainContainer__K45OR`,
	"left_side": `Main_left_side__VBbBs`,
	"sideBar": `Main_sideBar__3eQpw`
};
export default ___CSS_LOADER_EXPORT___;
