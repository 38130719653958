import React from "react";
import styles from "./FooterHeader.module.css";

const FooterHeader = () => {
  return (
    <div className={styles.textHeadingContainer}>
      <h3 className={styles.heading}>Get Started with NSS Pvt. Ltd.</h3>
      <div className={styles.borderContainer}>
        <span className={styles.BoderBlue}></span>
        <span className={styles.BoderWhite}></span>
      </div>
      <button className={`${styles.btn}`}>GET STARTED</button>
    </div>
  );
};

export default FooterHeader;
