import React, { useState } from "react";
import styles from "./BgContainerImg.module.css";
import parse from "html-react-parser";

// const btns = ["HOME ", "Products", "CRM"];
const BgContainerImg: React.FC<{
  img: string;
  heading: string;
  isTab: boolean;
  btns?: string[];
}> = ({ img, heading, isTab, btns }) => {
  const [activeBtn, setActiveBtn] = useState(btns[2]);

  return (
    <section>
      <div className={styles.bgContainer}>
        <div className={styles.bgImg}>
          <img src={img} alt="img" className={styles.img} />
          <div className={styles.textHeading}>
            <h1>{parse(heading)}</h1>
            {isTab && (
              <div className={styles.buttonContainer}>
                {btns &&
                  btns.map((item, i) => (
                    <>
                      <button
                        className={`${styles.btns} caption ${
                          activeBtn === item ? styles.activeBtnClass : ""
                        }`}
                        onClick={() => {
                          setActiveBtn(item);
                        }}
                        key={i}
                      >
                        {item}
                      </button>
                      {i !== btns.length - 1 && (
                        <span className={styles.border}>/</span>
                      )}
                    </>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BgContainerImg;
