import React from "react";

const AiDrivenAutomationIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 10H16.25V11.25H13.75V10ZM13.75 5H16.25V6.25H13.75V5ZM13.75 12.5H16.25V13.75H13.75V12.5Z"
        fill="#1BA9B7"
      />
      <path
        d="M26.25 15V11.25C22.4392 11.2488 18.7469 12.5747 15.8075 15H14.1925C11.2531 12.5747 7.5608 11.2488 3.75 11.25V15C3.41848 15 3.10054 15.1317 2.86612 15.3661C2.6317 15.6005 2.5 15.9185 2.5 16.25V18.75C2.5 19.0815 2.6317 19.3995 2.86612 19.6339C3.10054 19.8683 3.41848 20 3.75 20V25C7.92765 24.9958 11.9502 26.5825 15 29.4375C18.0532 26.5878 22.0735 25.0021 26.25 25V20C26.5815 20 26.8995 19.8683 27.1339 19.6339C27.3683 19.3995 27.5 19.0815 27.5 18.75V16.25C27.5 15.9185 27.3683 15.6005 27.1339 15.3661C26.8995 15.1317 26.5815 15 26.25 15Z"
        fill="#1BA9B7"
      />
      <path
        d="M11.25 6.25C11.9404 6.25 12.5 5.69036 12.5 5C12.5 4.30964 11.9404 3.75 11.25 3.75C10.5596 3.75 10 4.30964 10 5C10 5.69036 10.5596 6.25 11.25 6.25Z"
        fill="#1BA9B7"
      />
      <path
        d="M18.75 6.25C19.4404 6.25 20 5.69036 20 5C20 4.30964 19.4404 3.75 18.75 3.75C18.0596 3.75 17.5 4.30964 17.5 5C17.5 5.69036 18.0596 6.25 18.75 6.25Z"
        fill="#1BA9B7"
      />
      <path
        d="M20 10H10C9.00574 9.99901 8.05249 9.6036 7.34945 8.90055C6.6464 8.19751 6.25099 7.24426 6.25 6.25V3.75C6.25099 2.75574 6.6464 1.80249 7.34945 1.09945C8.05249 0.3964 9.00574 0.000992769 10 0L20 0C20.9943 0.000992769 21.9475 0.3964 22.6506 1.09945C23.3536 1.80249 23.749 2.75574 23.75 3.75V6.25C23.749 7.24426 23.3536 8.19751 22.6506 8.90055C21.9475 9.6036 20.9943 9.99901 20 10ZM10 2.5C9.66848 2.5 9.35054 2.6317 9.11612 2.86612C8.8817 3.10054 8.75 3.41848 8.75 3.75V6.25C8.75 6.58152 8.8817 6.89946 9.11612 7.13388C9.35054 7.3683 9.66848 7.5 10 7.5H20C20.3315 7.5 20.6495 7.3683 20.8839 7.13388C21.1183 6.89946 21.25 6.58152 21.25 6.25V3.75C21.25 3.41848 21.1183 3.10054 20.8839 2.86612C20.6495 2.6317 20.3315 2.5 20 2.5H10Z"
        fill="#1BA9B7"
      />
    </svg>
  );
};

export default AiDrivenAutomationIcon;
