// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MtcHelpSectionCircleBgCard_section__8lzwz {
  /* border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #e7e7e7; */
}

.MtcHelpSectionCircleBgCard_card__rKpyy {
  max-width: 143.6rem;
  margin: auto;
  width: 85%;
  display: flex;
  align-items: center;
  column-gap: 3%;
  position: relative;
  z-index: 10;
  justify-content: space-between;
}

.MtcHelpSectionCircleBgCard_textContainer__By5Q0 {
  max-width: 53.2rem;
}

.MtcHelpSectionCircleBgCard_text__0pr3L {
  margin-top: 12px;
  color: #666;
  line-height: 23.66px;
  font-weight: 500;
}
.MtcHelpSectionCircleBgCard_imgContainer__yN7\\+C {
  position: relative;
  width: 50%;
}

.MtcHelpSectionCircleBgCard_img__M\\+pQE {
  width: 100%;
  position: relative;
  z-index: 2;
}

.MtcHelpSectionCircleBgCard_imgOverlay__PyxpY {
  width: 100rem;
  height: 30rem;
  border-top-left-radius: 16rem;
  border-bottom-left-radius: 16rem;
  background-color: #ffe2cf;
  position: absolute;
  top: 50%;
  left: -4rem;
  transform: translateY(-50%);
  z-index: -1;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/NssplMtcPage/MtcPageComponents/MtcHelpSection/MtcHelpSectionCircleBgCard/MtcHelpSectionCircleBgCard.module.css"],"names":[],"mappings":"AAAA;EACE;;0BAEwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,aAAa;EACb,6BAA6B;EAC7B,gCAAgC;EAChC,yBAAyB;EACzB,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,2BAA2B;EAC3B,WAAW;AACb","sourcesContent":[".section {\n  /* border-top: 1px solid;\n  border-bottom: 1px solid;\n  border-color: #e7e7e7; */\n}\n\n.card {\n  max-width: 143.6rem;\n  margin: auto;\n  width: 85%;\n  display: flex;\n  align-items: center;\n  column-gap: 3%;\n  position: relative;\n  z-index: 10;\n  justify-content: space-between;\n}\n\n.textContainer {\n  max-width: 53.2rem;\n}\n\n.text {\n  margin-top: 12px;\n  color: #666;\n  line-height: 23.66px;\n  font-weight: 500;\n}\n.imgContainer {\n  position: relative;\n  width: 50%;\n}\n\n.img {\n  width: 100%;\n  position: relative;\n  z-index: 2;\n}\n\n.imgOverlay {\n  width: 100rem;\n  height: 30rem;\n  border-top-left-radius: 16rem;\n  border-bottom-left-radius: 16rem;\n  background-color: #ffe2cf;\n  position: absolute;\n  top: 50%;\n  left: -4rem;\n  transform: translateY(-50%);\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `MtcHelpSectionCircleBgCard_section__8lzwz`,
	"card": `MtcHelpSectionCircleBgCard_card__rKpyy`,
	"textContainer": `MtcHelpSectionCircleBgCard_textContainer__By5Q0`,
	"text": `MtcHelpSectionCircleBgCard_text__0pr3L`,
	"imgContainer": `MtcHelpSectionCircleBgCard_imgContainer__yN7+C`,
	"img": `MtcHelpSectionCircleBgCard_img__M+pQE`,
	"imgOverlay": `MtcHelpSectionCircleBgCard_imgOverlay__PyxpY`
};
export default ___CSS_LOADER_EXPORT___;
