import React from "react";

const SendIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1969 0.859375C13.6617 0.585938 14.2633 0.96875 14.1539 1.51562L12.1852 13.3281C12.1305 13.7656 11.6656 14.0117 11.2828 13.8477L7.89219 12.3984L6.14219 14.5312C5.75938 14.9961 4.99375 14.75 4.99375 14.0938V11.8789L11.5563 3.86719C11.693 3.70312 11.4742 3.51172 11.3375 3.64844L3.48984 10.5664L0.564063 9.33594C0.0718751 9.14453 0.0171876 8.43359 0.509375 8.16016L13.1969 0.859375Z"
        fill="white"
      />
    </svg>
  );
};

export default SendIcon;
