import React from "react";

const ProfessionalServices = () => {
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8693 19.5417V16.3201C22.8693 15.5533 23.1429 14.8964 23.6901 14.3492C24.2373 13.802 24.8942 13.5284 25.661 13.5284H41.339C42.1058 13.5284 42.7628 13.802 43.3099 14.3492C43.8571 14.8964 44.1307 15.5533 44.1307 16.3201V19.5417H49.4125C49.9559 19.5417 50.4491 19.6859 50.8921 19.9744C51.3332 20.2629 51.6561 20.663 51.8608 21.1748L58.1002 35.2811C58.2751 35.6906 58.4054 36.1261 58.491 36.5876C58.5803 37.0492 58.625 37.4595 58.625 37.8187V50.25C58.625 51.0187 58.3514 51.6756 57.8042 52.2209C57.2571 52.7662 56.6001 53.0398 55.8333 53.0417H11.1667C10.398 53.0417 9.74106 52.7681 9.19575 52.2209C8.65044 51.6738 8.37686 51.0168 8.375 50.25V37.8383C8.375 37.3581 8.41874 36.9142 8.50621 36.5066C8.59368 36.0972 8.72489 35.6887 8.89983 35.2811L15.1392 21.1748C15.3439 20.663 15.6668 20.2629 16.1079 19.9744C16.5509 19.6859 17.0441 19.5417 17.5875 19.5417H22.8693ZM25.661 19.5417H41.339V16.3201H25.661V19.5417ZM20.9375 35.4319V32.6402H23.7292V35.4319H43.2708V32.6402H46.0625V35.4319H55.1243L49.4125 22.3334H17.5875L11.8758 35.4319H20.9375ZM20.9375 38.2235H11.1667V50.25H55.8333V38.2235H46.0625V41.0152H43.2708V38.2235H23.7292V41.0152H20.9375V38.2235Z"
        fill="#1CB9C8"
      />
    </svg>
  );
};

export default ProfessionalServices;
