import React from "react";
import styles from "./EmbeddedOutlook.module.css";
import TickIcon from "../../../icons/TickIcon";

const EmbeddedOutlookCard: React.FC<{
  Heading: string;
  img: string;
  effectImg: string;
  points: { text: string }[];
}> = ({ Heading, img, effectImg, points }) => {
  return (
    <section className={styles.section}>
      <div className={styles.innerContainer}>
        <div className={styles.imageContainer}>
          <img src={img} alt="img" />
          {/* <img src={effectImg} alt="" className={styles.effectImg} /> */}
        </div>

        <div className={styles.textContainer}>
          <h3 className={styles.heading}>{Heading}</h3>

          <div className={`caption`}>
            {points.map((item) => (
              <div className={styles.pointItem}>
                <span>
                  <TickIcon />
                </span>

                <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.border}></div>
    </section>
  );
};

export default EmbeddedOutlookCard;
