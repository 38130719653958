const backend = "https://www.nssplindia.com/backend";
const getcontacturl = backend + "/api/contactForm/getContactFormDetails";
const postcontacturl = backend + "/api/contactForm/addContactFormDetails";
const getAllCaseStudies = backend + "/api/caseStudy/getCaseStudy";
const getCaseStudyById = backend + "/api/caseStudy/getCaseStudyById";
const getHomeCaseStudy = backend + "/api/caseStudy/getCaseStudyHome";
const phoneNumber = "+918448229553";
const workingDays = "Mon - Fri / 10AM - 6PM";
const companyEmail = "sales@vueporteq.ae";

export {
  backend,
  getcontacturl,
  postcontacturl,
  getAllCaseStudies,
  getCaseStudyById,
  getHomeCaseStudy,
  phoneNumber,
  workingDays,
  companyEmail,
};
