import React from "react";
import styles from "./../../../../components/app/SEO/SeoServices/SeoServices.module.css";
import TextHeading from "../../../partials/TextHeading/TextHeading";
import ErpIcon from "../../../icons/ErpIcons/ErpIcon";
import CustomerServiceIcon2 from "../../../icons/CustomerServiceIcon2";
import CustomerServiceIcon3 from "../../../icons/CustomerServiceIcon3";
import CustomerServiceIcon1 from "../../../icons/CustomerServiceIcon1";

const data = [
  {
    icon: <CustomerServiceIcon1 />,
    heading: "Service",
    pay: `Keep close track of your human capital and efficiently manage your resources to boost productivity.`,
  },
  {
    icon: <CustomerServiceIcon3 />,
    heading: "Industry",
    pay: `Gain visibility over your value chains, reduce operational complexity, and improve customer satisfaction.`,
  },
  {
    icon: <CustomerServiceIcon3 />,
    heading: "Public Sector",
    pay: `At the service of users, the public sector plays an essential role in the support, and development of resources and territories.`,
  },
  {
    icon: <CustomerServiceIcon2 />,
    heading: "Retail",
    pay: `Manage your purchase and replenishment orders. Facilitate data exchange with suppliers and logistics service providers.`,
  },
];
const VersatileERPs = () => {
  return (
    <section style={{ backgroundColor: "#F7F7F7" }}>
      <div className={styles.innerContainer}>
        <TextHeading
          heading="One of the most versatile ERPs on the market"
          text="Our broader objective is to make quality tech available, accessible, and affordable to all. In doing so, we meet the needs of organizations of all sizes."
        />
        <div className={styles.imgContainer}>
          {data.map((item) => (
            <div className={styles.card}>
              {item.icon}
              <h4 className={`${styles.text} body1 heading2`}>
                {item.heading}
              </h4>
              <p className={`${styles.pay} heading2`}>{item.pay}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VersatileERPs;
