import React from "react";

const SecurityIcon = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.916 10C20.916 15.55 16.5427 20.74 10.666 22C4.78935 20.74 0.416016 15.55 0.416016 10V4L10.666 0L20.916 4V10ZM10.666 20C14.9368 19 18.6382 14.54 18.6382 10.22V5.3L10.666 2.18L2.69379 5.3V10.22C2.69379 14.54 6.39518 19 10.666 20ZM13.8549 10V8.5C13.8549 7.1 12.2605 6 10.666 6C9.07157 6 7.47713 7.1 7.47713 8.5V10C6.79379 10 6.11046 10.6 6.11046 11.2V14.7C6.11046 15.4 6.79379 16 7.47713 16H13.741C14.5382 16 15.2216 15.4 15.2216 14.8V11.3C15.2216 10.6 14.5382 10 13.8549 10ZM12.3743 10H8.95768V8.5C8.95768 7.7 9.7549 7.2 10.666 7.2C11.5771 7.2 12.3743 7.7 12.3743 8.5V10Z"
        fill="#C81C79"
      />
    </svg>
  );
};

export default SecurityIcon;
