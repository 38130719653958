import React from "react";
import WebConferenceSolutionsHeader from "../components/app/WebConferenceSolutions/WebConferenceSolutionsHeader";
import ConnectingTeams from "../components/app/WebConferenceSolutions/ConnectingTeams/ConnectingTeams";
import WebConferencingTab from "../components/app/WebConferenceSolutions/WebConferencingTab/WebConferencingTab";
import VueporteqITSolutions from "../components/app/WebConferenceSolutions/VueporteqITSolutions/VueporteqITSolutions";
import Pricing from "../components/app/CrmPage/Pricing/Pricing";

const WebConferenceSolutions = () => {
  return (
    <>
      <WebConferenceSolutionsHeader />
      <ConnectingTeams />
      <WebConferencingTab />
      <VueporteqITSolutions />
      {/* <Pricing /> */}
    </>
  );
};

export default WebConferenceSolutions;
