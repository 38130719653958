// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sport_InnerContainer__EnCzt {
  max-width: 1400px;
  margin: auto;
  margin-top: 5rem;
  display: flex;
  position: relative;
}
.Sport_imgContainer__a1ueW {
  max-width: 80rem;
  margin: auto;
  position: absolute;
  top: -252px;
  right: -18%;
  z-index: -1;
}
.Sport_imgContainer__a1ueW img {
  width: 100%;
}
@media (max-width: 1450px) {
  .Sport_InnerContainer__EnCzt {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .Sport_imgContainer__a1ueW img {
    max-width: 80rem;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .Sport_imgContainer__a1ueW img {
    max-width: 70rem;
  }
}
@media (max-width: 850px) {
  .Sport_imgContainer__a1ueW img {
    max-width: 60rem;
  }
}
@media (max-width: 768px) {
  .Sport_InnerContainer__EnCzt {
    flex-direction: column-reverse;
  }
  .Sport_imgContainer__a1ueW {
    top: -200px;
    /* right: 76px; */
  }
}
@media (max-width: 580px) {
  .Sport_imgContainer__a1ueW {
    top: -190px;
    max-width: 50rem;
  }
}
@media (max-width: 450px) {
  .Sport_imgContainer__a1ueW {
    top: -160px;
    max-width: 35rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CaseStudyPage/sport/Sport.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,WAAW;IACX,iBAAiB;EACnB;AACF;AACA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF;AACA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":[".InnerContainer {\n  max-width: 1400px;\n  margin: auto;\n  margin-top: 5rem;\n  display: flex;\n  position: relative;\n}\n.imgContainer {\n  max-width: 80rem;\n  margin: auto;\n  position: absolute;\n  top: -252px;\n  right: -18%;\n  z-index: -1;\n}\n.imgContainer img {\n  width: 100%;\n}\n@media (max-width: 1450px) {\n  .InnerContainer {\n    margin-left: 3rem;\n    margin-right: 3rem;\n  }\n  .imgContainer img {\n    max-width: 80rem;\n    width: 100%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .imgContainer img {\n    max-width: 70rem;\n  }\n}\n@media (max-width: 850px) {\n  .imgContainer img {\n    max-width: 60rem;\n  }\n}\n@media (max-width: 768px) {\n  .InnerContainer {\n    flex-direction: column-reverse;\n  }\n  .imgContainer {\n    top: -200px;\n    /* right: 76px; */\n  }\n}\n@media (max-width: 580px) {\n  .imgContainer {\n    top: -190px;\n    max-width: 50rem;\n  }\n}\n@media (max-width: 450px) {\n  .imgContainer {\n    top: -160px;\n    max-width: 35rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InnerContainer": `Sport_InnerContainer__EnCzt`,
	"imgContainer": `Sport_imgContainer__a1ueW`
};
export default ___CSS_LOADER_EXPORT___;
