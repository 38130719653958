// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../../assets//img/WebConferenceSolutions//Effect.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VueporteqITSolutions_VueporteqITSolutionsimg__KQiUM {
  max-width: 60rem;
  margin: auto;
  transform: translateY(-80px);
  margin-bottom: 5rem;
  position: relative;
}
.VueporteqITSolutions_VueporteqITSolutionsimg__KQiUM img {
  width: 100%;
}

.VueporteqITSolutions_textContainer__LtC3Q {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 40rem;
  width: 100%;
}
.VueporteqITSolutions_TextHeading__PMeZW {
  font-size: 2.5rem;
  line-height: 2.8rem;
}
.VueporteqITSolutions_btn__e0ZRc {
  font-size: 1rem;
  padding: 1.2rem 3rem;
}

.VueporteqITSolutions_effectImg__i3QFL {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100%;
  padding-bottom: 19rem;
  background-repeat: no-repeat;
  padding: 0px 2rem;
  position: relative;
}
@media (max-width: 800px) {
  .VueporteqITSolutions_effectImg__i3QFL {
    padding-bottom: 1rem;
  }
}
@media (max-width: 640px) {
  .VueporteqITSolutions_TextHeading__PMeZW {
    font-size: 2rem;
  }
  .VueporteqITSolutions_btn__e0ZRc {
    font-size: 1rem;
    padding: 1rem 2rem;
  }
}
@media (max-width: 500px) {
  .VueporteqITSolutions_TextHeading__PMeZW {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .VueporteqITSolutions_btn__e0ZRc {
    font-size: 0.8rem;
    padding: 0.8rem 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/WebConferenceSolutions/VueporteqITSolutions/VueporteqITSolutions.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,4BAA4B;EAC5B,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,yDAAqF;EACrF,qBAAqB;EACrB,qBAAqB;EACrB,4BAA4B;EAC5B,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE;IACE,oBAAoB;EACtB;AACF;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;IACf,kBAAkB;EACpB;AACF;AACA;EACE;IACE,iBAAiB;IACjB,iBAAiB;EACnB;EACA;IACE,iBAAiB;IACjB,oBAAoB;EACtB;AACF","sourcesContent":[".VueporteqITSolutionsimg {\n  max-width: 60rem;\n  margin: auto;\n  transform: translateY(-80px);\n  margin-bottom: 5rem;\n  position: relative;\n}\n.VueporteqITSolutionsimg img {\n  width: 100%;\n}\n\n.textContainer {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n  max-width: 40rem;\n  width: 100%;\n}\n.TextHeading {\n  font-size: 2.5rem;\n  line-height: 2.8rem;\n}\n.btn {\n  font-size: 1rem;\n  padding: 1.2rem 3rem;\n}\n\n.effectImg {\n  background-image: url(\"./../../../../assets//img/WebConferenceSolutions//Effect.png\");\n  background-size: 100%;\n  padding-bottom: 19rem;\n  background-repeat: no-repeat;\n  padding: 0px 2rem;\n  position: relative;\n}\n@media (max-width: 800px) {\n  .effectImg {\n    padding-bottom: 1rem;\n  }\n}\n@media (max-width: 640px) {\n  .TextHeading {\n    font-size: 2rem;\n  }\n  .btn {\n    font-size: 1rem;\n    padding: 1rem 2rem;\n  }\n}\n@media (max-width: 500px) {\n  .TextHeading {\n    font-size: 1.5rem;\n    line-height: 2rem;\n  }\n  .btn {\n    font-size: 0.8rem;\n    padding: 0.8rem 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VueporteqITSolutionsimg": `VueporteqITSolutions_VueporteqITSolutionsimg__KQiUM`,
	"textContainer": `VueporteqITSolutions_textContainer__LtC3Q`,
	"TextHeading": `VueporteqITSolutions_TextHeading__PMeZW`,
	"btn": `VueporteqITSolutions_btn__e0ZRc`,
	"effectImg": `VueporteqITSolutions_effectImg__i3QFL`
};
export default ___CSS_LOADER_EXPORT___;
