import React, { CSSProperties } from "react";
import styles from "./MtcHelpSectionCircleBgCard.module.css";

const MtcHelpSectionCircleBgCard: React.FC<{
  img: string;
  imageClasses?: string;
  heading: string;
  text: string;
  imgMaxWidth?: string;
  showBlueOverlay?: boolean;
  cardClasses?: string;
}> = ({
  imageClasses,
  img,
  heading,
  text,
  imgMaxWidth,
  showBlueOverlay,
  cardClasses,
}) => {
  return (
    <section className={styles.section}>
      <div className={`${styles.card} ${cardClasses ? cardClasses : ""}`}>
        <div
          style={{ maxWidth: imgMaxWidth ? imgMaxWidth : "100%" }}
          className={styles.imgContainer}
        >
          <img
            src={img}
            alt="img"
            className={`${styles.img} ${imageClasses ? imageClasses : ""}`}
          />

          {showBlueOverlay && <div className={styles.imgOverlay} />}
        </div>

        <div className={styles.textContainer}>
          <h3 className="fontMontserrat">{heading}</h3>
          <p className={`${styles.text} caption fontRoboto`}>{text}</p>
        </div>
      </div>
    </section>
  );
};

export default MtcHelpSectionCircleBgCard;
