import styles from "./FiservOndot.module.css";
import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../../assets/img/CaseStudies/FiservOndotHeader.png";
import FiservOndot2 from "./../../../../assets/img/CaseStudies/FiservOndot2.png";
import CaseStudyPageComponet, {
  CaseStudyFlexComponet,
} from "../CaseStudyPageComponet/CaseStudyPageComponet";
import FiservOndot1 from "./../../../../assets/img/CaseStudies/FiservOndot1.png";
import One from "./../../../../assets/img/CaseStudies/AllIcons/One.svg";
import Two from "./../../../../assets/img/CaseStudies/AllIcons/Two.svg";
import Three from "./../../../../assets/img/CaseStudies/AllIcons/Three.svg";
import Four from "./../../../../assets/img/CaseStudies/AllIcons/Four.svg";
import FiservOndot3 from "./../../../../assets/img/CaseStudies/FiservOndot3.png";
import FiservOndotBgImg from "./../../../../assets/img/CaseStudies/FiservOndotBgImg.png";
import FiservOndotScreenshots from "./../../../../assets/img/CaseStudies/FiservOndotScreenshots.png";
import Cicon from "../../../icons/Cicon";
import Antivirus from "../../../icons/Antivirus";
import CaseIcon1 from "../../../icons/CaseIcon1";
import Apple from "../../../icons/Apple";

const ImgTextData = [
  {
    problem: "01",
    problemHeading: "problem",
    img: FiservOndot2,
    circleBgColor: "#f9c18a85",
    heading: "Problem",
    IconColor: "#F9C18A",
    list: [
      "Development of mobile app with latest upgrades and UI",
      "API integration",
      "Intergration of security featutes on the App as per International Banking Norms",
      "Customer Facing consultancy",
      "White Labeling of the Apps for various Banks Internationaly",
      "Development in secure confidential environment to conform banking security mandate",
      "Reduce Turn Around Time - TAT",
    ],
  },
  {
    problem: "02",
    problemHeading: "Solutions",
    img: FiservOndot2,
    circleBgColor: "#f9c18a85",
    heading: "Solutions",
    IconColor: "#F9C18A",
    list: [
      "Professional development and Deployment on tight timelines",
      "Weekly updates, resource onboarding & allocations, and technical discussions available by onshore account manager",
      "All development project processes overseen by offshore PM",
      "Provided secured work area to confirm banking needs",
    ],
  },
];
const Data = [
  {
    img: One,
    pay: `Constant Upgrade / Deployment of Applicaton on all platforms with Quality standards for Banking Apps.`,
    BgColor: "#FDEDDEA1",
  },
  {
    img: Two,
    pay: `Weekly Sprint management professional for quality applications.`,
    BgColor: "#EDEBFB",
  },
];
const Data1 = [
  {
    img: Three,
    pay: `Improved delivery times, TAT is reduced considerable due to quality and fast development processses.`,
    BgColor: "#EDEBFB",
  },
  {
    img: Four,
    pay: `Active Client since 2013 till date..`,
    BgColor: "#E5FFEC",
  },
];
const FiservOndot = () => {
  return (
    <section>
      <BgContainerImg
        img={img}
        heading="FISERV - ONDOT"
        isTab={true}
        btns={["HOME ", "CASE STUDIES", "FISERV - ONDOT"]}
      />
      <div className={styles.InnerContainer}>
        <CaseStudyFlexComponet
          heading="FISERV - ONDOT"
          heading1="Project Overview"
          text="Constant Upgrade / Deployment of Applicaton on all platforms with
        Quality standards for Banking Apps. Improved delivery times, TAT is
        reduced considerable due to quality and fast development processs."
          color={{
            mainHeading: "#f9c18a",
            manHeadingLine: "#e19b55",
            heading: "#ff9955",
            iconBg: "rgb(248, 241, 234)",
            // iconColor: "red",
            imgColor: "#F2994A",
          }}
          iconLog={[<CaseIcon1 />, <Cicon />, <Antivirus />, <Apple />]}
        />
        <div className={styles.imgContainer}>
          <img src={FiservOndot1} alt="" />
        </div>
      </div>
      <CaseStudyPageComponet
        ImgTextData={ImgTextData}
        Data1={Data1}
        Data={Data}
        color={{
          problem: "#f9c18a",
          circle: "rgba(249, 193, 138, 0.52)",
          img: FiservOndot3,
          ScreenshotsImg: FiservOndotScreenshots,
          processIconColor: "#FFB369",
        }}
        imgBlue={FiservOndotBgImg}
      />
    </section>
  );
};

export default FiservOndot;
