import React from "react";

const TwitterIcon = () => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5321 0.625H17.2157L11.3532 7.29688L18.25 16.375H12.8495L8.62062 10.8686L3.781 16.375H1.09475L7.36587 9.23938L0.75 0.625H6.287L10.1107 5.658L14.5321 0.625ZM13.5906 14.7755H15.0781L5.4785 2.1405H3.8825L13.5906 14.7755Z"
        fill="black"
      />
    </svg>
  );
};

export default TwitterIcon;
