import React from "react";
import styles from "./AllNewAgain.module.css";
import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";
import CaseStudyPageComponet, {
  CaseStudyFlexComponet,
} from "../CaseStudyPageComponet/CaseStudyPageComponet";
import img from "./../../../../assets/img/CaseStudies/AllNewAgain/header.png";
import img1 from "./../../../../assets/img/CaseStudies/AllNewAgain/img1.png";
import img2 from "./../../../../assets/img/CaseStudies/AllNewAgain/img2.png";
import img3 from "./../../../../assets/img/CaseStudies/AllNewAgain/img3.png";
import img4 from "./../../../../assets/img/CaseStudies/AllNewAgain/img4.png";
import img5 from "./../../../../assets/img/CaseStudies/AllNewAgain/img5.png";

import Five from "./../../../../assets/img/CaseStudies/AllIcons/Five.svg";
import Icon1 from "./../../../../assets/img/CaseStudies/sports/Icon1.svg";
import Icon2 from "./../../../../assets/img/CaseStudies/sports/Icon2.svg";
import Icon3 from "./../../../../assets/img/CaseStudies/sports/Icon3.svg";
import Icon4 from "./../../../../assets/img/CaseStudies/sports/Icon4.svg";
import Icon5 from "./../../../../assets/img/CaseStudies/sports/Icon5.svg";
import Icon6 from "./../../../../assets/img/CaseStudies/sports/Icon6.svg";
import Icon7 from "./../../../../assets/img/CaseStudies/sports/Icon7.svg";
import FiservOndotBgImg from "./../../../../assets/img/CaseStudies/FiservOndotBgImg.png";
import ReactIcon from "../../../icons/CaseStudy/ReactIcon";
import NodeJsIcon from "../../../icons/CaseStudy/NodeJsIcon";
import MogoDbIcon from "../../../icons/CaseStudy/MogoDbIcon";
import Azure from "../../../icons/CaseStudy/Azure";
import Apple from "../../../icons/Apple";
import Antivirus from "../../../icons/Antivirus";
import DotNetIcon from "../../../icons/CaseStudy/DotNetIcon";
import MySql from "../../../icons/CaseStudy/MySql";
import SqlServer from "../../../icons/CaseStudy/SqlServer";

const ImgTextData = [
  {
    problem: "01",
    problemHeading: "problem",
    img: img2,
    circleBgColor: "red",
    heading: "Problem",
    IconColor: "#FFE69585",
    list: [
      "Create a mobile app to transform excel and other manual files and folders to complete digital form.",
      "Create a web application to monitor the progress of actual work being done across multiple sites by multiple workers and technicians Android App for clients to store and review data with doctors",
      "Conversion of excel sheet formulae that they use into algorithms so that required amount of raw material can be ordered to avoid wastage's.",
      "Make a dashboard for administrators with relevant real time data.",
      "Capture real time responses regarding the various works in progress.",
      "Form can be submitted using the mobile app and needs to be saved in the form of a PDF",
    ],
  },
  {
    problem: "02",
    problemHeading: "Solutions",
    img: img3,
    circleBgColor: "#f9c18a85",
    heading: "Solutions",
    IconColor: "#FFE69585",
    list: [
      "Development of the progressive web application and web portal.",
      "Progressive web app that works on iOS and android phones.",
      "Tight sprints for support and development.",
      "Project live duration 6 months – various different features are still being added on continual basis to ease their employees task.",
      "Ensure client and NSS development teams work seamlessly",
      "Extensive data processing and client engagements.",
    ],
  },
];
const Data = [
  {
    img: Five,
    pay: "Flawless user ready progressive web application",
    BgColor: "#FDEDDEA1",
  },
  {
    img: Icon1,
    pay: "Technicians can draw sketches showing positions of various equipment placed across the room so that employees at the office can track the numbers of equipment in use.",
    BgColor: "#E5FFEC",
  },
  {
    img: Icon2,
    pay: "Using the portal an estimate can be created for bid for the proposed work.",
    BgColor: "#EDEBFB",
  },
];
const Data1 = [
  {
    img: Icon4,
    pay: "Potential customers can be entered into the system in the form of Lead and can be assigned to a manager who can then take the charge of the proposed work.",
    BgColor: "#EDEBFB",
  },
  {
    img: Icon5,
    pay: "Various invoices can be uploaded into the system in the form of purchase order to track the expenses.",
    BgColor: "#E5FFEC",
  },
];
const AllNewAgain = () => {
  return (
    <section>
      <BgContainerImg
        img={img}
        heading="ALL NEW AGAIN"
        isTab={true}
        btns={["HOME ", "CASE STUDIES", "ALL NEW AGAIN"]}
      />
      <div className={`${styles.InnerContainer}  `}>
        <CaseStudyFlexComponet
          heading="ALL NEW AGAIN"
          heading1="Project Overview"
          text="All New Again app is a social app for both individuals and entrepreneurs over which they can share their posts, videos, and thoughts and even promote their respective business links."
          color={{
            mainHeading: "#FFD754",
            manHeadingLine: "#F8E9B8",
            heading: "#FFD754",
            iconBg: "#FDEDDEA1",
            // iconColor: "#ff9955",
            imgColor: "#FFD754",
          }}
          iconLog={[
            <Antivirus />,
            <Apple />,
            <ReactIcon />,
            <MogoDbIcon />,
            <DotNetIcon />,
            <SqlServer />,
            <Azure />,
          ]}
        />
        <div className={styles.imgContainer}>
          <img src={img1} alt="" />
        </div>
      </div>
      <CaseStudyPageComponet
        ImgTextData={ImgTextData}
        Data1={Data1}
        Data={Data}
        color={{
          problem: "#FFE6954D",
          circle: "#FFE69585",
          img: img4,
          ScreenshotsImg: img5,
          processIconColor: "#FFE69585",
        }}
        imgBlue={FiservOndotBgImg}
      />
    </section>
  );
};

export default AllNewAgain;
