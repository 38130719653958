// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialMedia_sectionBgColor__SXhHy {
  background-color: #f7f7f7;
  border-bottom: 1px solid #e7e7e7;
  padding-top: 7rem;
}

.SocialMedia_innerContainer__uzn9O {
  max-width: 150.9rem;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 3.4rem;
}

.SocialMedia_TextContainer__kH1OW {
  max-width: 50rem;
}
.SocialMedia_heading__MSA4F {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
  line-height: 3.9rem;
}

.SocialMedia_btn__7EGHr {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  color: var(--color-primary);
  font-weight: 500;
  background-color: transparent;
  border: 2px solid #e1e1e1;
  letter-spacing: 1px;
}
.SocialMedia_btn__7EGHr:hover {
  color: #232323;
}

.SocialMedia_img__2zckm {
  max-width: 50.8rem;
  padding-right: 1.5rem;
}

@media (max-width: 1024px) {
  .SocialMedia_sectionBgColor__SXhHy {
    padding-top: 3rem;
  }
  .SocialMedia_innerContainer__uzn9O > div,
  .SocialMedia_innerContainer__uzn9O > img {
    max-width: 50%;
    width: 100%;
  }
  .SocialMedia_innerContainer__uzn9O {
    flex-direction: column-reverse;
    row-gap: 2rem;
  }
  .SocialMedia_innerContainer__uzn9O > div {
    max-width: 100%;
  }
  .SocialMedia_innerContainer__uzn9O > img {
    max-width: 340px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/SEO/SocialMedia/SocialMedia.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;;IAEE,cAAc;IACd,WAAW;EACb;EAIA;IACE,8BAA8B;IAC9B,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AAZF","sourcesContent":[".sectionBgColor {\n  background-color: #f7f7f7;\n  border-bottom: 1px solid #e7e7e7;\n  padding-top: 7rem;\n}\n\n.innerContainer {\n  max-width: 150.9rem;\n  width: 85%;\n  margin: auto;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  column-gap: 3.4rem;\n}\n\n.TextContainer {\n  max-width: 50rem;\n}\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n  line-height: 3.9rem;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  color: var(--color-primary);\n  font-weight: 500;\n  background-color: transparent;\n  border: 2px solid #e1e1e1;\n  letter-spacing: 1px;\n}\n.btn:hover {\n  color: #232323;\n}\n\n.img {\n  max-width: 50.8rem;\n  padding-right: 1.5rem;\n}\n\n@media (max-width: 1024px) {\n  .sectionBgColor {\n    padding-top: 3rem;\n  }\n  .innerContainer > div,\n  .innerContainer > img {\n    max-width: 50%;\n    width: 100%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .innerContainer {\n    flex-direction: column-reverse;\n    row-gap: 2rem;\n  }\n  .innerContainer > div {\n    max-width: 100%;\n  }\n  .innerContainer > img {\n    max-width: 340px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBgColor": `SocialMedia_sectionBgColor__SXhHy`,
	"innerContainer": `SocialMedia_innerContainer__uzn9O`,
	"TextContainer": `SocialMedia_TextContainer__kH1OW`,
	"heading": `SocialMedia_heading__MSA4F`,
	"btn": `SocialMedia_btn__7EGHr`,
	"img": `SocialMedia_img__2zckm`
};
export default ___CSS_LOADER_EXPORT___;
