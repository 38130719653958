import React from "react";
import styles from "../styles/AvidianPage.module.css";
import BgContainerImg from "../components/partials/BgContainerCard/BgContainerImg";
import TextFlexCard from "../components/partials/TextFlexCard/TextFlexCard";
import Features from "../components/app/AvidianCRMPage/Features/Features";

import img from "../assets/img/avidian/header-img.png";
import aboutImg from "../assets/img/avidian/about.png";
import FeaturesIcon1 from "../components/icons/FeaturesIcon1";
import FeaturesIcon2 from "../components/icons/FeaturesIcon2";
import ErpItSolution3 from "../components/icons/ErpIcons/ErpItSolution3";
import FeaturesIcon3 from "../components/icons/FeaturesIcon3";
import TeamIcon from "../components/icons/TeamIcon";
import WebConferenceIcon from "../components/icons/WebConferenceIcon";
import ErpSvgIcon from "../components/icons/ErpSvgIcon";
import AvidianEmbeddedOutlook from "../components/app/AvidianComponents/AvidianEmbeddedOutlook/AvidianEmbeddedOutlook";
import TechnologyImgCard from "../components/app/ErpPage/TechnologyImgCard/TechnologyImgCard";
import MtcQuoteForm from "../components/app/NssplAvidianCrm/MtcQuoteForm/MtcQuoteForm";

const servicesData = [
  {
    icon: <TeamIcon />,
    heading: "Avid CRM",
    pay: "Avid CRM is an easy-to-use, fast-to-implement CRM and contact manager embedded in Microsoft Outlook. ",
    BgColor: "#EEDDFF",
  },
  {
    icon: <FeaturesIcon2 />,
    heading: "Avid DocuWorks",
    pay: "Store, share, and manage sensitive data and documents with security and confidence.",
    BgColor: "#DDE4FF",
  },
  {
    icon: <WebConferenceIcon />,
    heading: "Avid Web Conferences",
    pay: "Communicate and market to up to 1,000 people toll-free at any time from within your center of business.",
    BgColor: "#DDFFDE",
  },
  {
    icon: <ErpSvgIcon />,
    heading: "Avid ERP",
    pay: "Integrate your CRM with your favorite products, apps, and software to boost efficiency and save time.",
    BgColor: "#FFEFDD",
  },
];

const AvidianPage = () => {
  return (
    <>
      <BgContainerImg
        btns={["HOME ", "PRODUCTS", "AVIDIAN"]}
        img={img}
        heading="AVIDIAN CRM"
        isTab={true}
      />

      <TextFlexCard
        Heading="Integrated Solutions for Seamless Business Operations"
        para="Set of digital tools and platforms are designed for Collaborative applications. This helps organizations to facilitate communication, coordination, and cooperation among individuals or teams working together on projects or tasks, regardless of their physical location."
        img={aboutImg}
        btns={[{ text: "Get Started", link: "https://www.avidian.com/" }]}
        imgContainerClasses={styles.aboutImgContainer}
      />

      <Features
        heading="How can Avidian help you?"
        subHeading="Whether you’re looking to fill gaps or find a turn-key solution, our customizable search engine optimization and social media marketing services can help."
        data={servicesData}
      />

      <AvidianEmbeddedOutlook />

      <TechnologyImgCard
        bgImageClass={styles.technologyCardImg}
        text="Experience seamless integration with our partner brands, ensuring optimal performance and collaboration on the <span className='text-[#1CB9C8]'>Avidian platform.</span>"
        btnText="VIEW PLANS"
      />

      <MtcQuoteForm />
    </>
  );
};

export default AvidianPage;
