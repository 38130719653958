import React from "react";

const FeaturesIcon3 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.6 18.4H14.4M5.6 23.2H18.4C18.8243 23.2 19.2313 23.0315 19.5314 22.7314C19.8314 22.4314 20 22.0244 20 21.6V2.40005C20 1.9757 19.8314 1.56874 19.5314 1.26868C19.2313 0.96862 18.8243 0.800049 18.4 0.800049H5.6C5.17565 0.800049 4.76869 0.96862 4.46863 1.26868C4.16857 1.56874 4 1.9757 4 2.40005V21.6C4 22.0244 4.16857 22.4314 4.46863 22.7314C4.76869 23.0315 5.17565 23.2 5.6 23.2Z"
        stroke="#0D872F"
      />
    </svg>
  );
};

export default FeaturesIcon3;
