import React from "react";
import TextHeading from "../../../partials/TextHeading/TextHeading";
import styles from "./SeoServices.module.css";
import SeoIcon1 from "../../../icons/SeoIcon1";
import SeoIcon2 from "../../../icons/SeoIcon2";
import SeoIcon3 from "../../../icons/SeoIcon3";

const data = [
  {
    icon: <SeoIcon1 />,
    heading: "SEO audits",
    pay: `Keep close track of your human capital and efficiently manage your resources to boost productivity.`,
  },
  {
    icon: <SeoIcon1 />,
    heading: "SEO Strategy",
    pay: `Gain visibility over your value chains, reduce operational complexity, and improve customer satisfaction.`,
  },
  {
    icon: <SeoIcon2 />,
    heading: "Analysis",
    pay: `At the service of users, the public sector plays an essential role in the support, and development of resources and territories.`,
  },
  {
    icon: <SeoIcon3 />,
    heading: "Social media Strategy",
    pay: `Manage your purchase and replenishment orders. Facilitate data exchange with suppliers and logistics service providers.`,
  },
  // {
  //   icon: <SeoIcon1 />,
  //   heading: "SEO audits",
  //   pay: `Keep close track of your human capital and efficiently manage your resources to boost productivity.`,
  // },
  // {
  //   icon: <SeoIcon1 />,
  //   heading: "SEO Strategy",
  //   pay: `Gain visibility over your value chains, reduce operational complexity, and improve customer satisfaction..`,
  // },
  // {
  //   icon: <SeoIcon2 />,
  //   heading: "Analysis",
  //   pay: `At the service of users, the public sector plays an essential role in the support, and development of resources and territories.`,
  // },
  // {
  //   icon: <SeoIcon3 />,
  //   heading: "Social media Strategy",
  //   pay: `Manage your purchase and replenishment orders. Facilitate data exchange with suppliers and logistics service providers.`,
  // },
];

const SeoServices = () => {
  return (
    <section>
      <div className={styles.innerContainer}>
        <TextHeading
          heading="What do our SEO and SMM services include?"
          text="Whether you’re looking to fill gaps or find a turn-key solution, our customizable search engine optimization and social media marketing services can help."
        />
        <div className={styles.imgContainer}>
          {data.map((item) => (
            <div className={styles.card}>
              {item.icon}
              {/* <SeoIcon3 /> */}

              <h4 className={`${styles.text} body1 heading2`}>
                {item.heading}
              </h4>
              <p className={`${styles.pay} heading2`}>{item.pay}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SeoServices;
