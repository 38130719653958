import React from "react";

const Sales = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41 14L24 4L7 14V34L24 44L41 34V14Z"
        stroke="#1CB9C8"
        stroke-width="4"
        stroke-linejoin="round"
      />
      <path
        d="M24 22V30M32 18V30M16 26V30"
        stroke="#1CB9C8"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Sales;
