// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CaseStudyHeader_innerContainer__iElrE {
  max-width: 95.9rem;
  width: 85%;
  margin: 7rem auto;
  text-align: center;
}

.CaseStudyHeader_heading__O1HvM {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
  line-height: 3.9rem;
}
@media (max-width: 999px) {
  .CaseStudyHeader_innerContainer__iElrE {
    margin: 4rem auto;
  }
}
@media (max-width: 550px) {
  .CaseStudyHeader_innerContainer__iElrE {
    margin: 2rem auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CaseStudyPage/CaseStudyHeader.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".innerContainer {\n  max-width: 95.9rem;\n  width: 85%;\n  margin: 7rem auto;\n  text-align: center;\n}\n\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  letter-spacing: 0.5px;\n  line-height: 3.9rem;\n}\n@media (max-width: 999px) {\n  .innerContainer {\n    margin: 4rem auto;\n  }\n}\n@media (max-width: 550px) {\n  .innerContainer {\n    margin: 2rem auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `CaseStudyHeader_innerContainer__iElrE`,
	"heading": `CaseStudyHeader_heading__O1HvM`
};
export default ___CSS_LOADER_EXPORT___;
