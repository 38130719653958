import React from "react";
import styles from "./ErpSystem.module.css";
import img from "./../../../../assets/img/SEOSupport/monitor.png";

const ErpSystem = () => {
  return (
    <div className={styles.innerContainer}>
      <div className={styles.TextContainer}>
        <h3 className={styles.heading}>
          Customize, upscale, and deploy with ease
        </h3>
        <h4 className="heading2 caption">
          My True ERP has a wide range of feature suitable for many business
          particularly distributed businesses in a wholesale, distribution and
          manufacturing environment. When combined with a 3rd party interactive
          desktop Point of Sale System with a Rewards program it can form the
          hub of a dispersed multi-branch retail management system.
        </h4>
        <button className={`btnPrimary ${styles.btn}`}>FREE DEMO</button>
      </div>
      <img src={img} alt="" className={styles.img} />
    </div>
  );
};

export default ErpSystem;
