import React from "react";
import SeoSupportHeader from "../components/app/SEO/SMMSupport/SeoSupportHeader";
import RankHigher from "../components/app/SEO/RankHigher/RankHigher";
import SeoServices from "../components/app/SEO/SeoServices/SeoServices";
import SocialMedia from "../components/app/SEO/SocialMedia/SocialMedia";
import Monitor from "../components/app/SEO/MonitorCard/Monitor";
import VueporteqSEO from "../components/app/SEO/VueporteqSEO/VueporteqSEO";
import Pricing from "../components/app/CrmPage/Pricing/Pricing";

const SeoSupportPage = () => {
  return (
    <>
      <SeoSupportHeader />
      <RankHigher />
      <SeoServices />
      <SocialMedia />
      {/* <Monitor /> */}
      <VueporteqSEO />
      {/* <Pricing /> */}
    </>
  );
};

export default SeoSupportPage;
