import React from "react";
import styles from "./TechnologyImgCard.module.css";
import img from "./../../../../assets/img/ERP/TechnologyImgCard.png";
import parse from "html-react-parser";

const TechnologyImgCard: React.FC<{
  text?: string;
  btnText?: string;
  bgImageClass?: string;
}> = ({ text, btnText, bgImageClass }) => {
  return (
    <section className={styles.bgContainer}>
      <div className={`${styles.bgImg} ${bgImageClass ? bgImageClass : ""}`}>
        <div className={styles.textHeadingContainer}>
          <h3 className={`${styles.heading} fontMontserrat`}>
            {text
              ? parse(text)
              : "See what’s possible when you have growth-friendly technology and award-winning support behind you."}
          </h3>
          <button className={`btnPrimary ${styles.btn}`}>
            {btnText ? btnText : "GET STARTED"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default TechnologyImgCard;
