import React from "react";

const Experimentation: React.FC<{
  processIconColor: string;
}> = ({ processIconColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 2L12 12M22 2V10M22 2H14M2 22H6V18H2V22ZM22 13H18V22H22V13ZM10 22H14V16H10V22Z"
        stroke={processIconColor}
        stroke-width="2"
      />
    </svg>
  );
};

export default Experimentation;
