import React, { useEffect, useState } from "react";
import styles from "./PlanningCard.module.css";
import img from "./../../../../assets/img/about/planning.png";
import effect from "./../../../../assets/img/about/effect.png";
import { useInView } from "react-intersection-observer";
import { CountUp } from "use-count-up";

const PlanningCard = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
  });
  const [startCount, setStartCount] = useState(false);
  console.log(startCount, "count 1");

  useEffect(() => {
    if (inView) {
      setStartCount(true);
    }
  }, [inView]);

  return (
    <section className={styles.section}>
      <div className={styles.innerContainer}>
        <div className={styles.imgContainer}>
          <img src={img} alt="" className={styles.img} />
          {/* <img src={effect} alt="" className={styles.effectimg} /> */}
        </div>
        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            We're expert in
            <span> Brainstorming, Researching, Planning </span>
            and <span>Strategizing</span> Work
          </h3>
          <h4 className="heading2 caption">
            Continual so distrusts pronounce by unwilling listening. Thing do
            taste on we manor. Him had wound use found hoped. Of distrusts
            immediate enjoyment curiosity.
          </h4>
          <div className={styles.countContainer} ref={ref}>
            <div>
              <h2 className={styles.countHeading}>
                <CountUp isCounting={startCount} end={120} duration={5} />+
              </h2>
              <p className="heading">Clients</p>
            </div>
            <div>
              <h2 className={styles.countHeading}>
                <CountUp isCounting={startCount} end={100} duration={5} />%
              </h2>
              <p className="heading">Success Rate</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanningCard;
