import React, { useState } from "react";
import styles from "./MakeBetterDecisions.module.css";
import img from "./../../../../assets/img/ERP/MakeBetter.png";
import Tab1 from "../../../icons/ErpIcons/Tab1";
import Tab2 from "../../../icons/ErpIcons/Tab2";
import Tab3 from "../../../icons/ErpIcons/Tab3";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
const TabData = [
  {
    icon: <Tab1 />,
    heading: "Make Better Decisions",
  },
  {
    icon: <Tab2 />,
    heading: "Simplify Your Business",
  },
  {
    icon: <Tab3 />,
    heading: "Run a Global Business With Ease",
  },
];
const MakeBetterDecisions = () => {
  const [toggle, setToggle] = useState("");
  return (
    <>
      <div className={`${styles.borderBottom} ${styles.Show}`}>
        <div className={styles.innerContainer}>
          <div className={styles.imgContainer}>
            <img src={img} alt="" className={styles.img} />
          </div>
          <div className={styles.TextContainer}>
            <h3 className={styles.heading}>Make Better Decisions</h3>
            <h4 className="heading2 caption">
              Access financial, operational and transactional data from across
              the organization. Easily customize dashboards, reports and visual
              analytics to provide a summary-level overview or an in-depth
              account of business activity.
            </h4>
            <Link to={"/contact"}>
              <button className={`btnPrimary ${styles.btn}`}>
                GET STARTED
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className={`${styles.borderBottom} ${styles.Hide}`}>
        <div className={styles.innerContainer}>
          {TabData.map((item) => (
            <div style={{ width: "100%" }}>
              <div className={styles.tabBox}>
                <div
                  style={{
                    marginBottom: "2rem",
                    display: "flex",
                    gap: "25px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className={styles.icon}>{item.icon}</div>
                  <h4 className={styles.heading2}>{item.heading}</h4>

                  {toggle === item.heading ? (
                    <KeyboardArrowDownIcon
                      style={{ fontSize: "4rem", marginLeft: "auto" }}
                      onClick={() =>
                        setToggle((p) =>
                          p === item.heading ? "" : item.heading
                        )
                      }
                    />
                  ) : (
                    <KeyboardArrowUpIcon
                      style={{ fontSize: "4rem", marginLeft: "auto" }}
                      onClick={() =>
                        setToggle((p) =>
                          p === item.heading ? "" : item.heading
                        )
                      }
                    />
                  )}
                </div>
              </div>
              {toggle === item.heading && (
                <div className={styles.innerContainer}>
                  <div className={styles.imgContainer}>
                    <img src={img} alt="" className={styles.img} />
                  </div>
                  <div className={styles.TextContainer}>
                    <h3 className={styles.heading}>Make Better Decisions</h3>
                    <h4 className="heading2 caption">
                      Access financial, operational and transactional data from
                      across the organization. Easily customize dashboards,
                      reports and visual analytics to provide a summary-level
                      overview or an in-depth account of business activity.
                    </h4>
                    <button className={`btnPrimary ${styles.btn}`}>
                      Get Started
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MakeBetterDecisions;
