import React from "react";
import styles from "./EmbeddedOutlook.module.css";
import TickIcon from "../../icons/TickIcon";

const EmbeddedOutlookCard: React.FC<{
  Heading: string;
  img: string;
  effectImg: string;
  points?: { text: string; icon?: React.ReactNode | JSX.Element }[];
  text?: string;
  innerContainerClasses?: string;
  pointsContainerClasses?: string;
  textContainerClasses?: string;
}> = ({
  Heading,
  img,
  effectImg,
  points,
  innerContainerClasses,
  text,
  pointsContainerClasses,
  textContainerClasses,
}) => {
  return (
    <section className={styles.section}>
      <div
        className={`${styles.innerContainer} ${innerContainerClasses || ""}`}
      >
        <div className={styles.imageContainer}>
          <img src={img} alt="img" />
          {/* <img src={effectImg} alt="" className={styles.effectImg} /> */}
        </div>

        <div
          className={`${styles.textContainer} ${textContainerClasses || ""}`}
        >
          <h3 className={styles.heading}>{Heading}</h3>

          {text && <p className="mb-[2.5rem] text-[#666666]">{text}</p>}

          <div className={`caption ${pointsContainerClasses || ""}`}>
            {points &&
              points.map((item) => (
                <div className={styles.pointItem}>
                  <span className="flex justify-center items-center">
                    {item.icon ? item.icon : <TickIcon />}
                  </span>

                  <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                    {item.text}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={styles.border}></div>
    </section>
  );
};

export default EmbeddedOutlookCard;
