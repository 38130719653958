import React from "react";
import img from "../../../../assets/img/nssplimg/AvidainImg1.png";
import { text } from "stream/consumers";
import TextFlexCard from "../../../partials/nssplPartials/TextFlexCard/TextFlexCard";

const AvidianCrm = () => {
  return (
    <div>
      <TextFlexCard
        Heading="Industry-leading CRM geared towards user convenience."
        para="Prophet CRM is an easy-to-use, fast-to-implement CRM and contact manager embedded in Microsoft Outlook. It de-clutters and expedites your workflow with intuitive features."
        img={img}
        btns={[{ text: "Get Started" }]}
      />
    </div>
  );
};

export default AvidianCrm;
