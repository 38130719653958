import React from "react";

const Apple = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_997_2036)">
        <path
          d="M36.3413 0.0260228C36.2308 -0.0974772 32.2495 0.0747729 28.785 3.83502C25.3205 7.59202 25.8535 11.9015 25.9315 12.012C26.0095 12.1225 30.8715 12.2948 33.9753 7.92352C37.079 3.55227 36.4518 0.152773 36.3413 0.0260228ZM47.1118 38.1583C46.9557 37.8463 39.5555 34.1478 40.2445 27.0368C40.9335 19.9258 45.6883 17.9725 45.763 17.7613C45.8377 17.55 43.8228 15.1938 41.6875 14.001C40.1194 13.1609 38.3845 12.6792 36.6078 12.5905C36.2568 12.5808 35.038 12.2818 32.5323 12.9675C30.8813 13.4193 27.16 14.8818 26.1363 14.9403C25.1093 14.9988 22.0543 13.2438 18.7685 12.779C16.6658 12.3728 14.4363 13.2048 12.8405 13.845C11.248 14.482 8.21901 16.2955 6.10001 21.1153C3.98101 25.9318 5.08926 33.5628 5.88226 35.9353C6.67526 38.3078 7.91351 42.1883 10.0195 45.0223C11.8915 48.2203 14.3745 50.44 15.4113 51.194C16.448 51.948 19.373 52.4485 21.401 51.4118C23.0325 50.4108 25.977 49.8355 27.1405 49.8778C28.3008 49.92 30.5888 50.3783 32.932 51.6295C34.7878 52.2698 36.5428 52.0033 38.301 51.2883C40.0592 50.57 42.604 47.8465 45.5745 42.3248C46.7012 39.7573 47.2136 38.3684 47.1118 38.1583Z"
          fill="black"
        />
        <path
          d="M36.3413 0.0260228C36.2308 -0.0974772 32.2495 0.0747729 28.785 3.83502C25.3205 7.59202 25.8535 11.9015 25.9315 12.012C26.0095 12.1225 30.8715 12.2948 33.9753 7.92352C37.079 3.55227 36.4518 0.152773 36.3413 0.0260228ZM47.1118 38.1583C46.9557 37.8463 39.5555 34.1478 40.2445 27.0368C40.9335 19.9258 45.6883 17.9725 45.763 17.7613C45.8377 17.55 43.8228 15.1938 41.6875 14.001C40.1194 13.1609 38.3845 12.6792 36.6078 12.5905C36.2568 12.5808 35.038 12.2818 32.5323 12.9675C30.8813 13.4193 27.16 14.8818 26.1363 14.9403C25.1093 14.9988 22.0543 13.2438 18.7685 12.779C16.6658 12.3728 14.4363 13.2048 12.8405 13.845C11.248 14.482 8.21901 16.2955 6.10001 21.1153C3.98101 25.9318 5.08926 33.5628 5.88226 35.9353C6.67526 38.3078 7.91351 42.1883 10.0195 45.0223C11.8915 48.2203 14.3745 50.44 15.4113 51.194C16.448 51.948 19.373 52.4485 21.401 51.4118C23.0325 50.4108 25.977 49.8355 27.1405 49.8778C28.3008 49.92 30.5888 50.3783 32.932 51.6295C34.7878 52.2698 36.5428 52.0033 38.301 51.2883C40.0592 50.57 42.604 47.8465 45.5745 42.3248C46.7012 39.7573 47.2136 38.3684 47.1118 38.1583Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_997_2036">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Apple;
