import React from "react";
import styles from "./VueporteqITSolutions.module.css";
import ImgTextFlexComponent from "../../../partials/ImgTextFlexComponent/ImgTextFlexComponent";
import img from "./../../../../assets/img/WebConferenceSolutions/map.png";
import VueporteqITSolutionsimg from "./../../../../assets/img/WebConferenceSolutions/VueporteqITSolutions.png";
import { Link, useNavigate } from "react-router-dom";

const VueporteqITSolutions = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.effectImg}>
      <div className={styles.VueporteqITSolutionsimg}>
        <img src={VueporteqITSolutionsimg} alt="" />
        <div className={styles.textContainer}>
          <h4 className={`${styles.TextHeading} headingWhite`}>
            Intelligent collaboration for any workstyle.
          </h4>
          <Link to={"/contact"}>
            <button className={`${styles.btn} btnOutlined`}>
              SCHEDULE A DEMO
            </button>
          </Link>
        </div>
      </div>

      <ImgTextFlexComponent
        isTitle={false}
        img={img}
        MainHeading="Why Choose Vueporteq IT Solutions for web Conferencing?"
        para="There’s lots to love about Vueporteq IT Solutions Web Conference"
        btn={false}
        imgWidth=""
      />
    </div>
  );
};

export default VueporteqITSolutions;
