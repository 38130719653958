import React from "react";

const ChooseVueporteqIcon1 = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 6.5H8.125V9.75H6.5V6.5ZM6.5 11.375H8.125V14.625H6.5V11.375ZM11.375 6.5H13V9.75H11.375V6.5ZM11.375 11.375H13V14.625H11.375V11.375ZM6.5 16.25H8.125V19.5H6.5V16.25ZM11.375 16.25H13V19.5H11.375V16.25Z"
        fill="white"
      />
      <path
        d="M24.375 11.375C24.375 10.944 24.2038 10.5307 23.899 10.226C23.5943 9.92121 23.181 9.75 22.75 9.75H17.875V3.25C17.875 2.81902 17.7038 2.4057 17.399 2.10095C17.0943 1.7962 16.681 1.625 16.25 1.625H3.25C2.81902 1.625 2.4057 1.7962 2.10095 2.10095C1.7962 2.4057 1.625 2.81902 1.625 3.25V24.375H24.375V11.375ZM3.25 3.25H16.25V22.75H3.25V3.25ZM17.875 22.75V11.375H22.75V22.75H17.875Z"
        fill="white"
      />
    </svg>
  );
};

export default ChooseVueporteqIcon1;
