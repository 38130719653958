import React from "react";
import TextHeading from "../../../../partials/TextHeading/TextHeading";
import Testimonial from "../../../HomePage/Testimonial/Testimonial";
import styles from "./IndustryUsecases.module.css";

const IndustryUsecases = () => {
  return (
    <div className={styles.innerContainer}>
      <TextHeading
        heading="Industry Use cases"
        text="Our broader objective is to make quality tech available, accessible, and affordable to all. In doing so, we meet the needs of organizations of all sizes."
      />
      <Testimonial bgColor="#fff" />
    </div>
  );
};

export default IndustryUsecases;
