import React from "react";

const JsIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_959_12271)">
        <path
          d="M39.8125 0H12.1875C5.45653 0 0 5.45653 0 12.1875V39.8125C0 46.5435 5.45653 52 12.1875 52H39.8125C46.5435 52 52 46.5435 52 39.8125V12.1875C52 5.45653 46.5435 0 39.8125 0Z"
          fill="#F0DB4F"
        />
        <path
          d="M13.6721 43.4549L17.6513 41.0466C18.4191 42.4078 19.1175 43.5595 20.7926 43.5595C22.3983 43.5595 23.4107 42.9314 23.4107 40.4882V23.873H28.2975V40.5573C28.2975 45.6186 25.3306 47.9224 21.002 47.9224C17.0929 47.9224 14.8238 45.8978 13.6719 43.4545M30.9517 42.9312L34.9305 40.6276C35.978 42.3381 37.3394 43.5946 39.7476 43.5946C41.7726 43.5946 43.0637 42.5822 43.0637 41.186C43.0637 39.5106 41.7373 38.9171 39.5033 37.94L38.2819 37.4162C34.7563 35.9155 32.4175 34.0305 32.4175 30.0513C32.4175 26.3861 35.21 23.5935 39.5734 23.5935C42.68 23.5935 44.9139 24.6758 46.5194 27.5031L42.7147 29.9464C41.8768 28.4456 40.9696 27.8522 39.5734 27.8522C38.1421 27.8522 37.2346 28.7598 37.2346 29.9464C37.2346 31.4124 38.1421 32.0059 40.2364 32.9137L41.458 33.4371C45.6117 35.2175 47.9504 37.0323 47.9504 41.1161C47.9504 45.5146 44.4949 47.9228 39.8525 47.9228C35.3148 47.9228 32.3827 45.7587 30.9517 42.9312Z"
          fill="#323330"
        />
      </g>
      <defs>
        <clipPath id="clip0_959_12271">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JsIcon;
