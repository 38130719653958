import React from "react";
import styles from "../styles/MtcPage.module.css";

import AvidianCrmHeader from "../components/app/AvidianCRMPage/AvidianCrmHeader";
import AvidianCrm from "../components/app/AvidianCRMPage/AvidianCrm";
import CrmOutlook from "../components/app/AvidianCRMPage/CrmOutlook/CrmOutlook";
import Features from "../components/app/AvidianCRMPage/Features/Features";
import EmbeddedOutlook from "../components/app/AvidianCRMPage/EmbeddedOutlook/EmbeddedOutlook";
import SalesAutomation from "../components/app/AvidianCRMPage/SalesAutomation/SalesAutomation";
import TechnologyImgCard from "../components/app/ErpPage/TechnologyImgCard/TechnologyImgCard";
import MtcQuoteForm from "../components/app/Products/MtcPageComponents/MtcHelpSection/MtcQuoteForm/MtcQuoteForm";
import MyTrueCard from "../components/app/AvidianCRMPage/MyTrueCard/MyTrueCard";

const CrmProductPage = () => {
  return (
    <>
      <AvidianCrmHeader />
      <AvidianCrm />
      <CrmOutlook />
      <Features />
      <EmbeddedOutlook />
      <SalesAutomation />
      <TechnologyImgCard
        bgImageClass={styles.technologyCardImg}
        text="See what’s possible when you have growth-friendly technology and award-winning support behind you."
        btnText="VIEW PLANS"
      />
      <MtcQuoteForm />
      <MyTrueCard />
    </>
  );
};

export default CrmProductPage;
