// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChooseVueporteq_borderBottom__7Az9I {
  border-bottom: 1px solid #e7e7e7;
}
.ChooseVueporteq_innerContainer__F23Os {
  max-width: 150.9rem;
  width: 95%;
  margin: 6rem auto 5rem auto;
}
.ChooseVueporteq_cardContainer__yTvTd {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10rem;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
}
.ChooseVueporteq_cards__kxCXe {
  display: flex;
  align-items: center;
  column-gap: 2.2rem;
}
.ChooseVueporteq_headingNmber__k4-Ml {
  color: #f2f2f2;
  font-family: "Montserrat", sans-serif;
  line-height: 3.6rem;
  margin-bottom: 0.9rem;
}
.ChooseVueporteq_heading__1HAWy {
  color: #232323;
  font-weight: 800;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  line-height: 2.1rem;
  margin-bottom: 1rem;
}
.ChooseVueporteq_pay__ieM3M {
  line-height: 2.6rem;
}
.ChooseVueporteq_bgblueColor__72BB- {
  background-color: #1cb9c8;
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #f2f2f2;
}

@media (max-width: 800px) {
  .ChooseVueporteq_cards__kxCXe {
    max-width: 390px;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CrmPage/ChooseVueporteq/ChooseVueporteq.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;AACA;EACE,mBAAmB;EACnB,UAAU;EACV,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,qCAAqC;EACrC,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE;IACE,gBAAgB;IAChB,WAAW;EACb;AACF","sourcesContent":[".borderBottom {\n  border-bottom: 1px solid #e7e7e7;\n}\n.innerContainer {\n  max-width: 150.9rem;\n  width: 95%;\n  margin: 6rem auto 5rem auto;\n}\n.cardContainer {\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 10rem;\n  align-items: center;\n  justify-content: center;\n  row-gap: 1rem;\n}\n.cards {\n  display: flex;\n  align-items: center;\n  column-gap: 2.2rem;\n}\n.headingNmber {\n  color: #f2f2f2;\n  font-family: \"Montserrat\", sans-serif;\n  line-height: 3.6rem;\n  margin-bottom: 0.9rem;\n}\n.heading {\n  color: #232323;\n  font-weight: 800;\n  font-size: 2rem;\n  font-family: \"Montserrat\", sans-serif;\n  line-height: 2.1rem;\n  margin-bottom: 1rem;\n}\n.pay {\n  line-height: 2.6rem;\n}\n.bgblueColor {\n  background-color: #1cb9c8;\n  width: 8.5rem;\n  height: 8.5rem;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 5px solid #f2f2f2;\n}\n\n@media (max-width: 800px) {\n  .cards {\n    max-width: 390px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderBottom": `ChooseVueporteq_borderBottom__7Az9I`,
	"innerContainer": `ChooseVueporteq_innerContainer__F23Os`,
	"cardContainer": `ChooseVueporteq_cardContainer__yTvTd`,
	"cards": `ChooseVueporteq_cards__kxCXe`,
	"headingNmber": `ChooseVueporteq_headingNmber__k4-Ml`,
	"heading": `ChooseVueporteq_heading__1HAWy`,
	"pay": `ChooseVueporteq_pay__ieM3M`,
	"bgblueColor": `ChooseVueporteq_bgblueColor__72BB-`
};
export default ___CSS_LOADER_EXPORT___;
