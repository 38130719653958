import React from "react";
import ErpHeader from "../components/app/ErpPage/ErpHeader";
import ErpSystem from "../components/app/ErpPage/ERPSystem/ErpSystem";
import VersatileERPs from "../components/app/ErpPage/VersatileERPs/VersatileERPs";
import ErpitSolutions from "../components/app/ErpPage/ERPITSolutions/ErpitSolutions";
import IntegrateTools from "../components/app/ErpPage/IntegrateTools/IntegrateTools";
import TechnologyImgCard from "../components/app/ErpPage/TechnologyImgCard/TechnologyImgCard";
import SmarterDecisionsTab from "../components/app/ErpPage/SmarterDecisionsTab/SmarterDecisionsTab";
import MakeBetterDecisions from "../components/app/ErpPage/MakeBetterDecisions/MakeBetterDecisions";
import Pricing from "../components/app/CrmPage/Pricing/Pricing";

const ErpPage = () => {
  return (
    <>
      <ErpHeader />
      <ErpSystem />
      <VersatileERPs />
      <ErpitSolutions />
      <IntegrateTools />
      <TechnologyImgCard />
      <SmarterDecisionsTab />
      <MakeBetterDecisions />
      {/* <Pricing /> */}
    </>
  );
};

export default ErpPage;
