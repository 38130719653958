import React from "react";

const MogoDbIcon = () => {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_959_12557)">
        <path
          d="M40.3242 0H12.6992C5.96825 0 0.511719 5.45653 0.511719 12.1875V39.8125C0.511719 46.5435 5.96825 52 12.6992 52H40.3242C47.0552 52 52.5117 46.5435 52.5117 39.8125V12.1875C52.5117 5.45653 47.0552 0 40.3242 0Z"
          fill="#023430"
        />
        <path
          d="M35.2812 21.8544C33.1409 12.413 28.6803 9.8995 27.5423 8.12277C27.0731 7.34006 26.6574 6.52644 26.2982 5.6875C26.2381 6.52641 26.1276 7.05453 25.4146 7.69072C23.9832 8.96695 17.9037 13.9212 17.392 24.6488C16.9153 34.6511 24.7451 40.8186 25.7794 41.4562C26.5749 41.8476 27.5436 41.4645 28.0162 41.1052C31.7905 38.5149 36.9474 31.6091 35.2853 21.8544"
          fill="#10AA50"
        />
        <path
          d="M26.6219 36.1301C26.4249 38.6058 26.2837 40.0443 25.7832 41.4591C25.7832 41.4591 26.1117 43.816 26.3424 46.3126H27.1588C27.3535 44.5544 27.6508 42.8092 28.0493 41.0858C26.9924 40.5658 26.6625 38.3025 26.6219 36.1301Z"
          fill="#B8C4C2"
        />
        <path
          d="M28.0484 41.087C26.9803 40.5937 26.6714 38.2827 26.6239 36.13C26.8879 32.5788 26.9649 29.0161 26.8546 25.4568C26.7985 23.5892 26.881 8.15906 26.3945 5.8999C26.7274 6.66505 27.1108 7.40715 27.5424 8.12128C28.6803 9.89943 33.1423 12.4129 35.2813 21.8544C36.9475 31.5924 31.8186 38.4799 28.0484 41.087Z"
          fill="#12924F"
        />
      </g>
      <defs>
        <clipPath id="clip0_959_12557">
          <rect
            width="52"
            height="52"
            fill="white"
            transform="translate(0.511719)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MogoDbIcon;
