// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .section {
  position: relative;
} */
.AllNewAgain_InnerContainer__t2GCJ {
  max-width: 1400px;
  margin: auto;
  margin-top: 5rem;
  display: flex;
  position: relative;
}
.AllNewAgain_imgContainer__oetwj {
  max-width: 90rem;
  margin: auto;
  position: absolute;
  top: -361px;
  right: -305px;
  z-index: -1;
}
.AllNewAgain_imgContainer__oetwj img {
  width: 100%;
}
@media (max-width: 1450px) {
  .AllNewAgain_InnerContainer__t2GCJ {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .AllNewAgain_imgContainer__oetwj img {
    max-width: 80rem;
    width: 100%;
  }
}

/* @media (max-width: 1024px) {
  .imgContainer img {
    max-width: 70rem;
  }
} */
@media (max-width: 850px) {
  .AllNewAgain_imgContainer__oetwj img {
    max-width: 70rem;
    top: -100px;
  }
  .AllNewAgain_imgContainer__oetwj {
    top: -323px;
  }
}
@media (max-width: 768px) {
  .AllNewAgain_InnerContainer__t2GCJ {
    flex-direction: column-reverse;
  }
  .AllNewAgain_imgContainer__oetwj {
    top: -310px;
    /* right: 76px; */
  }
}
@media (max-width: 580px) {
  .AllNewAgain_imgContainer__oetwj {
    top: -290px;
    max-width: 65rem;
  }
}
@media (max-width: 450px) {
  .AllNewAgain_imgContainer__oetwj {
    top: -281px;
    right: -261px;
    max-width: 53rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CaseStudyPage/AllNewAgain/AllNewAgain.module.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;;AAEA;;;;GAIG;AACH;EACE;IACE,gBAAgB;IAChB,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,WAAW;IACX,iBAAiB;EACnB;AACF;AACA;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;AACF;AACA;EACE;IACE,WAAW;IACX,aAAa;IACb,gBAAgB;EAClB;AACF","sourcesContent":["/* .section {\n  position: relative;\n} */\n.InnerContainer {\n  max-width: 1400px;\n  margin: auto;\n  margin-top: 5rem;\n  display: flex;\n  position: relative;\n}\n.imgContainer {\n  max-width: 90rem;\n  margin: auto;\n  position: absolute;\n  top: -361px;\n  right: -305px;\n  z-index: -1;\n}\n.imgContainer img {\n  width: 100%;\n}\n@media (max-width: 1450px) {\n  .InnerContainer {\n    margin-left: 3rem;\n    margin-right: 3rem;\n  }\n  .imgContainer img {\n    max-width: 80rem;\n    width: 100%;\n  }\n}\n\n/* @media (max-width: 1024px) {\n  .imgContainer img {\n    max-width: 70rem;\n  }\n} */\n@media (max-width: 850px) {\n  .imgContainer img {\n    max-width: 70rem;\n    top: -100px;\n  }\n  .imgContainer {\n    top: -323px;\n  }\n}\n@media (max-width: 768px) {\n  .InnerContainer {\n    flex-direction: column-reverse;\n  }\n  .imgContainer {\n    top: -310px;\n    /* right: 76px; */\n  }\n}\n@media (max-width: 580px) {\n  .imgContainer {\n    top: -290px;\n    max-width: 65rem;\n  }\n}\n@media (max-width: 450px) {\n  .imgContainer {\n    top: -281px;\n    right: -261px;\n    max-width: 53rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InnerContainer": `AllNewAgain_InnerContainer__t2GCJ`,
	"imgContainer": `AllNewAgain_imgContainer__oetwj`
};
export default ___CSS_LOADER_EXPORT___;
