// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modifi_InnerContainer__0O1Zh {
  max-width: 1400px;
  margin: auto;
  margin-top: 5rem;
  display: flex;
}
.Modifi_imgContainer__72FVq {
  max-width: 55%;
  width: 100%;
}
.Modifi_imgContainer__72FVq img {
  width: 100%;
}
@media (max-width: 1450px) {
  .Modifi_InnerContainer__0O1Zh {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .Modifi_imgContainer__72FVq img {
    max-width: 80rem;
    width: 100%;
  }
}
@media (max-width: 999px) {
  .Modifi_ImgContainer__oXrQL {
    max-width: 80rem;
  }
}
@media (max-width: 768px) {
  .Modifi_InnerContainer__0O1Zh {
    flex-direction: column-reverse;
  }
  .Modifi_imgContainer__72FVq {
    margin: auto;
    max-width: 120rem;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CaseStudyPage/Modifi/Modifi.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,YAAY;IACZ,iBAAiB;IACjB,WAAW;EACb;AACF","sourcesContent":[".InnerContainer {\n  max-width: 1400px;\n  margin: auto;\n  margin-top: 5rem;\n  display: flex;\n}\n.imgContainer {\n  max-width: 55%;\n  width: 100%;\n}\n.imgContainer img {\n  width: 100%;\n}\n@media (max-width: 1450px) {\n  .InnerContainer {\n    margin-left: 3rem;\n    margin-right: 3rem;\n  }\n  .imgContainer img {\n    max-width: 80rem;\n    width: 100%;\n  }\n}\n@media (max-width: 999px) {\n  .ImgContainer {\n    max-width: 80rem;\n  }\n}\n@media (max-width: 768px) {\n  .InnerContainer {\n    flex-direction: column-reverse;\n  }\n  .imgContainer {\n    margin: auto;\n    max-width: 120rem;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InnerContainer": `Modifi_InnerContainer__0O1Zh`,
	"imgContainer": `Modifi_imgContainer__72FVq`,
	"ImgContainer": `Modifi_ImgContainer__oXrQL`
};
export default ___CSS_LOADER_EXPORT___;
