import React from "react";

const EasyScalability = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.906 2C15.906 2.414 16.242 2.75 16.656 2.75H20.189L12.75 10.19V8C12.75 7.80109 12.671 7.61032 12.5303 7.46967C12.3897 7.32902 12.1989 7.25 12 7.25C11.8011 7.25 11.6103 7.32902 11.4697 7.46967C11.329 7.61032 11.25 7.80109 11.25 8V12C11.25 12.414 11.586 12.75 12 12.75H16C16.1989 12.75 16.3897 12.671 16.5303 12.5303C16.671 12.3897 16.75 12.1989 16.75 12C16.75 11.8011 16.671 11.6103 16.5303 11.4697C16.3897 11.329 16.1989 11.25 16 11.25H13.81L21.25 3.81V7.344C21.25 7.54291 21.329 7.73368 21.4697 7.87433C21.6103 8.01498 21.8011 8.094 22 8.094C22.1989 8.094 22.3897 8.01498 22.5303 7.87433C22.671 7.73368 22.75 7.54291 22.75 7.344V2C22.75 1.80109 22.671 1.61032 22.5303 1.46967C22.3897 1.32902 22.1989 1.25 22 1.25H16.656C16.4571 1.25 16.2663 1.32902 16.1257 1.46967C15.985 1.61032 15.906 1.80109 15.906 2Z"
        fill="#7742C6"
      />
      <path
        d="M19.752 7.43C19.7742 8.00964 20.0193 8.55832 20.4363 8.96153C20.8533 9.36474 21.4099 9.59133 21.99 9.594C22 10.32 22 11.119 22 12C22 16.714 22 19.071 20.535 20.535C19.072 22 16.714 22 12 22C7.286 22 4.929 22 3.464 20.535C2 19.072 2 16.714 2 12C2 7.286 2 4.929 3.464 3.464C4.93 2 7.286 2 12 2C12.881 2 13.68 2 14.406 2.01C14.4087 2.59005 14.6353 3.14667 15.0385 3.56367C15.4417 3.98067 15.9904 4.22584 16.57 4.248L13.947 6.871C13.6988 6.44253 13.3163 6.10789 12.8586 5.91895C12.4009 5.73 11.8937 5.69731 11.4155 5.82593C10.9374 5.95455 10.515 6.23731 10.2139 6.63037C9.91276 7.02344 9.74971 7.50485 9.75 8V12C9.75 12.5967 9.98705 13.169 10.409 13.591C10.831 14.0129 11.4033 14.25 12 14.25H16C16.4951 14.2503 16.9766 14.0872 17.3696 13.7861C17.7627 13.485 18.0454 13.0626 18.1741 12.5845C18.3027 12.1063 18.27 11.5991 18.0811 11.1414C17.8921 10.6837 17.5575 10.3012 17.129 10.053L19.752 7.43Z"
        fill="#7742C6"
      />
    </svg>
  );
};

export default EasyScalability;
