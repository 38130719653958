// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmbeddedOutlook_innerContainer__xsgNj {
  max-width: 145rem;
  width: 85%;
  margin: 7rem auto 0px auto;
  display: flex;
  align-items: center;
  column-gap: 15rem;
  justify-content: space-around;
}

.EmbeddedOutlook_imageContainer__Yl9Ma {
  max-width: 50.6rem;
  width: 50%;
  position: relative;
  /* margin: auto; */
}
.EmbeddedOutlook_imageContainer__Yl9Ma > img {
  width: 100%;
}

/* .effectImg {
  position: absolute;
  bottom: -13%;
  left: -17%;
  max-width: 32rem;
  z-index: -1;
} */
.EmbeddedOutlook_textContainer__NJ411 {
  max-width: 50.6rem;
  padding-top: 1.2rem;
  width: 50%;
}
.EmbeddedOutlook_heading__8Sm5d {
  font-weight: 600;
  margin-bottom: 2.6rem;
}

.EmbeddedOutlook_aboutDescriptionText__fiapO {
  color: var(--gray-666);
  line-height: 2.74rem;
  margin-bottom: 1rem;
}

.EmbeddedOutlook_pointItem__hmFK3 {
  display: flex;
  column-gap: 1.6rem;
  align-items: center;
}
.EmbeddedOutlook_border__v1J-s {
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/nssplPartials/EmbeddedOutlook/EmbeddedOutlook.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;EACV,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;;AAEA;;;;;;GAMG;AACH;EACE,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,WAAW;AACb","sourcesContent":[".innerContainer {\n  max-width: 145rem;\n  width: 85%;\n  margin: 7rem auto 0px auto;\n  display: flex;\n  align-items: center;\n  column-gap: 15rem;\n  justify-content: space-around;\n}\n\n.imageContainer {\n  max-width: 50.6rem;\n  width: 50%;\n  position: relative;\n  /* margin: auto; */\n}\n.imageContainer > img {\n  width: 100%;\n}\n\n/* .effectImg {\n  position: absolute;\n  bottom: -13%;\n  left: -17%;\n  max-width: 32rem;\n  z-index: -1;\n} */\n.textContainer {\n  max-width: 50.6rem;\n  padding-top: 1.2rem;\n  width: 50%;\n}\n.heading {\n  font-weight: 600;\n  margin-bottom: 2.6rem;\n}\n\n.aboutDescriptionText {\n  color: var(--gray-666);\n  line-height: 2.74rem;\n  margin-bottom: 1rem;\n}\n\n.pointItem {\n  display: flex;\n  column-gap: 1.6rem;\n  align-items: center;\n}\n.border {\n  border-bottom: 1px solid #e7e7e7;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `EmbeddedOutlook_innerContainer__xsgNj`,
	"imageContainer": `EmbeddedOutlook_imageContainer__Yl9Ma`,
	"textContainer": `EmbeddedOutlook_textContainer__NJ411`,
	"heading": `EmbeddedOutlook_heading__8Sm5d`,
	"aboutDescriptionText": `EmbeddedOutlook_aboutDescriptionText__fiapO`,
	"pointItem": `EmbeddedOutlook_pointItem__hmFK3`,
	"border": `EmbeddedOutlook_border__v1J-s`
};
export default ___CSS_LOADER_EXPORT___;
