import React from "react";

const Azure = () => {
  return (
    <svg
      width="53"
      height="52"
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_959_12563)">
        <path
          d="M40.3242 0H12.6992C5.96825 0 0.511719 5.45653 0.511719 12.1875V39.8125C0.511719 46.5435 5.96825 52 12.6992 52H40.3242C47.0552 52 52.5117 46.5435 52.5117 39.8125V12.1875C52.5117 5.45653 47.0552 0 40.3242 0Z"
          fill="#242938"
        />
        <path
          d="M19.7424 6.90666H31.7637L19.2845 43.8815C19.1563 44.2615 18.9121 44.5917 18.5864 44.8256C18.2606 45.0595 17.8697 45.1853 17.4686 45.1854H8.11308C7.65461 45.1854 7.21135 45.0209 6.86397 44.7217C6.5166 44.4225 6.2882 44.0085 6.22036 43.5551C6.17532 43.2546 6.20248 42.9478 6.29958 42.6599L17.9262 8.21052C18.0544 7.83043 18.2986 7.50014 18.6245 7.26615C18.9503 7.03217 19.3413 6.9063 19.7424 6.90625V6.90666Z"
          fill="url(#paint0_linear_959_12563)"
        />
        <path
          d="M37.2117 31.7068H18.1488C17.9715 31.7065 17.7983 31.7597 17.6518 31.8593C17.5052 31.959 17.3921 32.1005 17.3271 32.2654C17.2621 32.4302 17.2483 32.6109 17.2874 32.7837C17.3265 32.9566 17.4167 33.1136 17.5463 33.2345L29.7958 44.6678C30.1524 45.0004 30.6219 45.1853 31.1096 45.1854H41.9038L37.2117 31.7068Z"
          fill="#0078D4"
        />
        <path
          d="M19.7419 6.90675C19.3364 6.90521 18.941 7.03337 18.6135 7.27252C18.286 7.51166 18.0435 7.84926 17.9215 8.236L6.31331 42.6285C6.20973 42.9174 6.17725 43.2271 6.2186 43.5312C6.25995 43.8353 6.37393 44.1251 6.55088 44.3759C6.72784 44.6266 6.96258 44.8311 7.23525 44.9721C7.50793 45.113 7.81053 45.1862 8.11746 45.1855H17.7145C18.0719 45.1214 18.4059 44.9637 18.6824 44.7285C18.959 44.4933 19.1682 44.1889 19.2887 43.8465L21.6037 37.0243L29.8726 44.7365C30.219 45.0232 30.6536 45.1817 31.1033 45.1855H41.8573L37.1408 31.7071L23.3914 31.7103L31.8065 6.90675H19.7419Z"
          fill="url(#paint1_linear_959_12563)"
        />
        <path
          d="M35.0958 8.20877C34.9679 7.8292 34.7241 7.49935 34.3987 7.26574C34.0733 7.03214 33.6828 6.90657 33.2823 6.90674H19.8848C20.2853 6.90669 20.6757 7.03231 21.0011 7.2659C21.3264 7.49948 21.5703 7.82926 21.6983 8.20877L33.3253 42.6596C33.4224 42.9475 33.4495 43.2544 33.4046 43.555C33.3596 43.8555 33.2438 44.141 33.0668 44.388C32.8895 44.6348 32.656 44.8358 32.3856 44.9746C32.1152 45.1133 31.8157 45.1857 31.5118 45.1858H44.9098C45.2136 45.1857 45.5131 45.1132 45.7834 44.9744C46.0538 44.8357 46.2872 44.6346 46.4645 44.3878C46.6416 44.1409 46.7574 43.8554 46.8024 43.5549C46.8473 43.2544 46.82 42.9475 46.7229 42.6596L35.0958 8.20877Z"
          fill="url(#paint2_linear_959_12563)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_959_12563"
          x1="24.1238"
          y1="9.7433"
          x2="11.6395"
          y2="46.6253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#114A8B" />
          <stop offset="1" stop-color="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_959_12563"
          x1="28.0231"
          y1="26.9314"
          x2="25.1353"
          y2="27.9078"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0.3" />
          <stop offset="0.071" stop-opacity="0.2" />
          <stop offset="0.321" stop-opacity="0.1" />
          <stop offset="0.623" stop-opacity="0.05" />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_959_12563"
          x1="26.4347"
          y1="8.66763"
          x2="40.1388"
          y2="45.1779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3CCBF4" />
          <stop offset="1" stop-color="#2892DF" />
        </linearGradient>
        <clipPath id="clip0_959_12563">
          <rect
            width="52"
            height="52"
            fill="white"
            transform="translate(0.511719)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Azure;
