// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VueporteqSEO_section__-Ca3J {
  margin-top: 8rem;
  margin-bottom: 9.2rem;
}

.VueporteqSEO_innerContainer__7oDG6 {
  max-width: 145rem;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  column-gap: 5rem;
}

.VueporteqSEO_imageContainer__xZ80C {
  max-width: 58.6rem;
  width: 50%;
}
.VueporteqSEO_imageContainer__xZ80C > img {
  width: 100%;
}

.VueporteqSEO_textContainer__9NOC1 {
  max-width: 61.6rem;
  padding-top: 1.2rem;
  width: 50%;
}

.VueporteqSEO_aboutDescriptionText__UUGre {
  color: var(--gray-666);
  line-height: 2.74rem;
}

.VueporteqSEO_pointsContainer__z8yCl {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-bottom: 4rem;
}

.VueporteqSEO_pointItem__fT-NE {
  display: flex;
  column-gap: 1.8rem;
}

.VueporteqSEO_pointItemHeading__91Nek {
  font-weight: 600;

  /* margin-bottom: 0.2rem; */
}

@media (max-width: 1024px) {
  .VueporteqSEO_section__-Ca3J {
    margin-top: 6rem;
    margin-bottom: 4rem;
  }
  .VueporteqSEO_innerContainer__7oDG6 {
    flex-direction: column;
    row-gap: 3rem;
  }
  .VueporteqSEO_textContainer__9NOC1 {
    max-width: 100%;
    width: 100%;
  }
  .VueporteqSEO_imageContainer__xZ80C {
    margin: auto;
    max-width: 45rem;
    width: 100%;
  }
}

@media (max-width: 640px) {
  .VueporteqSEO_section__-Ca3J {
    margin-bottom: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/SEO/VueporteqSEO/VueporteqSEO.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;;EAEhB,2BAA2B;AAC7B;;AAEA;EACE;IACE,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,sBAAsB;IACtB,aAAa;EACf;EACA;IACE,eAAe;IACf,WAAW;EACb;EACA;IACE,YAAY;IACZ,gBAAgB;IAChB,WAAW;EACb;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".section {\n  margin-top: 8rem;\n  margin-bottom: 9.2rem;\n}\n\n.innerContainer {\n  max-width: 145rem;\n  width: 85%;\n  margin: auto;\n  display: flex;\n  justify-content: space-between;\n  column-gap: 5rem;\n}\n\n.imageContainer {\n  max-width: 58.6rem;\n  width: 50%;\n}\n.imageContainer > img {\n  width: 100%;\n}\n\n.textContainer {\n  max-width: 61.6rem;\n  padding-top: 1.2rem;\n  width: 50%;\n}\n\n.aboutDescriptionText {\n  color: var(--gray-666);\n  line-height: 2.74rem;\n}\n\n.pointsContainer {\n  display: flex;\n  flex-direction: column;\n  row-gap: 1.5rem;\n  margin-bottom: 4rem;\n}\n\n.pointItem {\n  display: flex;\n  column-gap: 1.8rem;\n}\n\n.pointItemHeading {\n  font-weight: 600;\n\n  /* margin-bottom: 0.2rem; */\n}\n\n@media (max-width: 1024px) {\n  .section {\n    margin-top: 6rem;\n    margin-bottom: 4rem;\n  }\n  .innerContainer {\n    flex-direction: column;\n    row-gap: 3rem;\n  }\n  .textContainer {\n    max-width: 100%;\n    width: 100%;\n  }\n  .imageContainer {\n    margin: auto;\n    max-width: 45rem;\n    width: 100%;\n  }\n}\n\n@media (max-width: 640px) {\n  .section {\n    margin-bottom: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `VueporteqSEO_section__-Ca3J`,
	"innerContainer": `VueporteqSEO_innerContainer__7oDG6`,
	"imageContainer": `VueporteqSEO_imageContainer__xZ80C`,
	"textContainer": `VueporteqSEO_textContainer__9NOC1`,
	"aboutDescriptionText": `VueporteqSEO_aboutDescriptionText__UUGre`,
	"pointsContainer": `VueporteqSEO_pointsContainer__z8yCl`,
	"pointItem": `VueporteqSEO_pointItem__fT-NE`,
	"pointItemHeading": `VueporteqSEO_pointItemHeading__91Nek`
};
export default ___CSS_LOADER_EXPORT___;
