import React from "react";
import styles from "./VueporteqSEO.module.css";
import TickIcon from "../../../icons/TickIcon";
import img from "./../../../../assets/img/SEOSupport/VueporteqSEO.png";
import TextHeading from "../../../partials/TextHeading/TextHeading";

const points = [
  {
    heading: "Serious security",
    text: "Host secure web conferencing sessions on our virtual meeting software by locking your meetings",
  },
  {
    heading: "Serious security",
    text: "Host secure web conferencing sessions on our virtual meeting software by locking your meetings",
  },
  {
    heading: "Unbeatable uptime",
    text: "Backed by an industry leading 99.999% service uptime, Vueporteq IT Solutions Web Conference is designed for dependability.",
  },
  {
    heading: "Serious security",
    text: "Host secure web conferencing sessions on our virtual meeting software by locking your meetings",
  },
  {
    heading: "Unbeatable uptime",
    text: "Backed by an industry leading 99.999% service uptime, Vueporteq IT Solutions Web Conference is designed for dependability.",
  },
];

const VueporteqSEO = () => {
  return (
    <section className={styles.section}>
      <TextHeading
        heading="Why Vueporteq SEO and SMM Support?"
        text="Vueporteq IT Solutions has a host of built-in integrations that help your business take-off faster."
      />
      <div className={styles.innerContainer}>
        <div className={styles.imageContainer}>
          <img src={img} alt="img" />
        </div>
        <div className={styles.textContainer}>
          <div className={`${styles.pointsContainer} caption`}>
            {points.map((item) => (
              <div className={styles.pointItem}>
                <span>
                  <TickIcon />
                </span>
                <div>
                  <p className={styles.pointItemHeading}>{item.heading}</p>
                  <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VueporteqSEO;
