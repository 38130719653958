import React from "react";
import styles from "./CrmSystemsCard.module.css";
import img from "./../../../../assets/img/CRM/CrmSystemsCard.png";
import { Link } from "react-router-dom";

const CrmSystemsCard = () => {
  return (
    <section className={styles.borderBottom}>
      <div className={styles.innerContainer}>
        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            Vueporteq designs and implements robust
            <span> CRM systems </span>
            that personalize customer experience and effectively automate sales,
            marketing, and customer service operations.
          </h3>
          <h4 className="heading2 caption">
            As one of the leading Information
            Technology consulting company, Vueporteq IT
            Solutions delivers tailored IT solutions to organizations Globally.
          </h4>
          <Link to={"/contact"}>
            <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
          </Link>
        </div>

        <img src={img} alt="" className={styles.img} />
      </div>
    </section>
  );
};

export default CrmSystemsCard;
