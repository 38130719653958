import React from "react";
import TickIcon from "../../../icons/TickIcon";
import styles from "./CrmIntegrations.module.css";
import img from "./../../../../assets/img/CRM/CRMIntegrations.png";
import { Link } from "react-router-dom";

const points = [
  {
    heading: "See and Solve Issues Fast",
    text: "Identify problem areas long before they become full-blown issues with powerful case tracking and dashboards.",
  },
  {
    heading: "Reduced customer service workload",
    text: "Thanks to the effective self-service tools for customers.",
  },
  {
    heading: "Higher customer retention and loyalty",
    text: "Due to a personalized approach to each customer, enabled by a well-documented conversation and sales history.",
  },
];

const CrmIntegrations = () => {
  return (
    <section className={styles.section}>
      <div className={styles.innerContainer}>
        <div className={styles.textContainer}>
          <h3 className={styles.heading}>We are Your Integration Powerhouse</h3>
          <p
            className={`caption fontRoboto ${styles.aboutDescription} ${styles.aboutDescriptionText}`}
          >
            Integrate Vueporteq CRM with over 800+ apps
          </p>

          <div className={`${styles.pointsContainer} caption`}>
            {points.map((item) => (
              <div className={styles.pointItem}>
                <span>
                  <TickIcon />
                </span>
                <div>
                  <p className={styles.pointItemHeading}>{item.heading}</p>
                  <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                    {item.text}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <Link to={"/contact"}>
            <button className="btnOutlined">FREE DEMO</button>
          </Link>
        </div>
        <div className={styles.imageContainer}>
          <img src={img} alt="img" />
        </div>
      </div>
    </section>
  );
};

export default CrmIntegrations;
