import React from "react";
import BgContainerImg from "../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../assets/img/ERP/header.png";
const ErpHeader = () => {
  return (
    <>
      <BgContainerImg
        btns={["HOME ", "PRODUCTS", "ERP"]}
        img={img}
        heading="ERP"
        isTab={true}
      />
    </>
  );
};

export default ErpHeader;
