import React from "react";

const NaturalLanguageProcessing = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.625 21.5625H10.3125V23.4375H5.625V21.5625ZM5.625 17.8125H10.3125V19.6875H5.625V17.8125Z"
        fill="#1BA9B7"
      />
      <path
        d="M12.1875 28.125H3.75C2.71875 28.125 1.875 27.2813 1.875 26.25V15.9375C1.875 14.9062 2.71875 14.0625 3.75 14.0625H12.1875C13.2188 14.0625 14.0625 14.9062 14.0625 15.9375V26.25C14.0625 27.2813 13.2188 28.125 12.1875 28.125ZM3.75 15.9375V26.25H12.1875V15.9375H3.75ZM17.8125 1.875H25.3125V3.75H17.8125V1.875ZM20.625 5.625H28.125V7.5H20.625V5.625ZM20.625 9.375H28.125V11.25H20.625V9.375ZM17.8125 13.125H25.3125V15H17.8125V13.125ZM20.625 16.875H28.125V18.75H20.625V16.875ZM11.25 0.9375L9.9375 2.25L12.375 4.6875H3.75C2.71875 4.6875 1.875 5.53125 1.875 6.5625V11.25H3.75V6.5625H12.375L9.9375 9L11.25 10.3125L15.9375 5.625L11.25 0.9375Z"
        fill="#1BA9B7"
      />
    </svg>
  );
};

export default NaturalLanguageProcessing;
