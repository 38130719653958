import React from "react";
import MtcPageHeader from "../components/app/NssplMtcPage/MtcPageComponents/MtcPageHeader/MtcPageHeader";
import MtcPageAboutSection from "../components/app/NssplMtcPage/MtcPageComponents/MtcPageAboutSection/MtcPageAboutSection";
import MtcHelpSection from "../components/app/NssplMtcPage/MtcPageComponents/MtcHelpSection/MtcHelpSection";
import styles from "../styles/MtcPage.module.css";
import TechnologyImgCard from "../components/app/NssplMtcPage/MtcPageComponents/TechnologyImgCard/TechnologyImgCard";
import MtcQuoteForm from "../components/app/NssplMtcPage/MtcPageComponents/MtcHelpSection/MtcQuoteForm/MtcQuoteForm";
import MtcAvidianCrm from "../components/app/NssplMtcPage/MtcPageComponents/MtcAvidianCrm/MtcAvidianCrm";
import FooterHeader from "../components/app/NssplAvidianCrm/FooterHeader/FooterHeader";

const NssplMtc = () => {
  return (
    <>
      <MtcPageHeader />
      <MtcPageAboutSection />
      <MtcHelpSection />
      <TechnologyImgCard
        bgImageClass={styles.technologyCardImg}
        text="Experience seamless integration with our partner brands, ensuring optimal performance and collaboration on the <span className='textOrange' >MTC platform</span>."
        btnText="View plans"
      />
      <MtcQuoteForm />
      <MtcAvidianCrm />
      <FooterHeader />
    </>
  );
};

export default NssplMtc;
