import React from "react";
import styles from "./MyTrueCard.module.css";
import img from "./../../../../assets/img/mtc/mtc-about.png";

const MyTrueCard = () => {
  return (
    <section className={styles.sectionBgColor}>
      <div className={styles.innerContainer}>
        <div className={styles.TextContainer}>
          <p className={styles.FeaturedHeading}>FEATURED PRODUCT</p>
          <h3 className={styles.heading}>MY TRUE CLOUD</h3>
          <h4 className="heading2 caption">
            Set of digital tools and platforms are designed for Collaborative
            applications. This helps organizations to facilitate communication,
            coordination, and cooperation among individuals or teams working
            together on projects or tasks, regardless of their physical
            location.
          </h4>
          <button className={`btnPrimary ${styles.btn}`}>VIEW PRODUCT</button>
        </div>

        <img src={img} alt="" className={styles.img} />
      </div>
    </section>
  );
};

export default MyTrueCard;
