// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../../assets//img/nssplimg/footer.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterHeader_textHeadingContainer__VGh7N {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 100%;
  text-align: center;
  padding: 6rem 0px;
}
.FooterHeader_heading__Henz\\+ {
  margin-bottom: 2rem;
  color: #fff;
  letter-spacing: 0.3px;
  font-weight: 600;
}

.FooterHeader_borderContainer__iWya5 {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
}
.FooterHeader_BoderBlue__pdSfO {
  border: 2px solid #1cb9c8;
  width: 25px;
  display: block;
}
.FooterHeader_BoderWhite__K8730 {
  border: 2px solid #fff;
  width: 50px;
  display: block;
}
.FooterHeader_btn__WiSOr {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #1cb9c8;
}
.FooterHeader_btn__WiSOr:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/NssplAvidianCrm/FooterHeader/FooterHeader.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAsE;EACtE,4BAA4B;EAC5B,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,WAAW;EACX,cAAc;AAChB;AACA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB","sourcesContent":[".textHeadingContainer {\n  background-image: url(\"./../../../../assets//img/nssplimg/footer.png\");\n  background-repeat: no-repeat;\n  background-size: 100%;\n  text-align: center;\n  padding: 6rem 0px;\n}\n.heading {\n  margin-bottom: 2rem;\n  color: #fff;\n  letter-spacing: 0.3px;\n  font-weight: 600;\n}\n\n.borderContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  column-gap: 1rem;\n  margin-bottom: 1rem;\n}\n.BoderBlue {\n  border: 2px solid #1cb9c8;\n  width: 25px;\n  display: block;\n}\n.BoderWhite {\n  border: 2px solid #fff;\n  width: 50px;\n  display: block;\n}\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  color: #ffffff;\n  font-weight: 500;\n  letter-spacing: 1px;\n  background-color: #1cb9c8;\n}\n.btn:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textHeadingContainer": `FooterHeader_textHeadingContainer__VGh7N`,
	"heading": `FooterHeader_heading__Henz+`,
	"borderContainer": `FooterHeader_borderContainer__iWya5`,
	"BoderBlue": `FooterHeader_BoderBlue__pdSfO`,
	"BoderWhite": `FooterHeader_BoderWhite__K8730`,
	"btn": `FooterHeader_btn__WiSOr`
};
export default ___CSS_LOADER_EXPORT___;
