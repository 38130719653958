// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RankHigher_borderBottom__cP8DW {
  border-bottom: 1px solid #e7e7e7;
}

.RankHigher_innerContainer__eIXgD {
  max-width: 150.9rem;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3.4rem;
}

.RankHigher_TextContainer__0XcQE {
  max-width: 76.6rem;
}
.RankHigher_heading__NDtQA {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
}
.RankHigher_heading__NDtQA span {
  color: #1cb9c8;
}

.RankHigher_btn__Wm2SL {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1px;
}
.RankHigher_btn__Wm2SL:hover {
  cursor: pointer;
}

.RankHigher_img__lHfCJ {
  max-width: 50.8rem;
}

@media (max-width: 1024px) {
  .RankHigher_borderBottom__cP8DW {
    margin-top: 2rem;
    padding-bottom: 3rem;
  }
  .RankHigher_innerContainer__eIXgD > div,
  .RankHigher_innerContainer__eIXgD > img {
    max-width: 50%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .RankHigher_innerContainer__eIXgD {
    flex-direction: column-reverse;
  }
  .RankHigher_innerContainer__eIXgD > img {
    max-width: 440px;
  }
  .RankHigher_innerContainer__eIXgD > div {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/SEO/RankHigher/RankHigher.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,gBAAgB;IAChB,oBAAoB;EACtB;EACA;;IAEE,cAAc;IACd,WAAW;EACb;AACF;;AAEA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".borderBottom {\n  border-bottom: 1px solid #e7e7e7;\n}\n\n.innerContainer {\n  max-width: 150.9rem;\n  width: 90%;\n  margin: auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 3.4rem;\n}\n\n.TextContainer {\n  max-width: 76.6rem;\n}\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n}\n.heading span {\n  color: #1cb9c8;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  color: #ffffff;\n  font-weight: 500;\n  letter-spacing: 1px;\n}\n.btn:hover {\n  cursor: pointer;\n}\n\n.img {\n  max-width: 50.8rem;\n}\n\n@media (max-width: 1024px) {\n  .borderBottom {\n    margin-top: 2rem;\n    padding-bottom: 3rem;\n  }\n  .innerContainer > div,\n  .innerContainer > img {\n    max-width: 50%;\n    width: 100%;\n  }\n}\n\n@media (max-width: 768px) {\n  .innerContainer {\n    flex-direction: column-reverse;\n  }\n  .innerContainer > img {\n    max-width: 440px;\n  }\n  .innerContainer > div {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderBottom": `RankHigher_borderBottom__cP8DW`,
	"innerContainer": `RankHigher_innerContainer__eIXgD`,
	"TextContainer": `RankHigher_TextContainer__0XcQE`,
	"heading": `RankHigher_heading__NDtQA`,
	"btn": `RankHigher_btn__Wm2SL`,
	"img": `RankHigher_img__lHfCJ`
};
export default ___CSS_LOADER_EXPORT___;
