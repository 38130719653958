import React from "react";
import styles from "./Premon.module.css";
import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../../assets/img/CaseStudies/PremonHeader.png";
import Premon2 from "./../../../../assets/img/CaseStudies/Premon2.png";
import Premon3 from "./../../../../assets/img/CaseStudies/Premon3.png";
import imgBlue from "./../../../../assets/img/CaseStudies/imgBlue.png";
import Five from "./../../../../assets/img/CaseStudies/AllIcons/Five.svg";
import Six from "./../../../../assets/img/CaseStudies/AllIcons/Six.svg";
import Seven from "./../../../../assets/img/CaseStudies/AllIcons/Seven.svg";
import Eight from "./../../../../assets/img/CaseStudies/AllIcons/Eight.svg";
import Nine from "./../../../../assets/img/CaseStudies/AllIcons/Nine.svg";
import Ten from "./../../../../assets/img/CaseStudies/AllIcons/Ten.svg";
import Premon1 from "./../../../../assets/img/CaseStudies/Premon1.png";
import Premon4 from "./../../../../assets/img/CaseStudies/Premon4.png";
import PremonScreenshots from "./../../../../assets/img/CaseStudies/PremonScreenshots.png";
import CaseStudyPageComponet, {
  CaseStudyFlexComponet,
} from "../CaseStudyPageComponet/CaseStudyPageComponet";
import Antivirus from "../../../icons/Antivirus";
import Apple from "../../../icons/Apple";
import ReactIcon from "../../../icons/CaseStudy/ReactIcon";
import JsIcon from "../../../icons/CaseStudy/JsIcon";
import DotNetIcon from "../../../icons/CaseStudy/DotNetIcon";

const ImgTextData = [
  {
    problem: "01",
    problemHeading: "problem",
    img: Premon2,
    circleBgColor: "#f9c18a85",
    heading: "Problem",
    IconColor: "#5AABBF",
    list: [
      "Develop algorithms to process huge data and generate results",
      "Compare quarter-wise results for better understanding",
      "Make dashboards for automobile dealers and OEMs with relevant real-time data",
      "Capture real-time responses and generate quality progressive reports",
      "Create 'Action Plan' – automated workflow to manage issues by relevant users in the work plan",
      "Implement a messaging platform to speed up communication on one platform",
      "Provide a multi-brand solution – i.e. MG, Toyota, Renault, etc.",
      "Implement Premon CMS to manage OEMs",
      "Maintenance & Enhancement of the product is ongoing",
    ],
  },
  {
    problem: "02",
    problemHeading: "Solutions",
    img: Premon3,
    circleBgColor: "#f9c18a85",
    heading: "Solutions",
    IconColor: "#5AABBF",
    list: [
      "Development of the Android / IOS based mobile application",
      "Tight sprints for support and development",
      "Implement analytics module to track workflow and data on web and mobile",
      "Project Live, duration 6 months",
      "Ensure client and NSS development teams work seamlessly",
      "Handle extensive data processing and client engagements",
    ],
  },
];
const Data = [
  {
    img: Five,
    heading: "Real time communications",
    pay: `Flawless user ready mobile application`,
    BgColor: "#FDEDDEA1",
  },
  {
    img: Six,
    heading: "Task and project management",
    pay: " Increased efficiency for OEMs and Auto dealers",
    BgColor: "#EDEBFB",
  },
  {
    img: Seven,
    heading: "Workflow Automation",
    pay: `Integration of New technology to enhance speed and efficiency of the system.`,
    BgColor: "#EBF3FD",
  },
];
const Data1 = [
  {
    img: Eight,
    heading: "File sharing and document collaboration",
    pay: ` ROI on support is excellent as India team manages critical tasks at reasonable cost.`,
    BgColor: "#EDEBFB",
  },
  {
    img: Nine,
    heading: "Calendar and scheduling",
    pay: `Flexible model of engagement to make client experience seamless`,
    BgColor: "#E5FFEC",
  },
  {
    img: Ten,
    heading: "Access Control and Security",
    pay: `Premon got many big car manufacturers on board based on our quality work`,
    BgColor: "#fff8EA",
  },
];
const Premon = () => {
  return (
    <section className={styles.section}>
      <BgContainerImg
        img={img}
        heading="PREMON"
        isTab={true}
        btns={["HOME ", "CASE STUDIES", "PREMON"]}
      />
      <div className={`${styles.InnerContainer} ${styles.FISERV}`}>
        <CaseStudyFlexComponet
          heading="PREMON"
          heading1="Project Overview"
          text="Increased efficiency for OEMs and Auto dealers Interaction of New technology 
          to enhance speed and efficiency of the system. ROI on support is excellent as India team 
          manages critical tasks at reasonable cost."
          color={{
            mainHeading: "#5AABBF",
            manHeadingLine: "#5AABBF",
            heading: "#5AABBF",
            iconBg: "#F1FFFF",
            // iconColor: "#ff9955",
            imgColor: "#5AABBF",
          }}
          iconLog={[
            <ReactIcon />,
            <DotNetIcon />,
            <JsIcon />,
            <Antivirus />,
            <Apple />,
          ]}
        />
        <div className={styles.imgContainer}>
          <img src={Premon1} alt="" className="" />
        </div>
      </div>
      <CaseStudyPageComponet
        ImgTextData={ImgTextData}
        Data1={Data1}
        Data={Data}
        color={{
          problem: "#5AABBF",
          circle: "#5AABBF59",
          img: Premon4,
          ScreenshotsImg: PremonScreenshots,
          processIconColor: "#5AABBF",
        }}
        imgBlue={imgBlue}
      />
    </section>
  );
};

export default Premon;
