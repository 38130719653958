import React from "react";
import styles from "./OpportunityManagement.module.css";
import TickIcon from "../../icons/TickIcon";

const OpportunityManagementCard: React.FC<{
  Heading: string;
  img: string;
  points?: { text: string }[];
  text?: string;
  hideImgEffect?: boolean;
  showRoundedEffect?: boolean;
  innerContainerClasses?: string;
}> = ({
  Heading,
  img,
  points,
  text,
  hideImgEffect,
  showRoundedEffect,
  innerContainerClasses,
}) => {
  return (
    <section className={styles.section}>
      <div
        className={`${styles.innerContainer} ${innerContainerClasses || ""}`}
      >
        <div className={styles.textContainer}>
          <h3 className={styles.heading}>{Heading}</h3>

          {text && <p className="mb-[2.5rem] text-[#666666]">{text}</p>}

          <div className={`caption`}>
            {points &&
              points.map((item) => (
                <div className={styles.pointItem}>
                  <span>
                    <TickIcon />
                  </span>

                  <p className={`fontRoboto ${styles.aboutDescriptionText}`}>
                    {item.text}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src={img} alt="img" />
          {/* <img src={effectImg} alt="" className={styles.effect} /> */}
          {!hideImgEffect && <div className={styles.effectBorderImg}></div>}
          {showRoundedEffect && <div className={styles.roundedEffect} />}
        </div>
      </div>
      <div className={styles.border}></div>
    </section>
  );
};

export default OpportunityManagementCard;
