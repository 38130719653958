import React from "react";

const ConceptIdeation: React.FC<{
  processIconColor: string;
}> = ({ processIconColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.24964 18H15.7496V19.5H8.24964V18ZM9.74964 21H14.2496V22.5H9.74964V21ZM11.9996 1.5C10.0105 1.5 8.10286 2.29018 6.69634 3.6967C5.28981 5.10322 4.49964 7.01088 4.49964 9C4.44892 10.09 4.65772 11.1765 5.10886 12.17C5.56 13.1636 6.24059 14.0358 7.09464 14.715C7.84464 15.4125 8.24964 15.81 8.24964 16.5H9.74964C9.74964 15.12 8.91714 14.3475 8.10714 13.605C7.40616 13.0682 6.84749 12.3677 6.48007 11.5649C6.11265 10.7621 5.94769 9.88138 5.99964 9C5.99964 7.4087 6.63178 5.88258 7.757 4.75736C8.88222 3.63214 10.4083 3 11.9996 3C13.5909 3 15.1171 3.63214 16.2423 4.75736C17.3675 5.88258 17.9996 7.4087 17.9996 9C18.0507 9.88203 17.8846 10.7631 17.5159 11.566C17.1471 12.3689 16.587 13.069 15.8846 13.605C15.0821 14.355 14.2496 15.105 14.2496 16.5H15.7496C15.7496 15.81 16.1471 15.4125 16.9046 14.7075C17.7581 14.0295 18.4384 13.1585 18.8895 12.1663C19.3407 11.174 19.5498 10.0888 19.4996 9C19.4996 8.01509 19.3056 7.03982 18.9287 6.12987C18.5518 5.21993 17.9994 4.39314 17.3029 3.6967C16.6065 3.00026 15.7797 2.44781 14.8698 2.0709C13.9598 1.69399 12.9846 1.5 11.9996 1.5Z"
        fill={processIconColor}
      />
    </svg>
  );
};

export default ConceptIdeation;
