import React from "react";

const Cicon = () => {
  return (
    <svg
      width="47"
      height="52"
      viewBox="0 0 47 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_997_2025)">
        <path
          d="M46.0886 15.2732C46.0882 14.3998 45.9019 13.628 45.525 12.9655C45.1552 12.3141 44.6009 11.7681 43.8577 11.3364C37.7218 7.78827 31.5797 4.251 25.4458 0.699293C23.7921 -0.258193 22.1888 -0.223346 20.5472 0.748043C18.1049 2.19249 5.87731 9.2179 2.23343 11.3344C0.732953 12.2056 0.00270064 13.5388 0.00234055 15.2714C0 22.4071 0.00234055 29.5425 0 36.6782C0 37.5323 0.178782 38.2893 0.538868 38.9419C0.909035 39.613 1.47095 40.174 2.23145 40.6154C5.87551 42.7319 18.1047 49.757 20.5465 51.2016C22.1886 52.1733 23.7921 52.2082 25.4463 51.2503C31.5804 47.6984 37.7227 44.1614 43.8596 40.6133C44.6201 40.172 45.1821 39.6106 45.5522 38.9401C45.9118 38.2875 46.0909 37.5306 46.0909 36.6761C46.0909 36.6761 46.0909 22.4089 46.0886 15.2732Z"
          fill="#649AD2"
        />
        <path
          d="M23.1164 25.9053L0.539062 38.9417C0.90941 39.6129 1.47132 40.1739 2.23182 40.6153C5.87589 42.7318 18.1051 49.7568 20.5468 51.2015C22.189 52.1732 23.7925 52.2081 25.4467 51.2502C31.5808 47.6983 37.7231 44.1612 43.86 40.6131C44.6205 40.1719 45.1824 39.6105 45.5526 38.9399L23.1164 25.9053Z"
          fill="#004482"
        />
        <path
          d="M16.4297 29.7668C17.7435 32.0667 20.2131 33.6181 23.0463 33.6181C25.8969 33.6181 28.3806 32.0472 29.6877 29.7224L23.1168 25.9053L16.4297 29.7668Z"
          fill="#1A4674"
        />
        <path
          d="M46.0878 15.273C46.0874 14.3997 45.9011 13.6278 45.5242 12.9653L23.1152 25.9052L45.5514 38.9399C45.911 38.2873 46.0898 37.5305 46.0901 36.6759C46.0901 36.6759 46.0901 22.4087 46.0878 15.273Z"
          fill="#01589C"
        />
        <path
          d="M44.8558 26.8369H43.1026V28.5955H41.349V26.8369H39.5959V25.079H41.349V23.3208H43.1026V25.079H44.8558V26.8369ZM23.0464 10.625C28.652 10.625 33.5533 13.6475 36.2201 18.1558L36.3 18.2928L29.6383 22.1399C28.318 19.8633 25.8613 18.3313 23.0464 18.3313C18.837 18.3313 15.4246 21.7534 15.4246 25.9746C15.4232 27.305 15.7698 28.6126 16.4298 29.7668C17.7436 32.0667 20.2132 33.618 23.0464 33.618C25.8657 33.618 28.3261 32.0815 29.6444 29.7987L29.6878 29.7224L36.3398 33.5873C33.7022 38.209 28.737 41.3243 23.0464 41.3243C17.3662 41.3243 12.4087 38.2215 9.76751 33.6133C8.47805 31.3638 7.74023 28.7557 7.74023 25.9746C7.74023 17.4975 14.5928 10.625 23.0464 10.625ZM36.7053 23.3206V25.0788H38.4584V26.8369H36.7053V28.5955H34.9521V26.8369H33.1988V25.079H34.9521V23.3208L36.7053 23.3206Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_997_2025">
          <rect width="46.0909" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Cicon;
