import React from "react";

const Tab1 = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.625 14.25V4.75H26.125V8.3125H21.375C20.7453 8.31313 20.1416 8.56355 19.6963 9.00882C19.2511 9.45408 19.0006 10.0578 19 10.6875V17.8125H11.875V14.25H2.375V23.75H11.875V20.1875H19V27.3125C19.0006 27.9422 19.2511 28.5459 19.6963 28.9912C20.1416 29.4364 20.7453 29.6869 21.375 29.6875H26.125V33.25H35.625V23.75H26.125V27.3125H21.375V10.6875H26.125V14.25H35.625ZM9.5 21.375H4.75V16.625H9.5V21.375ZM28.5 26.125H33.25V30.875H28.5V26.125ZM28.5 7.125H33.25V11.875H28.5V7.125Z"
        fill="#1CB9C8"
      />
    </svg>
  );
};

export default Tab1;
