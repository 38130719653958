import React from "react";
import AboutHeader from "../components/app/AboutPage/AboutHeader/AboutHeader";
import BusinessProcessCard from "../components/app/AboutPage/BusinessProcess/BusinessProcessCard";
import Management from "../components/app/AboutPage/ManagementCard/Management";
import VerticalTestimonial from "../components/app/AboutPage/VerticalExposureTestimonial/VerticalTestimonial";
import PlanningCard from "../components/app/AboutPage/PlanningCard/PlanningCard";

const AboutPage = () => {
  return (
    <>
      <AboutHeader />
      <BusinessProcessCard />
      {/* <Management /> */}
      <VerticalTestimonial />
      <PlanningCard />
    </>
  );
};

export default AboutPage;
