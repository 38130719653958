import React from "react";

const CustomAiSolutions = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="#1BA9B7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1436_5124)">
        <path d="M29.2906 13.4122V10.2365H26.1149V7.06082C26.1124 6.21936 25.777 5.41307 25.182 4.81806C24.587 4.22305 23.7807 3.88766 22.9392 3.88515H19.7635V0.709473H16.5879V3.88515H13.4122V0.709473H10.2365V3.88515H7.06082C6.21936 3.88766 5.41307 4.22305 4.81806 4.81806C4.22305 5.41307 3.88766 6.21936 3.88515 7.06082V10.2365H0.709473V13.4122H3.88515V16.5879H0.709473V19.7635H3.88515V22.9392C3.88766 23.7807 4.22305 24.587 4.81806 25.182C5.41307 25.777 6.21936 26.1124 7.06082 26.1149H10.2365V29.2906H13.4122V26.1149H16.5879V29.2906H19.7635V26.1149H22.9392C23.7807 26.1124 24.587 25.777 25.182 25.182C25.777 24.587 26.1124 23.7807 26.1149 22.9392V19.7635H29.2906V16.5879H26.1149V13.4122H29.2906ZM22.9392 22.9392H7.06082V7.06082H22.9392V22.9392Z" />
        <path d="M13.9854 8.64868H11.8498L8.6582 21.3514H10.2889L11.0257 18.3742H14.7031L15.4208 21.3514H17.1071L13.9854 8.64868ZM11.24 17.1023L12.8564 10.1571H12.9295L14.4903 17.1023H11.24ZM18.5631 8.64868H20.151V21.3514H18.5631V8.64868Z" />
      </g>
      <defs>
        <clipPath id="clip0_1436_5124">
          <rect width="30" height="30" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomAiSolutions;
