// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomizeCard_sectionBgColor__9zNyt {
  background-color: #f7f7f7;
  border-bottom: 1px solid #e7e7e7;
}

.CustomizeCard_innerContainer__adXX\\+ {
  max-width: 150.9rem;
  width: 95%;
  margin: 6rem auto 5rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3.4rem;
}

.CustomizeCard_TextContainer__ibp16 {
  max-width: 56rem;
}
.CustomizeCard_heading__N7jkz {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
  line-height: 3.9rem;
}

.CustomizeCard_btn__NktKx {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  color: var(--color-primary);
  font-weight: 500;
  background-color: transparent;
  border: 2px solid #e1e1e1;
  letter-spacing: 1px;
}
.CustomizeCard_btn__NktKx:hover {
  color: #232323;
}

.CustomizeCard_img__pjUDZ {
  max-width: 50.8rem;
  padding-right: 1.5rem;
}
@media (max-width: 1280px) {
  .CustomizeCard_innerContainer__adXX\\+ {
    column-gap: 5rem;
  }
  .CustomizeCard_innerContainer__adXX\\+ > div,
  .CustomizeCard_innerContainer__adXX\\+ > img {
    max-width: 50%;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .CustomizeCard_innerContainer__adXX\\+ {
    flex-direction: column;
    row-gap: 3rem;
  }
  .CustomizeCard_innerContainer__adXX\\+ > div {
    max-width: 100%;
  }
  .CustomizeCard_innerContainer__adXX\\+ > img {
    max-width: 55rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CrmPage/CustomizeCard/CustomizeCard.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,2BAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE;IACE,gBAAgB;EAClB;EACA;;IAEE,cAAc;IACd,WAAW;EACb;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".sectionBgColor {\n  background-color: #f7f7f7;\n  border-bottom: 1px solid #e7e7e7;\n}\n\n.innerContainer {\n  max-width: 150.9rem;\n  width: 95%;\n  margin: 6rem auto 5rem auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 3.4rem;\n}\n\n.TextContainer {\n  max-width: 56rem;\n}\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n  line-height: 3.9rem;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  color: var(--color-primary);\n  font-weight: 500;\n  background-color: transparent;\n  border: 2px solid #e1e1e1;\n  letter-spacing: 1px;\n}\n.btn:hover {\n  color: #232323;\n}\n\n.img {\n  max-width: 50.8rem;\n  padding-right: 1.5rem;\n}\n@media (max-width: 1280px) {\n  .innerContainer {\n    column-gap: 5rem;\n  }\n  .innerContainer > div,\n  .innerContainer > img {\n    max-width: 50%;\n    width: 100%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .innerContainer {\n    flex-direction: column;\n    row-gap: 3rem;\n  }\n  .innerContainer > div {\n    max-width: 100%;\n  }\n  .innerContainer > img {\n    max-width: 55rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionBgColor": `CustomizeCard_sectionBgColor__9zNyt`,
	"innerContainer": `CustomizeCard_innerContainer__adXX+`,
	"TextContainer": `CustomizeCard_TextContainer__ibp16`,
	"heading": `CustomizeCard_heading__N7jkz`,
	"btn": `CustomizeCard_btn__NktKx`,
	"img": `CustomizeCard_img__pjUDZ`
};
export default ___CSS_LOADER_EXPORT___;
