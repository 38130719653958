import React from "react";
import styles from "./Services.module.css";
import img from "./../../../../../assets/img/Services.png";
import effect from "./../../../../../assets/img/Aiml/effect.png";
import { Link } from "react-router-dom";
import CustomAiSolutions from "../../../../icons/aimlIcons/CustomAiSolutions";
import MachineLearningModelsIcon from "../../../../icons/aimlIcons/MachineLearningModelsIcon";
import DataEngineeringIcon from "../../../../icons/aimlIcons/DataEngineeringIcon";
import AiDrivenAutomationIcon from "../../../../icons/aimlIcons/AiDrivenAutomationIcon";
import NaturalLanguageProcessing from "../../../../icons/aimlIcons/NaturalLanguageProcessing";
import ComputerVisionIcon from "../../../../icons/aimlIcons/ComputerVisionIcon";
import AiConsultingIcon from "../../../../icons/aimlIcons/AiConsultingIcon";
import PredictiveAnalyticsIcon from "../../../../icons/aimlIcons/PredictiveAnalyticsIcon";
import AiTrainingSupportIcon from "../../../../icons/aimlIcons/AiTrainingSupportIcon";

const data = [
  {
    heading: "Custom AI Solutions",
    icon: <CustomAiSolutions />,
    pay: `Get bespoke AI solutions tailored to your business, enhancing efficiency and driving innovation with cutting-edge technology.`,
  },
  {
    heading: "Machine Learning Models",
    icon: <MachineLearningModelsIcon />,
    pay: `Develop and deploy robust machine learning models to improve decision-making, operational efficiency, and gain actionable insights.`,
  },
  {
    heading: "Data Engineering",
    icon: <DataEngineeringIcon />,
    pay: `Transform raw data into valuable insights with scalable data pipelines, ensuring accurate and effective AI and ML initiatives.`,
  },
];
const data1 = [
  {
    heading: "AI-Driven Automation",
    icon: <AiDrivenAutomationIcon />,
    pay: `Automate processes with AI-driven systems, reducing manual effort, enhancing accuracy, and improving overall efficiency.`,
  },
  {
    heading: "Natural Language Processing (NLP)",
    icon: <NaturalLanguageProcessing />,
    pay: `Develop NLP models for sentiment analysis, language translation, and text summarization to enhance customer interactions and insights.`,
  },
  {
    heading: "Computer Vision",
    icon: <ComputerVisionIcon />,
    pay: `Create advanced computer vision solutions for image and video analysis, detecting objects, recognizing patterns, and making informed decisions.`,
  },
];
const data2 = [
  {
    heading: "AI Consulting",
    icon: <AiConsultingIcon />,
    pay: `Get strategic guidance on AI adoption, ensuring initiatives align with business goals and deliver measurable value.`,
  },
  {
    heading: "Predictive Analytics",
    icon: <PredictiveAnalyticsIcon />,
    pay: `Develop models to predict outcomes, identify risks, and uncover opportunities, enabling informed decision-making and optimized operations.`,
  },
  {
    heading: "AI Training and Support",
    icon: <AiTrainingSupportIcon />,
    pay: `Equip your team with AI skills through comprehensive training programs and ongoing support. Ensure successful implementation and maintenance of AI solutions.`,
  },
];
const Services = () => {
  return (
    <section className={styles.sectionBgColor}>
      <img
        src={effect}
        alt="effect"
        className={`${styles.effectImg} pointer-events-none`}
      />
      <div className={styles.innerContainer}>
        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            Accelerate Your AI Journey with Our AI/ML Development Services!
          </h3>
          <h4 className="heading2 caption">
            Vueporteq offers reliable AI and ML development services that
            convert your vision into reality. Our expert team delivers
            intelligent ML solutions, Unlocking data’s true potential for
            enabling businesses to achieve their true success.
          </h4>
          <Link to={"/contact"}>
            <button className={`btnPrimary ${styles.btn} uppercase`}>
              Get Started
            </button>
          </Link>
        </div>

        <div className={styles.imgContainer}>
          <div>
            {data.map((item) => (
              <div className={styles.card}>
                <span className={styles.icon}>{item.icon}</span>
                <h4 className={`${styles.text} body1 heading2`}>
                  {item.heading}
                </h4>
                <p className={`${styles.pay} heading2`}>{item.pay}</p>
              </div>
            ))}
          </div>
          <div className={styles.card2}>
            {data1.map((item) => (
              <div className={styles.card}>
                <span className={styles.icon}>{item.icon}</span>
                <h4 className={`${styles.text} body1 heading2`}>
                  {item.heading}
                </h4>
                <p className={`${styles.pay} heading2`}>{item.pay}</p>
              </div>
            ))}
          </div>
          <div>
            {data2.map((item) => (
              <div className={styles.card}>
                <span className={styles.icon}>{item.icon}</span>
                <h4 className={`${styles.text} body1 heading2`}>
                  {item.heading}
                </h4>
                <p className={`${styles.pay} heading2`}>{item.pay}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
