import React from "react";
import styles from "./TextHeading.module.css";

const TextHeading: React.FC<{ heading: string; text: string }> = ({
  heading,
  text,
}) => {
  return (
    <>
      <div className={styles.textHeadingContainer}>
        <h3 className="heading">{heading}</h3>
        <div className={styles.borderContainer}>
          <span className={styles.BoderBlack}></span>
          <span className={styles.BoderBlue}></span>
        </div>
        <p className={`${styles.pay} heading2`}>{text}</p>
      </div>
    </>
  );
};

export default TextHeading;
