import React from "react";
import TextHeading from "../../../partials/TextHeading/TextHeading";
import styles from "./CustomerService.module.css";
import CustomerServiceIcon1 from "../../../icons/CustomerServiceIcon1";
import CustomerServiceIcon2 from "../../../icons/CustomerServiceIcon2";
import CustomerServiceIcon3 from "../../../icons/CustomerServiceIcon3";

const cardData = [
  {
    icon: <CustomerServiceIcon1 />,
    title: "Startups",
  },
  {
    icon: <CustomerServiceIcon2 />,
    title: "SMBs",
  },

  {
    icon: <CustomerServiceIcon3 />,
    title: "Enterprise",
  },
];

const CustomerService = () => {
  return (
    <section className={styles.borderBottom}>
      <div className={styles.innerContainer}>
        <TextHeading
          heading="Great customer service for every business size"
          text="Our broader objective is to make quality tech available, accessible, and affordable to all. In doing so, we meet the needs of organizations of all sizes."
        />
        <div className={styles.cardContainer}>
          {cardData.map((item, i) => (
            <div className={styles.card}>
              {item.icon}
              <p className={styles.title}>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomerService;
