import React from "react";
import styles from "../styles/MtcPage.module.css";
import MyTrueCard from "../components/app/NssplAvidianCrm/AvidianCRMPage/MyTrueCard/MyTrueCard";
import AvidianCrmHeader from "../components/app/NssplAvidianCrm/AvidianCRMPage/AvidianCrmHeader";
import AvidianCrm from "../components/app/NssplAvidianCrm/AvidianCRMPage/AvidianCrm";
import CrmOutlook from "../components/app/NssplAvidianCrm/AvidianCRMPage/CrmOutlook/CrmOutlook";
import Features from "../components/app/NssplAvidianCrm/AvidianCRMPage/Features/Features";
import EmbeddedOutlook from "../components/app/NssplAvidianCrm/AvidianCRMPage/EmbeddedOutlook/EmbeddedOutlook";
import SalesAutomation from "../components/app/NssplAvidianCrm/AvidianCRMPage/SalesAutomation/SalesAutomation";
import TechnologyImgCard from "../components/app/ErpPage/TechnologyImgCard/TechnologyImgCard";
import MtcQuoteForm from "../components/app/NssplMtcPage/MtcPageComponents/MtcHelpSection/MtcQuoteForm/MtcQuoteForm";
import FooterHeader from "../components/app/NssplAvidianCrm/FooterHeader/FooterHeader";
// import MtcQuoteForm from "../components/app/NssplAvidianCrm/MtcQuoteForm/MtcQuoteForm";

const NssplAvidianCrm = () => {
  return (
    <div>
      <AvidianCrmHeader />
      <AvidianCrm />
      <CrmOutlook />
      <Features />
      <EmbeddedOutlook />
      <SalesAutomation />
      <TechnologyImgCard
        bgImageClass={styles.technologyCardImg}
        text="See what’s possible when you have growth-friendly technology and award-winning support behind you."
        btnText="VIEW PLANS"
      />
      <MtcQuoteForm />
      <MyTrueCard />
      <FooterHeader />
    </div>
  );
};

export default NssplAvidianCrm;
