import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2008_526)">
        <path
          d="M2.60626 15.5837V8.46702H0.67041V5.90469H2.60626V3.71612C2.60626 1.99633 3.74977 0.416992 6.38467 0.416992C7.4515 0.416992 8.24037 0.51641 8.24037 0.51641L8.17821 2.90919C8.17821 2.90919 7.37369 2.90158 6.49576 2.90158C5.54558 2.90158 5.39334 3.32723 5.39334 4.03371V5.90469H8.25375L8.12929 8.46702H5.39334V15.5837H2.60626Z"
          fill="#131313"
        />
      </g>
      <defs>
        <clipPath id="clip0_2008_526">
          <rect
            width="7.58333"
            height="15.1667"
            fill="white"
            transform="translate(0.67041 0.416992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
