// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesAutomation_innerContainer__\\+ABUw {
  max-width: 145rem;
  width: 90%;
  margin: 5rem auto 2rem;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  column-gap: 1rem;
}

.SalesAutomation_imageContainer__HCkP7 {
  max-width: 60.6rem;
  width: 50%;
}
.SalesAutomation_imageContainer__HCkP7 > img {
  width: 100%;
}

.SalesAutomation_textContainer__Gnvnu {
  max-width: 55.6rem;
  padding-top: 1.2rem;
  width: 50%;
  margin-top: 3rem;
}
.SalesAutomation_heading__fCNAV {
  font-weight: 600;
  margin-bottom: 2.6rem;
}

.SalesAutomation_aboutDescriptionText__87wE3 {
  color: var(--gray-666);
  line-height: 2.74rem;
  margin-bottom: 1rem;
}

.SalesAutomation_pointItem__gBV6P {
  display: flex;
  column-gap: 1.6rem;
  align-items: center;
}
.SalesAutomation_border__u1GbK {
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
}
.SalesAutomation_flexDirection__4gC2B {
  flex-direction: row-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/NssplAvidianCrm/AvidianCRMPage/SalesAutomation/SalesAutomation.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;EACV,sBAAsB;EACtB,aAAa;EACb,yBAAyB;EACzB,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;EACV,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,WAAW;AACb;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".innerContainer {\n  max-width: 145rem;\n  width: 90%;\n  margin: 5rem auto 2rem;\n  display: flex;\n  /* align-items: center; */\n  justify-content: space-around;\n  column-gap: 1rem;\n}\n\n.imageContainer {\n  max-width: 60.6rem;\n  width: 50%;\n}\n.imageContainer > img {\n  width: 100%;\n}\n\n.textContainer {\n  max-width: 55.6rem;\n  padding-top: 1.2rem;\n  width: 50%;\n  margin-top: 3rem;\n}\n.heading {\n  font-weight: 600;\n  margin-bottom: 2.6rem;\n}\n\n.aboutDescriptionText {\n  color: var(--gray-666);\n  line-height: 2.74rem;\n  margin-bottom: 1rem;\n}\n\n.pointItem {\n  display: flex;\n  column-gap: 1.6rem;\n  align-items: center;\n}\n.border {\n  border-bottom: 1px solid #e7e7e7;\n  width: 100%;\n}\n.flexDirection {\n  flex-direction: row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `SalesAutomation_innerContainer__+ABUw`,
	"imageContainer": `SalesAutomation_imageContainer__HCkP7`,
	"textContainer": `SalesAutomation_textContainer__Gnvnu`,
	"heading": `SalesAutomation_heading__fCNAV`,
	"aboutDescriptionText": `SalesAutomation_aboutDescriptionText__87wE3`,
	"pointItem": `SalesAutomation_pointItem__gBV6P`,
	"border": `SalesAutomation_border__u1GbK`,
	"flexDirection": `SalesAutomation_flexDirection__4gC2B`
};
export default ___CSS_LOADER_EXPORT___;
