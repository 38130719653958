import React from "react";

const CompetitorsAnalysis: React.FC<{
  processIconColor: string;
}> = ({ processIconColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9998 15.9999C13.802 15.9999 13.6087 15.9412 13.4443 15.8313C13.2799 15.7214 13.1518 15.5653 13.0761 15.3826C13.0004 15.1999 12.9806 14.9988 13.0192 14.8049C13.0578 14.6109 13.153 14.4328 13.2928 14.2929L20.2928 7.29292C20.4814 7.11076 20.734 7.00997 20.9962 7.01224C21.2584 7.01452 21.5092 7.11969 21.6946 7.3051C21.88 7.49051 21.9852 7.74132 21.9875 8.00352C21.9897 8.26572 21.8889 8.51832 21.7068 8.70692L14.7068 15.7069C14.614 15.7999 14.5038 15.8737 14.3825 15.924C14.2612 15.9742 14.1311 16.0001 13.9998 15.9999ZM2.99979 16.9999C2.80204 16.9999 2.60874 16.9412 2.44433 16.8313C2.27992 16.7214 2.15178 16.5653 2.07611 16.3826C2.00044 16.1999 1.98064 15.9988 2.0192 15.8049C2.05777 15.6109 2.15298 15.4328 2.29279 15.2929L8.29279 9.29292C8.48139 9.11076 8.73399 9.00997 8.99619 9.01225C9.25838 9.01452 9.5092 9.11969 9.69461 9.3051C9.88001 9.49051 9.98518 9.74132 9.98746 10.0035C9.98974 10.2657 9.88894 10.5183 9.70679 10.7069L3.70679 16.7069C3.61405 16.7999 3.50385 16.8737 3.38252 16.924C3.26119 16.9742 3.13112 17.0001 2.99979 16.9999Z"
        fill={processIconColor}
      />
      <path
        d="M13.9994 15.9999C13.8681 16.0001 13.738 15.9742 13.6167 15.924C13.4954 15.8737 13.3852 15.7999 13.2924 15.7069L8.29243 10.7069C8.11027 10.5183 8.00948 10.2657 8.01176 10.0035C8.01403 9.74132 8.1192 9.49051 8.30461 9.3051C8.49002 9.11969 8.74083 9.01452 9.00303 9.01224C9.26523 9.00997 9.51783 9.11076 9.70643 9.29292L14.7064 14.2929C14.8462 14.4328 14.9414 14.6109 14.98 14.8049C15.0186 14.9988 14.9988 15.1999 14.9231 15.3826C14.8474 15.5653 14.7193 15.7214 14.5549 15.8313C14.3905 15.9412 14.1972 15.9999 13.9994 15.9999Z"
        fill={processIconColor}
      />
    </svg>
  );
};

export default CompetitorsAnalysis;
