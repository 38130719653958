// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomerService_innerContainer__Y-XMm {
  max-width: 150.9rem;
  width: 75%;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.CustomerService_cardContainer__OR-sF {
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
}

.CustomerService_card__ds50E {
  max-width: 22.75rem;
  padding: 5rem;
  width: 100%;
  box-shadow: 1px 4px 20px -2px #0000001a;
  text-align: center;
}
.CustomerService_card__ds50E svg {
  margin: auto;
}
.CustomerService_title__O53pn {
  margin-top: 1rem;
  color: #232323;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  line-height: 2.1rem;
}
@media (max-width: 1000px) {
  .CustomerService_cardContainer__OR-sF {
    justify-content: center;
    margin-top: 5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CrmPage/CustomerService/CustomerService.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,uCAAuC;EACvC,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,qCAAqC;EACrC,mBAAmB;AACrB;AACA;EACE;IACE,uBAAuB;IACvB,gBAAgB;EAClB;AACF","sourcesContent":[".innerContainer {\n  max-width: 150.9rem;\n  width: 75%;\n  margin: auto;\n  margin-top: 6rem;\n  margin-bottom: 6rem;\n}\n\n.cardContainer {\n  display: flex;\n  align-items: center;\n  gap: 3rem;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.card {\n  max-width: 22.75rem;\n  padding: 5rem;\n  width: 100%;\n  box-shadow: 1px 4px 20px -2px #0000001a;\n  text-align: center;\n}\n.card svg {\n  margin: auto;\n}\n.title {\n  margin-top: 1rem;\n  color: #232323;\n  font-weight: 600;\n  font-size: 1.5rem;\n  font-family: \"Montserrat\", sans-serif;\n  line-height: 2.1rem;\n}\n@media (max-width: 1000px) {\n  .cardContainer {\n    justify-content: center;\n    margin-top: 5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `CustomerService_innerContainer__Y-XMm`,
	"cardContainer": `CustomerService_cardContainer__OR-sF`,
	"card": `CustomerService_card__ds50E`,
	"title": `CustomerService_title__O53pn`
};
export default ___CSS_LOADER_EXPORT___;
