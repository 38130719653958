import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../../assets/img/CaseStudies/sports/header.png";
import imgBlue from "./../../../../assets/img/CaseStudies/imgBlue.png";
import Five from "./../../../../assets/img/CaseStudies/AllIcons/Five.svg";
import Icon1 from "./../../../../assets/img/CaseStudies/sports/Icon1.svg";
import Icon2 from "./../../../../assets/img/CaseStudies/sports/Icon2.svg";
import Icon3 from "./../../../../assets/img/CaseStudies/sports/Icon3.svg";
import Icon4 from "./../../../../assets/img/CaseStudies/sports/Icon4.svg";
import Icon5 from "./../../../../assets/img/CaseStudies/sports/Icon5.svg";
import Icon6 from "./../../../../assets/img/CaseStudies/sports/Icon6.svg";
import Icon7 from "./../../../../assets/img/CaseStudies/sports/Icon7.svg";
import styles from "../../../../components/app/CaseStudyPage/sport/Sport.module.css";
import CaseStudyPageComponet, {
  CaseStudyFlexComponet,
} from "../CaseStudyPageComponet/CaseStudyPageComponet";

import ReactIcon from "../../../icons/CaseStudy/ReactIcon";
import NodeJsIcon from "../../../icons/CaseStudy/NodeJsIcon";
import MogoDbIcon from "../../../icons/CaseStudy/MogoDbIcon";
import Azure from "../../../icons/CaseStudy/Azure";
import img1 from "./../../../../assets/img/CaseStudies/sports/img1.png";
import img2 from "./../../../../assets/img/CaseStudies/sports/img2.png";
import img3 from "./../../../../assets/img/CaseStudies/sports/img3.png";
import img4 from "./../../../../assets/img/CaseStudies/sports/img4.png";
import img5 from "./../../../../assets/img/CaseStudies/sports/img5.png";
const ImgTextData = [
  {
    problem: "01",
    problemHeading: "problem",
    img: img2,
    circleBgColor: "#f9c18a85",
    heading: "Problem",
    IconColor: "#54B35E",
    list: [
      "Create B2C mobile and web application with live scores, news etc",
      "Have a video integration with tagging to sports / leagues",
      "Make dashboards for automobile dealers and OEMs with relevant real-time data",
      "Publish live and latest news to users as per their interest in sports",
      "Notifications for live match and upcoming tournaments",
      "User-specific tracking of sports and based on interest",
      "UI and UX planning and design as the client had no idea",
      "CMS for all content to be published, including videos, news, scores etc.",
      "User tracking and analysis dashboard for marketing",
      "Poll and market survey solution",
      "Live streaming of news and matches",
    ],
  },
  {
    problem: "02",
    problemHeading: "Solutions",
    img: img3,
    circleBgColor: "#f9c18a85",
    heading: "Solutions",
    IconColor: "#54B35E",
    list: [
      "Create B2C mobile and web application with live scores, news, etc.",
      "Have a video integration with tagging to sports / leagues",
      "Publish live and latest news to users as per their interest in sports",
      "Notifications for live match and upcoming tournaments",
      "User-specific tracking of sports and based on interest",
      "UI and UX planning and design as the client had no idea",
      "CMS for all content to be published, including videos, news, scores, etc.",
      "User tracking and analysis dashboard for marketing",
      "Poll and market survey solution",
      "Live streaming of news and matches",
    ],
  },
];
const Data = [
  {
    img: Five,
    pay: "Create B2C mobile and web application with live scores, news etc",
    BgColor: "#FDEDDEA1",
  },
  {
    img: Icon1,
    pay: "Have a video integration with tagging to sports / leagues",
    BgColor: "#E5FFEC",
  },
  {
    img: Icon2,
    pay: "Publish live and latest news to users as per their interest in sports. Notifications for live match and upcoming tournaments",
    BgColor: "#EDEBFB",
  },
  {
    img: Icon3,
    pay: "User specific tracking of sports and based on interest",
    BgColor: "#FDEDDEA1",
  },
];
const Data1 = [
  {
    img: Icon4,
    pay: "UI and UX planning and design as client had no idea",
    BgColor: "#EDEBFB",
  },
  {
    img: Icon5,
    pay: " CMS for all content to be published , videos, news, scores etc. • User tracking and analysis dashboard for marketing",
    BgColor: "#E5FFEC",
  },
  {
    img: Icon6,
    pay: "Poll and market survey solution",
    BgColor: "#FDEDDEA1",
  },
  {
    img: Icon7,
    pay: "Live streaming of news and matches",
    BgColor: "#fff8EA",
  },
];
const Sport = () => {
  return (
    <section>
      <BgContainerImg
        img={img}
        heading="SPORTS TAK"
        isTab={true}
        btns={["HOME ", "CASE STUDIES", "SPORTS TAK (TVTN)"]}
      />
      <div className={`${styles.InnerContainer}  `}>
        <CaseStudyFlexComponet
          heading="SPORTS TAK (TVTN)"
          heading1="Project Overview"
          text="SportsTak is a multisport platform that caters to all your sporting needs international and national. It brings you news ,videos, live analysis, live match score, match center, short video content , press conferences, match highlights , sporting polls, infographics, player statistics and much more from a wide variety of leagues including EPL, Asia , F1 Racing, India Vs Ireland ,
           T20 World cup, NBA, Champions League, La Liga, Pro Kabaddi League- the list goes on and on and on."
          color={{
            mainHeading: "#54B35E",
            manHeadingLine: "#55E16B",
            heading: "#54B35E",
            iconBg: "#F1FFFF",
            // iconColor: "#ff9955",
            imgColor: "#54B35E",
          }}
          iconLog={[<ReactIcon />, <NodeJsIcon />, <MogoDbIcon />, <Azure />]}
        />
        <div className={styles.imgContainer}>
          <img src={img1} alt="" />
        </div>
      </div>
      <CaseStudyPageComponet
        ImgTextData={ImgTextData}
        Data1={Data1}
        Data={Data}
        color={{
          problem: "#54B35E",
          circle: "#8AF9A285",
          img: img4,
          ScreenshotsImg: img5,
          processIconColor: "#54B35E",
        }}
        imgBlue={imgBlue}
      />
    </section>
  );
};

export default Sport;
