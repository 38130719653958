import React from "react";
import CaseStudyHeader from "../components/app/CaseStudyPage/CaseStudyHeader";
import CaseStudiesBgCard from "../components/app/CaseStudyPage/CaseStudiesCard/CaseStudiesBgCard";

const CaseStudies = () => {
  return (
    <div>
      <CaseStudyHeader />
      <CaseStudiesBgCard />
    </div>
  );
};

export default CaseStudies;
