import React from "react";
import img from "./../../../../assets/img/avidianCrm/avidianCrmHeader.png";
import BgContainerImg from "../../../partials/nssplPartials/BgContainerCard/BgContainerImg";

const AvidianCrmHeader = () => {
  return (
    <>
      <BgContainerImg img={img} heading="AVIDIAN CRM" isTab={true} />
    </>
  );
};

export default AvidianCrmHeader;
