// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntegrateTools_innerContainer__ooqmh {
  max-width: 130.6rem;
  width: 90%;
  margin: 6rem auto;
}
.IntegrateTools_cardContainer__A7VB4 {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 5rem;
  row-gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  /* max-width: 105.6rem; */
}
.IntegrateTools_card__MUDBW {
  width: 100%;
  box-shadow: 1px 4px 20px -2px #0000001a;
  padding: 0rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* max-width: 19rem; */
}
.IntegrateTools_imgLogo__7Omln {
  max-width: 15rem;
  max-height: 6rem;
  /* width: 100%; */
}

@media (max-width: 1280px) {
  .IntegrateTools_cardContainer__A7VB4 {
    column-gap: 2rem;
  }
}

@media (max-width: 1024px) {
  .IntegrateTools_innerContainer__ooqmh {
    width: 95%;
  }
  .IntegrateTools_cardContainer__A7VB4 {
    display: flex;
    flex-wrap: wrap;
  }
  .IntegrateTools_card__MUDBW {
    max-width: 30%;
  }
}

@media (max-width: 640px) {
  .IntegrateTools_card__MUDBW {
    max-width: 45%;
    min-height: 6rem;
  }
}

@media (max-width: 480px) {
  .IntegrateTools_card__MUDBW {
    max-width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/ErpPage/IntegrateTools/IntegrateTools.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,qCAAqC;EACrC,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,uCAAuC;EACvC,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,aAAa;IACb,eAAe;EACjB;EACA;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,cAAc;IACd,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".innerContainer {\n  max-width: 130.6rem;\n  width: 90%;\n  margin: 6rem auto;\n}\n.cardContainer {\n  /* display: flex; */\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  column-gap: 5rem;\n  row-gap: 2rem;\n  flex-wrap: wrap;\n  justify-content: center;\n  /* max-width: 105.6rem; */\n}\n.card {\n  width: 100%;\n  box-shadow: 1px 4px 20px -2px #0000001a;\n  padding: 0rem 3rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  /* max-width: 19rem; */\n}\n.imgLogo {\n  max-width: 15rem;\n  max-height: 6rem;\n  /* width: 100%; */\n}\n\n@media (max-width: 1280px) {\n  .cardContainer {\n    column-gap: 2rem;\n  }\n}\n\n@media (max-width: 1024px) {\n  .innerContainer {\n    width: 95%;\n  }\n  .cardContainer {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  .card {\n    max-width: 30%;\n  }\n}\n\n@media (max-width: 640px) {\n  .card {\n    max-width: 45%;\n    min-height: 6rem;\n  }\n}\n\n@media (max-width: 480px) {\n  .card {\n    max-width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `IntegrateTools_innerContainer__ooqmh`,
	"cardContainer": `IntegrateTools_cardContainer__A7VB4`,
	"card": `IntegrateTools_card__MUDBW`,
	"imgLogo": `IntegrateTools_imgLogo__7Omln`
};
export default ___CSS_LOADER_EXPORT___;
