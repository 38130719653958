import React from "react";
import styles from "./CrmIntegrationsCard.module.css";
import TextHeading from "../../../../partials/TextHeading/TextHeading";
import img from "./../../../../../assets/img/CRM/CustomizeCard.png";
import { Link } from "react-router-dom";

import img1 from "../../../../../assets/img/Aiml/use-cases/img-1.png";
import img2 from "../../../../../assets/img/Aiml/use-cases/img-2.png";
import img3 from "../../../../../assets/img/Aiml/use-cases/img-3.png";
import img4 from "../../../../../assets/img/Aiml/use-cases/img-4.png";

const data = [
  {
    heading: "Cybersecurity",
    text: "AI/ML enhances cybersecurity by customizing threat detection to specific environments, upscaling defenses against sophisticated attacks, and deploying seamlessly across networks. These technologies analyze patterns to predict and mitigate risks, ensuring robust and adaptive protection for sensitive data.",
    img: img1,
  },
  {
    heading: "IT Operations",
    text: "In IT operations, AI/ML customizes system monitoring, upscales performance optimization, and deploys automated solutions with ease. By analyzing network traffic and server logs, these technologies predict and resolve issues proactively, ensuring smooth and efficient IT infrastructure management.",
    img: img2,
  },
  {
    heading: "Software Development",
    text: "AI/ML in software development customizes code suggestions and bug detection, upscales testing processes, and deploys continuous integration and delivery pipelines effortlessly. This accelerates development cycles, improves code quality, and enhances collaboration among development teams.",
    img: img3,
  },
  {
    heading: "Cloud Computing",
    text: "AI/ML optimizes cloud computing by customizing resource allocation, upscaling data processing capabilities, and deploying intelligent management solutions effortlessly. These technologies predict workload demands, optimize storage, and ensure efficient use of cloud resources, leading to cost-effective and scalable operations.",
    img: img4,
  },
];
const CrmIntegrationsCard: React.FC<{ innerContainerClasses?: string }> = ({
  innerContainerClasses,
}) => {
  return (
    <section>
      <div
        className={`${styles.innerContainer} ${innerContainerClasses || ""}`}
      >
        <TextHeading
          heading="Industry Use Cases"
          text="Our broader objective is to make quality tech available, accessible, and affordable to all. In doing so, we meet the needs of organizations of all sizes."
        />
        {data.map((item, i) => (
          <div
            className={`${styles.CardConainer}
              ${(i + 1) % 2 ? `` : `${styles.row_reverse}`}
            `}
          >
            <div className={styles.TextContainer}>
              <h3 className={styles.heading}>{item.heading}</h3>
              <h4 className="heading2 caption">{item.text}</h4>
              <Link to={"/contact"}>
                <button className={`btnPrimary ${styles.btn}`}>
                  FREE DEMO
                </button>
              </Link>
            </div>

            <img src={item.img} alt="" className={styles.img} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default CrmIntegrationsCard;
