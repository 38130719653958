import React from "react";
import Nav from "./Nav/Nav";
import Footer from "./Footer/Footer";

const Layout: React.FC<{ children: React.ReactNode | JSX.Element }> = ({
  children,
}) => {
  return (
    <>
      <Nav />
      <main className="pt-[9rem]">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
