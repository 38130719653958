const RIghtClickArrow: React.FC<{
  imgColor: any;
}> = ({ imgColor }) => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.65656 10.1747L1.18842 5.70654C0.91925 5.43738 0.91925 4.9798 1.18842 4.71063L2.15741 3.74164C2.42657 3.47247 2.85724 3.47247 3.1264 3.74164L6.16797 6.75629L12.6279 0.296326C12.8971 0.0271606 13.3278 0.0271606 13.5969 0.296326L14.5659 1.26532C14.8351 1.53448 14.8351 1.99207 14.5659 2.26123L6.65247 10.1747C6.3833 10.4438 5.92572 10.4438 5.65656 10.1747Z"
        fill={imgColor === "" ? "#1CB9C8" : imgColor}
      />
    </svg>
  );
};

export default RIghtClickArrow;
