// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextFlexCard_innerContainer__vMmkc {
  max-width: 150.9rem;
  width: 85%;
  margin: 5rem auto;
}

.TextFlexCard_CardConainer__5GyiN {
  justify-content: space-between;
  align-items: center;
  display: flex;
  column-gap: 3.4rem;
  margin-bottom: 7rem;
}
.TextFlexCard_border__W8Sgx {
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
}
.TextFlexCard_row_reverse__KFiqB {
  flex-direction: row-reverse;
}

.TextFlexCard_TextContainer__7nR92 {
  max-width: 56rem;
}
.TextFlexCard_heading__dZLS4 {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
  line-height: 3.9rem;
}

.TextFlexCard_btn__c9\\+yg {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  color: var(--color-primary);
  font-weight: 500;
  background-color: transparent;
  border: 2px solid #e1e1e1;
  letter-spacing: 1px;
}
.TextFlexCard_btn__c9\\+yg:hover {
  color: #232323;
}

.TextFlexCard_imgContainer__M8bE- {
  position: relative;
}

.TextFlexCard_img__zXHbS {
  max-width: 65.8rem;
}
/* .effectImg {
  position: absolute;
  bottom: -10%;
  right: 0%;
  max-width: 27rem;
  z-index: -1;
} */
`, "",{"version":3,"sources":["webpack://./src/components/partials/nssplPartials/TextFlexCard/TextFlexCard.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,WAAW;AACb;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;AACA;;;;;;GAMG","sourcesContent":[".innerContainer {\n  max-width: 150.9rem;\n  width: 85%;\n  margin: 5rem auto;\n}\n\n.CardConainer {\n  justify-content: space-between;\n  align-items: center;\n  display: flex;\n  column-gap: 3.4rem;\n  margin-bottom: 7rem;\n}\n.border {\n  border-bottom: 1px solid #e7e7e7;\n  width: 100%;\n}\n.row_reverse {\n  flex-direction: row-reverse;\n}\n\n.TextContainer {\n  max-width: 56rem;\n}\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n  line-height: 3.9rem;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  color: var(--color-primary);\n  font-weight: 500;\n  background-color: transparent;\n  border: 2px solid #e1e1e1;\n  letter-spacing: 1px;\n}\n.btn:hover {\n  color: #232323;\n}\n\n.imgContainer {\n  position: relative;\n}\n\n.img {\n  max-width: 65.8rem;\n}\n/* .effectImg {\n  position: absolute;\n  bottom: -10%;\n  right: 0%;\n  max-width: 27rem;\n  z-index: -1;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `TextFlexCard_innerContainer__vMmkc`,
	"CardConainer": `TextFlexCard_CardConainer__5GyiN`,
	"border": `TextFlexCard_border__W8Sgx`,
	"row_reverse": `TextFlexCard_row_reverse__KFiqB`,
	"TextContainer": `TextFlexCard_TextContainer__7nR92`,
	"heading": `TextFlexCard_heading__dZLS4`,
	"btn": `TextFlexCard_btn__c9+yg`,
	"imgContainer": `TextFlexCard_imgContainer__M8bE-`,
	"img": `TextFlexCard_img__zXHbS`
};
export default ___CSS_LOADER_EXPORT___;
