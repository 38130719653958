import React from "react";

const DataEngineeringIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 7.5C25 4.79 20.4212 2.5 15 2.5C9.57875 2.5 5 4.79 5 7.5V10C5 12.71 9.57875 15 15 15C20.4212 15 25 12.71 25 10V7.5ZM15 23.75C9.57875 23.75 5 21.46 5 18.75V22.5C5 25.21 9.57875 27.5 15 27.5C20.4212 27.5 25 25.21 25 22.5V18.75C25 21.46 20.4212 23.75 15 23.75Z"
        fill="#1BA9B7"
      />
      <path
        d="M25 12.5C25 15.21 20.4212 17.5 15 17.5C9.57875 17.5 5 15.21 5 12.5V16.25C5 18.96 9.57875 21.25 15 21.25C20.4212 21.25 25 18.96 25 16.25V12.5Z"
        fill="#1BA9B7"
      />
    </svg>
  );
};

export default DataEngineeringIcon;
