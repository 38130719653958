// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErpSystem_innerContainer__ITf09 {
  max-width: 150.9rem;
  width: 95%;
  margin: 6rem auto 5rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3.4rem;
}

.ErpSystem_TextContainer__7GmFw {
  max-width: 56rem;
}
.ErpSystem_heading__2Ku0i {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
  line-height: 3.9rem;
}

.ErpSystem_btn__syYsd {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  background-color: var(--color-primary);
  font-weight: 500;
  border: 2px solid #e1e1e1;
  letter-spacing: 1px;
  color: #ffffff;
}

.ErpSystem_img__IdOF3 {
  max-width: 55.8rem;
  padding-right: 1.5rem;
}

@media (max-width: 1280px) {
  .ErpSystem_innerContainer__ITf09 {
    column-gap: 5rem;
  }
  .ErpSystem_innerContainer__ITf09 > div,
  .ErpSystem_innerContainer__ITf09 > img {
    max-width: 50%;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .ErpSystem_innerContainer__ITf09 {
    flex-direction: column;
    row-gap: 3rem;
  }
  .ErpSystem_innerContainer__ITf09 > div {
    max-width: 100%;
  }
  .ErpSystem_innerContainer__ITf09 > img {
    max-width: 55rem;
  }
}
@media (max-width: 700px) {
  .ErpSystem_innerContainer__ITf09 {
    margin: 2rem auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/ErpPage/ERPSystem/ErpSystem.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,2BAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,sCAAsC;EACtC,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;;IAEE,cAAc;IACd,WAAW;EACb;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".innerContainer {\n  max-width: 150.9rem;\n  width: 95%;\n  margin: 6rem auto 5rem auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 3.4rem;\n}\n\n.TextContainer {\n  max-width: 56rem;\n}\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n  line-height: 3.9rem;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  background-color: var(--color-primary);\n  font-weight: 500;\n  border: 2px solid #e1e1e1;\n  letter-spacing: 1px;\n  color: #ffffff;\n}\n\n.img {\n  max-width: 55.8rem;\n  padding-right: 1.5rem;\n}\n\n@media (max-width: 1280px) {\n  .innerContainer {\n    column-gap: 5rem;\n  }\n  .innerContainer > div,\n  .innerContainer > img {\n    max-width: 50%;\n    width: 100%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .innerContainer {\n    flex-direction: column;\n    row-gap: 3rem;\n  }\n  .innerContainer > div {\n    max-width: 100%;\n  }\n  .innerContainer > img {\n    max-width: 55rem;\n  }\n}\n@media (max-width: 700px) {\n  .innerContainer {\n    margin: 2rem auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `ErpSystem_innerContainer__ITf09`,
	"TextContainer": `ErpSystem_TextContainer__7GmFw`,
	"heading": `ErpSystem_heading__2Ku0i`,
	"btn": `ErpSystem_btn__syYsd`,
	"img": `ErpSystem_img__IdOF3`
};
export default ___CSS_LOADER_EXPORT___;
