// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyTrueCard_innerContainer__wdLhN {
  max-width: 150.9rem;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  background-color: #fff2ea;
  padding: 7rem 3rem 3rem 3rem;
  border-radius: 4.7rem;
  margin-bottom: 5rem;
}

.MyTrueCard_TextContainer__FR8C5 {
  max-width: 47rem;
}
.MyTrueCard_FeaturedHeading__7mFnu {
  padding: 0.5rem 2rem;
  background-color: #ffff;
  color: #1cb9c8;
  border-radius: 3.1rem;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
  line-height: 3.9rem;
  max-width: 18rem;
  width: 100%;
}
.MyTrueCard_heading__US\\+WF {
  font-weight: 800;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
  line-height: 3.9rem;
}

.MyTrueCard_btn__Ug7I9 {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  color: #ffff;
  font-weight: 500;
  background-color: #1cb9c8;

  letter-spacing: 1px;
}

.MyTrueCard_img__5BwSH {
  max-width: 70.8rem;
  transform: translateX(120px);
}
`, "",{"version":3,"sources":["webpack://./src/components/app/NssplAvidianCrm/AvidianCRMPage/MyTrueCard/MyTrueCard.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,4BAA4B;EAC5B,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,cAAc;EACd,qBAAqB;EACrB,iBAAiB;EACjB,gBAAgB;EAChB,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;;EAEzB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,4BAA4B;AAC9B","sourcesContent":[".innerContainer {\n  max-width: 150.9rem;\n  width: 90%;\n  margin: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  column-gap: 2rem;\n  background-color: #fff2ea;\n  padding: 7rem 3rem 3rem 3rem;\n  border-radius: 4.7rem;\n  margin-bottom: 5rem;\n}\n\n.TextContainer {\n  max-width: 47rem;\n}\n.FeaturedHeading {\n  padding: 0.5rem 2rem;\n  background-color: #ffff;\n  color: #1cb9c8;\n  border-radius: 3.1rem;\n  font-size: 1.2rem;\n  font-weight: 600;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n  line-height: 3.9rem;\n  max-width: 18rem;\n  width: 100%;\n}\n.heading {\n  font-weight: 800;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n  line-height: 3.9rem;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  color: #ffff;\n  font-weight: 500;\n  background-color: #1cb9c8;\n\n  letter-spacing: 1px;\n}\n\n.img {\n  max-width: 70.8rem;\n  transform: translateX(120px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `MyTrueCard_innerContainer__wdLhN`,
	"TextContainer": `MyTrueCard_TextContainer__FR8C5`,
	"FeaturedHeading": `MyTrueCard_FeaturedHeading__7mFnu`,
	"heading": `MyTrueCard_heading__US+WF`,
	"btn": `MyTrueCard_btn__Ug7I9`,
	"img": `MyTrueCard_img__5BwSH`
};
export default ___CSS_LOADER_EXPORT___;
