// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BusinessProcessCard_innerContainer__vYsJ7 {
  max-width: 1509px;
  width: 85%;
  margin: auto;
  margin-top: 6rem;
}
.BusinessProcessCard_main__mP9Iy {
  display: flex;
  justify-content: space-between;
}
.BusinessProcessCard_ContainerWidth__fjonB {
  max-width: 605px;
}

.BusinessProcessCard_icon__U9JoX {
  display: flex;
  /* align-items: center; */
  gap: 10px;
  margin-bottom: 1.5rem;
}
.BusinessProcessCard_partnerHeding__fmRru {
  margin-bottom: 1rem;
  color: #232323;
  font-weight: 600;
}

.BusinessProcessCard_img__\\+Z2zR {
  max-width: 580px;
}

@media (max-width: 1400px) {
  .BusinessProcessCard_innerContainer__vYsJ7 {
    width: 95%;
    padding: 0;
  }
}

@media (max-width: 1280px) {
  .BusinessProcessCard_main__mP9Iy {
    column-gap: 5rem;
    align-items: center;
  }
  .BusinessProcessCard_main__mP9Iy > div,
  .BusinessProcessCard_main__mP9Iy > img {
    max-width: 50%;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .BusinessProcessCard_main__mP9Iy {
    flex-direction: column-reverse;
    row-gap: 3rem;
  }
  .BusinessProcessCard_main__mP9Iy > div {
    max-width: 100%;
  }
  .BusinessProcessCard_main__mP9Iy > img {
    max-width: 55rem;
  }
}
@media (max-width: 600px) {
  .BusinessProcessCard_innerContainer__vYsJ7 {
    margin-top: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/AboutPage/BusinessProcess/BusinessProcessCard.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,SAAS;EACT,qBAAqB;AACvB;AACA;EACE,mBAAmB;EAGnB,cAAc;EACd,gBAAgB;AAHlB;;AAMA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;IACV,UAAU;EACZ;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,mBAAmB;EACrB;EACA;;IAEE,cAAc;IACd,WAAW;EACb;AACF;;AAEA;EACE;IACE,8BAA8B;IAC9B,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".innerContainer {\n  max-width: 1509px;\n  width: 85%;\n  margin: auto;\n  margin-top: 6rem;\n}\n.main {\n  display: flex;\n  justify-content: space-between;\n}\n.ContainerWidth {\n  max-width: 605px;\n}\n\n.icon {\n  display: flex;\n  /* align-items: center; */\n  gap: 10px;\n  margin-bottom: 1.5rem;\n}\n.partnerHeding {\n  margin-bottom: 1rem;\n}\n.partnerHeding {\n  color: #232323;\n  font-weight: 600;\n}\n\n.img {\n  max-width: 580px;\n}\n\n@media (max-width: 1400px) {\n  .innerContainer {\n    width: 95%;\n    padding: 0;\n  }\n}\n\n@media (max-width: 1280px) {\n  .main {\n    column-gap: 5rem;\n    align-items: center;\n  }\n  .main > div,\n  .main > img {\n    max-width: 50%;\n    width: 100%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .main {\n    flex-direction: column-reverse;\n    row-gap: 3rem;\n  }\n  .main > div {\n    max-width: 100%;\n  }\n  .main > img {\n    max-width: 55rem;\n  }\n}\n@media (max-width: 600px) {\n  .innerContainer {\n    margin-top: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `BusinessProcessCard_innerContainer__vYsJ7`,
	"main": `BusinessProcessCard_main__mP9Iy`,
	"ContainerWidth": `BusinessProcessCard_ContainerWidth__fjonB`,
	"icon": `BusinessProcessCard_icon__U9JoX`,
	"partnerHeding": `BusinessProcessCard_partnerHeding__fmRru`,
	"img": `BusinessProcessCard_img__+Z2zR`
};
export default ___CSS_LOADER_EXPORT___;
