import React from "react";

const ChooseVueporteqIcon3 = () => {
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1749 16.3481C21.754 15.9337 16.6539 13.961 15.7285 13.5888C14.8079 13.2232 14.4407 12.21 14.4407 12.21C14.4407 12.21 14.0263 12.4391 14.0263 11.7956C14.0263 11.1513 14.4407 12.21 14.8551 9.72539C14.8551 9.72539 16.0048 9.40283 15.7764 6.73539H15.5002C15.5002 6.73539 16.1908 3.88351 15.5002 2.91826C14.8071 1.95301 14.5358 1.30951 13.0139 0.848013C11.4946 0.387325 12.0471 0.479138 10.9437 0.526263C9.8387 0.572575 8.91895 1.17058 8.91895 1.49151C8.91895 1.49151 8.22832 1.53783 7.9537 1.81408C7.67745 2.09033 7.21757 3.37733 7.21757 3.69908C7.21757 4.02083 7.44751 6.18533 7.67745 6.64358L7.40363 6.73295C7.1737 9.4012 8.32338 9.72458 8.32338 9.72458C8.73776 12.2092 9.15213 11.1505 9.15213 11.7948C9.15213 12.4383 8.73776 12.2092 8.73776 12.2092C8.73776 12.2092 8.36969 13.2216 7.44994 13.588C6.53019 13.9561 1.42444 15.9337 1.00926 16.3473C0.594882 16.7698 0.641194 18.7003 0.641194 18.7003H10.4204L11.1338 15.889L10.5001 15.2553L11.5913 14.1625L12.6824 15.2545L12.0487 15.8882L12.7621 18.6995H22.5413C22.5413 18.6995 22.5925 16.7673 22.1733 16.3456L22.1749 16.3481Z"
        fill="white"
      />
    </svg>
  );
};

export default ChooseVueporteqIcon3;
