import React from "react";
import styles from "./CaseStudyPageComponet.module.css";
import RIghtClickArrow from "../../../icons/RIghtClickArrow";
import Research from "../../../icons/CaseStudy/Research";
import CountryIcon from "../../../icons/CountryIcon";
import Technology from "../../../icons/Technology";
import CompetitorsAnalysis from "../../../icons/CaseStudy/CompetitorsAnalysis";
import ConceptIdeation from "../../../icons/CaseStudy/ConceptIdeation";
import Experimentation from "../../../icons/CaseStudy/Experimentation";
import VisualDesign from "../../../icons/CaseStudy/VisualDesign";
import Development from "../../../icons/CaseStudy/Development";
import Testing from "../../../icons/CaseStudy/Testing";
import ProjectIcon from "../../../icons/ProjectIcon";

interface color {
  mainHeading: string;
  manHeadingLine: string;
  heading: string;
  iconBg: string;
  // iconColor: string;
  imgColor: string;
}

export const CaseStudyFlexComponet: React.FC<{
  heading: string;
  heading1: string;
  text: string;
  color: color;
  iconLog?: any;
}> = ({ heading, heading1, text, color, iconLog }) => {
  return (
    <div className={styles.Project_Overview}>
      <div
        className={`${styles.FiservHeading} headingWhite`}
        style={{ background: color.mainHeading }}
      >
        {heading}
      </div>
      <div className={`${styles.headingBottom} heading fontMontserrat body3`}>
        {heading1}
      </div>
      <div
        className={styles.border}
        style={{ borderColor: color.manHeadingLine }}
      ></div>
      <p className={`${styles.pay} heading2 caption`}>{text}</p>
      <div className={styles.cardContainer}>
        <div className={styles.cards}>
          <div
            className={styles.bgblueColor}
            style={{ background: color.iconBg }}
          >
            <ProjectIcon imgColor1={color.imgColor} />
          </div>
          <div>
            <p
              className={styles.heading}
              style={{ color: color.heading, whiteSpace: "nowrap" }}
            >
              Project Duration
            </p>
            <p className={`${styles.pay} heading2`}>9 Years ( Ongoing )</p>
          </div>
        </div>
        <div className={styles.cards}>
          <div
            className={styles.bgblueColor}
            style={{ background: color.iconBg }}
          >
            <Technology imgColor={color.imgColor} />
          </div>
          <div>
            <p className={styles.heading} style={{ color: color.heading }}>
              Technology Used
            </p>
          </div>
        </div>
        <div className={styles.cards}>
          <div
            className={styles.bgblueColor}
            style={{ background: color.iconBg }}
          >
            <CountryIcon imgColor={color.imgColor} />
          </div>
          <div>
            <p className={styles.heading} style={{ color: color.heading }}>
              Country
            </p>
            <p className={`${styles.pay} heading2`}>USA</p>
          </div>
        </div>
        <div className={styles.cards}>{iconLog?.map((icon: any) => icon)}</div>
      </div>
    </div>
  );
};

interface ImgTextDataItem {
  problem: string;
  problemHeading: string;
  img: string;
  circleBgColor?: string;
  heading: string;
  IconColor: string;
  list: string[];
}

interface Data1 {
  img: string;
  heading?: string;
  pay: string;
  BgColor: string;
}

interface color1 {
  problem: string;
  circle: string;
  img?: string;
  ScreenshotsImg: string;
  processIconColor?: string;
}

const CaseStudyPageComponet: React.FC<{
  ImgTextData: ImgTextDataItem[];
  Data1: Data1[];
  Data: Data1[];
  color: color1;
  imgBlue: string;
}> = ({ ImgTextData, Data1, Data, color, imgBlue }) => {
  const iconData = [
    {
      name: "Research",
      icon: <Research processIconColor={color.processIconColor} />,
    },
    {
      name: "Competitors Analysis",
      icon: <CompetitorsAnalysis processIconColor={color.processIconColor} />,
    },
    {
      name: "Concept Ideation",
      icon: <ConceptIdeation processIconColor={color.processIconColor} />,
    },
    {
      name: "Experimentation and optimization",
      icon: <Experimentation processIconColor={color.processIconColor} />,
    },
    {
      name: "Visual Design",
      icon: <VisualDesign processIconColor={color.processIconColor} />,
    },
    {
      name: "Development",
      icon: <Development processIconColor={color.processIconColor} />,
    },
    {
      name: "Testing",
      icon: <Testing processIconColor={color.processIconColor} />,
    },
  ];
  return (
    <>
      <div className={`${styles.InnerContainer} `}>
        {ImgTextData.map((item, i) => (
          <div
            className={`${styles.mainCard} ${(i + 1) % 2 ? "" : styles.order2}`}
          >
            <div className={styles.imgWidth}>
              <div style={{ flex: "1" }}>
                <div className={`${styles.sideProblem} heading2 body1`}>
                  <div>{item.problem}</div>
                  <div
                    className={styles.problem_line}
                    style={{ background: color.problem }}
                  ></div>
                  <div>{item.problemHeading}</div>
                </div>
                <div className={styles.imgClass}>
                  <img style={{ margin: "auto" }} src={item.img} alt="" />
                </div>
              </div>
            </div>

            <div className={styles.ulList}>
              <div>
                <div
                  className={styles.circleProblem}
                  style={{ background: color.circle }}
                >
                  <h2
                    className={`${styles.numbeHeading} heading fontMontserrat`}
                  >
                    {item.heading}
                  </h2>
                </div>
                {item.list.map((text) => (
                  <p className={`${styles.ulListWidth} heading2 caption`}>
                    <RIghtClickArrow imgColor={item.IconColor} /> {text}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.CaseStudyBgColor}>
        <img src={imgBlue} alt="" />

        <div className={styles.InnerContainer}>
          <div className={`${styles.sideProblem} heading2 body1`}>
            <div>03</div>
            <div
              className={styles.problem_line}
              style={{ background: color.problem }}
            ></div>
            <div>Process</div>
          </div>
          <div className={styles.CaseStudyFlex}>
            <div className={styles.MaincircleProblem}>
              <div
                className={styles.circleProblem}
                style={{ background: color.circle }}
              >
                <h2 className={`${styles.numbeHeading} heading fontMontserrat`}>
                  Project Workflow
                </h2>
              </div>
            </div>
            <div className={styles.bgWhiteContainer}>
              {iconData.map((item) => (
                <div>
                  <div className={styles.bgWhiteColor}>{item.icon}</div>
                  <p className="heading marginTop body2">{item.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${styles.InnerContainer} ${styles.mainClass}  ${styles.order2}`}
      >
        <div
          className={styles.circleProblem}
          style={{ marginBottom: "15px", background: color.circle }}
        >
          <h2 className={`${styles.numbeHeading} heading fontMontserrat`}>
            Results
          </h2>
        </div>

        <div className={`${styles.sideProblem} heading2 body1`}>
          <div>04</div>
          <div
            className={styles.problem_line}
            style={{ background: color.problem }}
          ></div>
          <div>Result</div>
        </div>

        {/* <p style={{ paddingLeft: "5px" }}>
          Build a custom software tailored to your business needs to
        </p> */}
        <div className={styles.mainContainer}>
          <div className={`${styles.cardContainer1} ${styles.ORDER2}`}>
            {Data.map((item, i) => (
              <div className={styles.cards} style={{ alignItems: "center" }}>
                <div
                  className={styles.bgblueColor}
                  style={{ background: item.BgColor }}
                >
                  <img src={item.img} alt="" />
                </div>
                <div>
                  <p
                    className={`${styles.pay} heading2`}
                    style={{ marginBottom: "0" }}
                  >
                    {item.pay}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <img
            src={color.img}
            alt=""
            className={`${styles.img} ${styles.ORDER1}`}
          />
          <div className={`${styles.cardContainer1} ${styles.ORDER3}`}>
            {Data1.map((item, i) => (
              <div className={styles.cards}>
                <div
                  className={styles.bgblueColor}
                  style={{ background: item.BgColor }}
                >
                  <img src={item.img} alt="" />
                </div>
                <div>
                  <p className={`${styles.pay} heading2`}>{item.pay}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={`${styles.InnerContainer} ${styles.lastContainer}`}>
        <div className={`${styles.sideProblem} heading2 body1`}>
          <div>05</div>
          <div
            className={styles.problem_line}
            style={{ background: color.problem }}
          ></div>
          <div>Screenshots</div>
        </div>

        <div className={styles.lastFlex}>
          <div className={styles.MaincircleProblem}>
            <div
              className={styles.circleProblem}
              style={{ background: color.circle }}
            >
              <h2 className={`${styles.numbeHeading} heading fontMontserrat`}>
                Screenshots
              </h2>
            </div>
          </div>

          <img src={color.ScreenshotsImg} alt="" />
        </div>
      </div>
    </>
  );
};

export default CaseStudyPageComponet;
