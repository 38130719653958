import React from "react";

const ReactIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 30.0203C28.2437 30.0203 30.0625 28.2014 30.0625 25.9578C30.0625 23.7141 28.2437 21.8953 26 21.8953C23.7563 21.8953 21.9375 23.7141 21.9375 25.9578C21.9375 28.2014 23.7563 30.0203 26 30.0203Z"
        fill="#00D8FF"
      />
      <path
        d="M26 35.2723C21.1171 35.3904 16.2464 34.7322 11.57 33.3223C9.40976 32.6375 7.39494 31.5588 5.62738 30.1406C4.95289 29.6561 4.39119 29.0314 3.98083 28.3094C3.57047 27.5874 3.32116 26.7852 3.25 25.9578C3.25 23.2717 6.201 20.6392 11.1442 18.9167C15.9457 17.3904 20.9624 16.6507 26 16.7262C30.9704 16.6567 35.9199 17.3831 40.6607 18.8777C42.7412 19.5409 44.6877 20.5676 46.41 21.9099C47.073 22.3718 47.6262 22.9739 48.0306 23.6734C48.435 24.3729 48.6806 25.1528 48.75 25.9578C48.75 28.7496 45.4513 31.5787 40.1375 33.3369C35.5516 34.7009 30.7837 35.3536 26 35.2723ZM26 18.6697C21.1804 18.6053 16.3813 19.3085 11.7829 20.7529C7.23287 22.3406 5.1935 24.5116 5.1935 25.9529C5.1935 27.4609 7.38562 29.8318 12.1989 31.4779C16.6726 32.82 21.3307 33.4447 26 33.3288C30.5767 33.4107 35.1389 32.7914 39.5281 31.4926C44.525 29.8367 46.8 27.4626 46.8 25.9578C46.718 25.4414 46.5298 24.9477 46.2473 24.5077C45.9648 24.0677 45.5942 23.6911 45.1588 23.4017C43.6178 22.2108 41.879 21.301 40.0221 20.7139C35.4868 19.29 30.7531 18.5999 26 18.6697Z"
        fill="#00D8FF"
      />
      <path
        d="M16.7694 46.2198C16.0113 46.2372 15.2622 46.0532 14.5984 45.6868C12.2714 44.3445 11.4654 40.4738 12.442 35.3306C13.5188 30.4087 15.3845 25.6932 17.967 21.367C20.3894 17.0268 23.4907 13.1021 27.1531 9.74177C28.767 8.27067 30.6288 7.09715 32.6521 6.27565C33.3839 5.93267 34.1823 5.75488 34.9905 5.75488C35.7987 5.75488 36.597 5.93267 37.3289 6.27565C39.7485 7.66989 40.5496 11.9404 39.4202 17.4215C38.3102 22.0764 36.4929 26.5336 34.0317 30.6376C31.6946 34.9266 28.691 38.8171 25.1332 42.1638C23.4608 43.6925 21.5198 44.8986 19.4084 45.7209C18.5631 46.0346 17.6709 46.2033 16.7694 46.2198ZM19.6505 22.3323C17.1871 26.4749 15.3989 30.9833 14.353 35.6881C13.4544 40.425 14.3172 43.2736 15.5717 43.9984C16.8717 44.7508 20.0275 44.0374 23.8592 40.6899C27.2555 37.4837 30.1224 33.7596 32.3531 29.6561C34.7109 25.7326 36.4541 21.4712 37.5222 17.0201C38.585 11.8608 37.6669 8.70177 36.3636 7.95102C35.8752 7.76386 35.3532 7.68008 34.8307 7.70498C34.3082 7.72987 33.7966 7.8629 33.3281 8.09564C31.5271 8.83883 29.8713 9.89392 28.4369 11.2124C24.9377 14.4312 21.9754 18.1884 19.6619 22.342L19.6505 22.3323Z"
        fill="#00D8FF"
      />
      <path
        d="M35.2256 46.241C33.0237 46.241 30.2271 44.9085 27.3151 42.4043C23.5884 39.0134 20.4348 35.0421 17.9762 30.6442C15.4261 26.3775 13.5752 21.7304 12.4934 16.8788C12.0243 14.7444 11.9374 12.5437 12.2367 10.3788C12.3059 9.57578 12.5504 8.79767 12.953 8.09939C13.3557 7.40111 13.9066 6.79967 14.5669 6.33747C16.9833 4.93834 21.0832 6.37647 25.2676 10.0912C28.746 13.3773 31.6999 17.1774 34.0263 21.359C36.5748 25.526 38.4452 30.0715 39.5676 34.8253C40.0569 37.0381 40.1324 39.3223 39.7902 41.5626C39.7088 42.3888 39.4497 43.1875 39.0306 43.9042C38.6116 44.6208 38.0425 45.2384 37.3624 45.7145C36.7094 46.0764 35.9719 46.2581 35.2256 46.241ZM19.6581 29.666C22.0166 33.8689 25.0292 37.6697 28.5826 40.9256C32.2372 44.07 35.1362 44.746 36.3826 44.0131C37.6826 43.2575 38.6413 40.1732 37.6533 35.1828C36.5709 30.6441 34.7769 26.3053 32.3379 22.3275C30.1179 18.3253 27.2984 14.6865 23.9773 11.5375C20.0383 8.04047 16.8436 7.25884 15.5419 8.01284C15.1362 8.34252 14.8033 8.75274 14.5642 9.21759C14.325 9.68245 14.1849 10.1918 14.1526 10.7136C13.8942 12.6442 13.9784 14.6053 14.4012 16.5067C15.4417 21.1463 17.2169 25.5898 19.6597 29.6692L19.6581 29.666Z"
        fill="#00D8FF"
      />
    </svg>
  );
};

export default ReactIcon;
