// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Premon_section__Ly7zN {
  position: relative;
}
.Premon_InnerContainer__5iMfK {
  max-width: 1400px;
  margin: auto;
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
}
.Premon_imgContainer__DL7E8 {
  max-width: 90rem;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.Premon_imgContainer__DL7E8 img {
  width: 100%;
}
@media (max-width: 1450px) {
  .Premon_InnerContainer__5iMfK {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .Premon_imgContainer__DL7E8 img {
    max-width: 80rem;
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .Premon_imgContainer__DL7E8 img {
    max-width: 70rem;
  }
}
@media (max-width: 1024px) {
  .Premon_imgContainer__DL7E8 img {
    max-width: 60rem;
  }
}
@media (max-width: 768px) {
  .Premon_InnerContainer__5iMfK {
    flex-direction: column-reverse;
  }
  .Premon_imgContainer__DL7E8 {
    top: 20px;
    right: 76px;
  }
}
@media (max-width: 580px) {
  .Premon_imgContainer__DL7E8 {
    top: 90px;
    right: 76px;
  }
}
@media (max-width: 450px) {
  .Premon_imgContainer__DL7E8 {
    top: 100px;
    right: 76px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CaseStudyPage/Premon/Premon.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,SAAS;IACT,WAAW;EACb;AACF;AACA;EACE;IACE,SAAS;IACT,WAAW;EACb;AACF;AACA;EACE;IACE,UAAU;IACV,WAAW;EACb;AACF","sourcesContent":[".section {\n  position: relative;\n}\n.InnerContainer {\n  max-width: 1400px;\n  margin: auto;\n  margin-top: 5rem;\n  display: flex;\n  justify-content: space-between;\n}\n.imgContainer {\n  max-width: 90rem;\n  margin: auto;\n  position: absolute;\n  top: 0;\n  right: 0;\n  z-index: -1;\n}\n.imgContainer img {\n  width: 100%;\n}\n@media (max-width: 1450px) {\n  .InnerContainer {\n    margin-left: 3rem;\n    margin-right: 3rem;\n  }\n  .imgContainer img {\n    max-width: 80rem;\n    width: 100%;\n  }\n}\n@media (max-width: 1200px) {\n  .imgContainer img {\n    max-width: 70rem;\n  }\n}\n@media (max-width: 1024px) {\n  .imgContainer img {\n    max-width: 60rem;\n  }\n}\n@media (max-width: 768px) {\n  .InnerContainer {\n    flex-direction: column-reverse;\n  }\n  .imgContainer {\n    top: 20px;\n    right: 76px;\n  }\n}\n@media (max-width: 580px) {\n  .imgContainer {\n    top: 90px;\n    right: 76px;\n  }\n}\n@media (max-width: 450px) {\n  .imgContainer {\n    top: 100px;\n    right: 76px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Premon_section__Ly7zN`,
	"InnerContainer": `Premon_InnerContainer__5iMfK`,
	"imgContainer": `Premon_imgContainer__DL7E8`
};
export default ___CSS_LOADER_EXPORT___;
