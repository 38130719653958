import React from "react";

const AiConsultingIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7124 26.1125C22.9424 25.7663 27.1074 21.5413 27.4499 16.2375C27.5162 15.2 27.5162 14.125 27.4499 13.0875C27.1074 7.78504 22.9424 3.56254 17.7124 3.21379C15.906 3.09475 14.0938 3.09475 12.2874 3.21379C7.05741 3.56129 2.89241 7.78504 2.54991 13.0888C2.48369 14.1377 2.48369 15.1898 2.54991 16.2388C2.67491 18.17 3.52866 19.9588 4.53491 21.4688C5.11866 22.525 4.73366 23.8438 4.12491 24.9975C3.68741 25.8288 3.46741 26.2438 3.64366 26.5438C3.81866 26.8438 4.21241 26.8538 4.99866 26.8725C6.55491 26.91 7.60366 26.47 8.43616 25.8563C8.90741 25.5075 9.14366 25.3338 9.30616 25.3138C9.46866 25.2938 9.78991 25.4263 10.4299 25.6888C11.0049 25.9263 11.6737 26.0725 12.2862 26.1138C14.0674 26.2313 15.9287 26.2313 17.7137 26.1138"
        stroke="#1BA9B7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.1088 8.75125V11.225M8.75 13.1263H11.315M18.7788 13.1263H21.3438M18.7788 16.8438H21.3438M8.75 16.8438H11.315M13.1088 18.775V21.25M16.89 18.775V21.25M16.8788 8.75V11.225M12.5663 18.7113H17.53C17.8615 18.7113 18.1795 18.5796 18.4139 18.3451C18.6483 18.1107 18.78 17.7928 18.78 17.4613V12.475C18.78 12.1435 18.6483 11.8255 18.4139 11.5911C18.1795 11.3567 17.8615 11.225 17.53 11.225H12.5663C12.2347 11.225 11.9168 11.3567 11.6824 11.5911C11.4479 11.8255 11.3163 12.1435 11.3163 12.475V17.4613C11.3163 17.7928 11.4479 18.1107 11.6824 18.3451C11.9168 18.5796 12.2347 18.7113 12.5663 18.7113Z"
        stroke="#1BA9B7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AiConsultingIcon;
