import React from "react";
import styles from "./MtcAvidianCrm.module.css";
import img from "./../../../../../assets/img/mtc/MtcAvidianCrm.png";

const MtcAvidianCrm = () => {
  return (
    <section className={styles.sectionBgColor}>
      <div className={styles.innerContainer}>
        <div className={styles.TextContainer}>
          <p className={styles.FeaturedHeading}>FEATURED PRODUCT</p>
          <h3 className={styles.heading}>Avidian CRM</h3>
          <h4 className="heading2 caption">
            Prophet CRM is an easy-to-use, fast-to-implement CRM and contact
            manager embedded in Microsoft Outlook. It de-clutters and expedites
            your workflow with intuitive features.
          </h4>
          <button className={`${styles.btn}`}>VIEW PRODUCT</button>
        </div>

        <img src={img} alt="" className={styles.img} />
      </div>
    </section>
  );
};

export default MtcAvidianCrm;
