// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CrmIntegrationsCard_innerContainer__oEKEh {
  max-width: 150.9rem;
  width: 95%;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 6rem;
  /* / margin: 5rem 0px 6rem auto; / */
}

.CrmIntegrationsCard_CardConainer__J6nIo {
  justify-content: space-between;
  align-items: center;
  display: flex;
  column-gap: 3.4rem;
}
.CrmIntegrationsCard_row_reverse__iF92J {
  flex-direction: row-reverse;
}

.CrmIntegrationsCard_TextContainer__0hpRC {
  max-width: 56rem;
}
.CrmIntegrationsCard_heading__IE5OQ {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
  line-height: 3.9rem;
}

.CrmIntegrationsCard_btn__mi1D\\+ {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  color: var(--color-primary);
  font-weight: 500;
  background-color: transparent;
  border: 2px solid #e1e1e1;
  letter-spacing: 1px;
}
.CrmIntegrationsCard_btn__mi1D\\+:hover {
  color: #232323;
}

.CrmIntegrationsCard_img__l-1Tu {
  max-width: 50.8rem;
  padding-right: 1.5rem;
}

@media (max-width: 1024px) {
  .CrmIntegrationsCard_CardConainer__J6nIo {
    flex-direction: column-reverse;
    row-gap: 2rem;
  }
  .CrmIntegrationsCard_CardConainer__J6nIo > div {
    max-width: 100%;
  }
  .CrmIntegrationsCard_CardConainer__J6nIo > img {
    max-width: 60rem;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/AI/MLPage/CrmIntegrationsCard/CrmIntegrationsCard.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,2BAA2B;EAC3B,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE;IACE,8BAA8B;IAC9B,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;IAChB,WAAW;EACb;AACF","sourcesContent":[".innerContainer {\n  max-width: 150.9rem;\n  width: 95%;\n  margin: auto;\n  margin-top: 5rem;\n  margin-bottom: 6rem;\n  /* / margin: 5rem 0px 6rem auto; / */\n}\n\n.CardConainer {\n  justify-content: space-between;\n  align-items: center;\n  display: flex;\n  column-gap: 3.4rem;\n}\n.row_reverse {\n  flex-direction: row-reverse;\n}\n\n.TextContainer {\n  max-width: 56rem;\n}\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n  line-height: 3.9rem;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  color: var(--color-primary);\n  font-weight: 500;\n  background-color: transparent;\n  border: 2px solid #e1e1e1;\n  letter-spacing: 1px;\n}\n.btn:hover {\n  color: #232323;\n}\n\n.img {\n  max-width: 50.8rem;\n  padding-right: 1.5rem;\n}\n\n@media (max-width: 1024px) {\n  .CardConainer {\n    flex-direction: column-reverse;\n    row-gap: 2rem;\n  }\n  .CardConainer > div {\n    max-width: 100%;\n  }\n  .CardConainer > img {\n    max-width: 60rem;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `CrmIntegrationsCard_innerContainer__oEKEh`,
	"CardConainer": `CrmIntegrationsCard_CardConainer__J6nIo`,
	"row_reverse": `CrmIntegrationsCard_row_reverse__iF92J`,
	"TextContainer": `CrmIntegrationsCard_TextContainer__0hpRC`,
	"heading": `CrmIntegrationsCard_heading__IE5OQ`,
	"btn": `CrmIntegrationsCard_btn__mi1D+`,
	"img": `CrmIntegrationsCard_img__l-1Tu`
};
export default ___CSS_LOADER_EXPORT___;
