import React from "react";

const CaseIcon1 = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_997_2021)">
        <path
          d="M11.5577 0H40.3609C41.7624 0 43.1234 0.223438 44.4437 0.690625C46.3531 1.38125 48.0796 2.6 49.3796 4.18438C50.6999 5.76875 51.5734 7.71875 51.8781 9.75C51.9999 10.5016 52.0203 11.2531 52.0203 12.0047V40.0969C52.0203 40.9906 51.9796 41.9047 51.7968 42.7781C51.3906 44.7891 50.4359 46.6781 49.0546 48.2016C47.6937 49.725 45.9062 50.8625 43.9765 51.4719C42.7984 51.8375 41.5796 52 40.3406 52C39.7921 52 11.4765 52 11.0499 51.9797C8.97805 51.8781 6.92649 51.2078 5.19993 50.05C3.51399 48.9125 2.13274 47.3281 1.23899 45.5C0.467112 43.9359 0.0811744 42.1891 0.0811744 40.4422V11.5578C0.0405494 9.83125 0.406175 8.125 1.15774 6.58125C2.03118 4.75313 3.39211 3.14844 5.07805 2.01094C6.80461 0.832812 8.83587 0.142188 10.9077 0.040625C11.1109 0 11.3343 0 11.5577 0Z"
          fill="url(#paint0_linear_997_2021)"
        />
        <path
          d="M43.875 42.5344C43.6922 42.25 43.489 41.9656 43.2656 41.7016C42.7578 41.0922 42.1687 40.5641 41.5187 40.1172C40.7062 39.5688 39.7515 39.2234 38.7766 39.1828C38.0859 39.1422 37.3953 39.2641 36.7453 39.5078C36.0953 39.7313 35.4656 40.0563 34.8562 40.3813C34.1453 40.7469 33.4344 41.1125 32.6828 41.4172C31.7891 41.7828 30.8547 42.0672 29.9 42.2703C28.7016 42.4938 27.4828 42.575 26.2844 42.5547C24.1109 42.5141 21.9375 42.1891 19.8656 41.5797C18.0375 41.0313 16.2906 40.2797 14.6453 39.325C13.2031 38.4922 11.8625 37.5375 10.6031 36.4609C9.56718 35.5672 8.6125 34.6125 7.71875 33.5969C7.10937 32.8859 6.52031 32.1344 5.97187 31.3625C5.74844 31.0578 5.54531 30.7328 5.3625 30.4078L0 24.6188V11.5172C0 5.15938 5.13906 0 11.4969 0H21.7547L29.3516 7.71875C46.4953 19.3781 40.95 32.2359 40.95 32.2359C40.95 32.2359 45.825 37.7203 43.875 42.5344Z"
          fill="url(#paint1_linear_997_2021)"
        />
        <path
          d="M29.393 7.71875C46.5367 19.3781 40.9914 32.2359 40.9914 32.2359C40.9914 32.2359 45.8664 37.7406 43.8961 42.5547C43.8961 42.5547 41.8852 39.1828 38.5133 39.1828C35.2633 39.1828 33.3539 42.5547 26.8133 42.5547C12.2492 42.5547 5.36328 30.3875 5.36328 30.3875C18.4852 39.0203 27.443 32.9062 27.443 32.9062C21.532 29.4734 8.9586 13.0609 8.9586 13.0609C19.907 22.3844 24.6399 24.8422 24.6399 24.8422C21.8164 22.5063 13.8945 11.0906 13.8945 11.0906C20.232 17.5094 32.8258 26.4672 32.8258 26.4672C36.4008 16.5547 29.393 7.71875 29.393 7.71875Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_997_2021"
          x1="30.934"
          y1="-1.85555"
          x2="21.1095"
          y2="53.8618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAAE42" />
          <stop offset="1" stop-color="#EF3E31" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_997_2021"
          x1="26.5306"
          y1="0.840125"
          x2="19.3401"
          y2="41.6189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E39F3A" />
          <stop offset="1" stop-color="#D33929" />
        </linearGradient>
        <clipPath id="clip0_997_2021">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CaseIcon1;
