import React from "react";
import TextHeading from "../../../partials/TextHeading/TextHeading";
import styles from "./IntegrateTools.module.css";
import Slack from "./../../../../assets/img/ERP/slackLogo.png";
import google from "./../../../../assets/img/ERP/logos_google.png";
import linkedin from "./../../../../assets/img/ERP/logos_linkedin.png";
import trello from "./../../../../assets/img/ERP/trello.png";
import outlook from "./../../../../assets/img/ERP/outlook.png";

const Logo = [
  {
    img: Slack,
  },
  {
    img: google,
  },
  {
    img: trello,
  },
  {
    img: linkedin,
  },
  {
    img: outlook,
  },

  // {
  //   img: Slack,
  // },
  // {
  //   img: trello,
  // },
  // {
  //   img: google,
  // },
  // {
  //   img: trello,
  // },
  // {
  //   img: linkedin,
  // },
  // {
  //   img: Slack,
  // },
];

const IntegrateTools = () => {
  return (
    <div className={styles.innerContainer}>
      <TextHeading
        heading="Integrate the tools you already use"
        text="Vueporteq IT Solutions has a host of built-in integrations that help your business take-off faster."
      />

      <div className={styles.cardContainer}>
        {Logo.map((item) => (
          <div className={styles.card}>
            <img src={item.img} alt="" className={styles.imgLogo} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrateTools;
