// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CrmOutlook_section__zo0pF {
  margin-top: 9.9rem;
  margin-bottom: 9.2rem;
}

.CrmOutlook_innerContainer__XjKHn {
  max-width: 125rem;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  column-gap: 10rem;
}

.CrmOutlook_imageContainer__L0jMD {
  max-width: 50.6rem;
  width: 50%;
}
.CrmOutlook_imageContainer__L0jMD > img {
  width: 100%;
}

.CrmOutlook_textContainer__OyhlD {
  max-width: 61.6rem;
  width: 50%;
}

.CrmOutlook_heading__LyMcH {
  font-weight: 600;
  margin-bottom: 2rem;
}

.CrmOutlook_aboutDescriptionText__qAsuq {
  color: var(--gray-666);
  line-height: 2.4rem;
}
.CrmOutlook_aboutDescription__hyhrr {
  margin-bottom: 2.2rem;
}

.CrmOutlook_pointsContainer__BFldS {
  display: flex;
  flex-direction: column;
  row-gap: 2.6rem;
  margin-bottom: 4rem;
}

.CrmOutlook_pointItem__IJcg6 {
  display: flex;
  column-gap: 1.8rem;
}

.CrmOutlook_pointItemHeading__1d3An {
  font-weight: 600;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/NssplAvidianCrm/AvidianCRMPage/CrmOutlook/CrmOutlook.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".section {\n  margin-top: 9.9rem;\n  margin-bottom: 9.2rem;\n}\n\n.innerContainer {\n  max-width: 125rem;\n  width: 90%;\n  margin: auto;\n  display: flex;\n  justify-content: space-between;\n  column-gap: 10rem;\n}\n\n.imageContainer {\n  max-width: 50.6rem;\n  width: 50%;\n}\n.imageContainer > img {\n  width: 100%;\n}\n\n.textContainer {\n  max-width: 61.6rem;\n  width: 50%;\n}\n\n.heading {\n  font-weight: 600;\n  margin-bottom: 2rem;\n}\n\n.aboutDescriptionText {\n  color: var(--gray-666);\n  line-height: 2.4rem;\n}\n.aboutDescription {\n  margin-bottom: 2.2rem;\n}\n\n.pointsContainer {\n  display: flex;\n  flex-direction: column;\n  row-gap: 2.6rem;\n  margin-bottom: 4rem;\n}\n\n.pointItem {\n  display: flex;\n  column-gap: 1.8rem;\n}\n\n.pointItemHeading {\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `CrmOutlook_section__zo0pF`,
	"innerContainer": `CrmOutlook_innerContainer__XjKHn`,
	"imageContainer": `CrmOutlook_imageContainer__L0jMD`,
	"textContainer": `CrmOutlook_textContainer__OyhlD`,
	"heading": `CrmOutlook_heading__LyMcH`,
	"aboutDescriptionText": `CrmOutlook_aboutDescriptionText__qAsuq`,
	"aboutDescription": `CrmOutlook_aboutDescription__hyhrr`,
	"pointsContainer": `CrmOutlook_pointsContainer__BFldS`,
	"pointItem": `CrmOutlook_pointItem__IJcg6`,
	"pointItemHeading": `CrmOutlook_pointItemHeading__1d3An`
};
export default ___CSS_LOADER_EXPORT___;
