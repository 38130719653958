// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SmarterDecisionsTab_innerContainer__89-iH {
  max-width: 150.9rem;
  width: 90%;
  margin: 7rem auto;
  margin-bottom: 7rem;
}

.SmarterDecisionsTab_TextContainer__Bc7gv {
  max-width: 96rem;
}
.SmarterDecisionsTab_heading__h8Wtp {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0.5rem;
  letter-spacing: 0.3px;
  line-height: 3.9rem;
}
.SmarterDecisionsTab_TabContainer__TIIcy {
  border-bottom: 2px solid #13131330;
  margin-top: 5rem;
  padding: 0px 3rem;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  gap: 3rem;
  overflow-x: auto;
}
.SmarterDecisionsTab_tabBox__QP4VU {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}
.SmarterDecisionsTab_blueBorder__1Wu49 {
  border-bottom: 2px solid #1cb9c8;
}
/* .icon {
  max-width: 3rem;
} */
.SmarterDecisionsTab_heading2__y-2Qk {
  font-weight: 700;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0.5rem;
  letter-spacing: 0.3px;
  line-height: 3.2rem;
  font-size: 2.1rem;
}

@media (max-width: 800px) {
  .SmarterDecisionsTab_TabContainer__TIIcy {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/ErpPage/SmarterDecisionsTab/SmarterDecisionsTab.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,gCAAgC;AAClC;AACA;;GAEG;AACH;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,qBAAqB;EACrB,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".innerContainer {\n  max-width: 150.9rem;\n  width: 90%;\n  margin: 7rem auto;\n  margin-bottom: 7rem;\n}\n\n.TextContainer {\n  max-width: 96rem;\n}\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 0.5rem;\n  letter-spacing: 0.3px;\n  line-height: 3.9rem;\n}\n.TabContainer {\n  border-bottom: 2px solid #13131330;\n  margin-top: 5rem;\n  padding: 0px 3rem;\n  display: flex;\n  justify-content: space-between;\n  white-space: nowrap;\n  gap: 3rem;\n  overflow-x: auto;\n}\n.tabBox {\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n  margin-bottom: 1rem;\n}\n.blueBorder {\n  border-bottom: 2px solid #1cb9c8;\n}\n/* .icon {\n  max-width: 3rem;\n} */\n.heading2 {\n  font-weight: 700;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 0.5rem;\n  letter-spacing: 0.3px;\n  line-height: 3.2rem;\n  font-size: 2.1rem;\n}\n\n@media (max-width: 800px) {\n  .TabContainer {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `SmarterDecisionsTab_innerContainer__89-iH`,
	"TextContainer": `SmarterDecisionsTab_TextContainer__Bc7gv`,
	"heading": `SmarterDecisionsTab_heading__h8Wtp`,
	"TabContainer": `SmarterDecisionsTab_TabContainer__TIIcy`,
	"tabBox": `SmarterDecisionsTab_tabBox__QP4VU`,
	"blueBorder": `SmarterDecisionsTab_blueBorder__1Wu49`,
	"heading2": `SmarterDecisionsTab_heading2__y-2Qk`
};
export default ___CSS_LOADER_EXPORT___;
