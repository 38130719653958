import React from "react";
import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../../assets/img/SEOSupport/SEOHeader.png";

const SeoSupportHeader = () => {
  return (
    <>
      <BgContainerImg
        btns={["HOME ", "SERVICES", "SEO/SMM SUPPORT"]}
        img={img}
        heading="SEO/SMM SUPPORT"
        isTab={true}
      />
    </>
  );
};

export default SeoSupportHeader;
