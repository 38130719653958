// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProphetCRMintegrationComponet_innerContainer__PSi0x {
  max-width: 145rem;
  width: 90%;
  margin: 10rem auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 10rem;
}

.ProphetCRMintegrationComponet_imageContainer__Fq6sP {
  max-width: 55.6rem;
  width: 50%;
}
.ProphetCRMintegrationComponet_imageContainer__Fq6sP > img {
  width: 100%;
}

.ProphetCRMintegrationComponet_textContainer__BGUsx {
  max-width: 61.5rem;
  padding-top: 1.2rem;
  width: 50%;
}
.ProphetCRMintegrationComponet_heading__D7SpM {
  font-weight: 600;
  margin-bottom: 2.6rem;
}

.ProphetCRMintegrationComponet_aboutDescriptionText__EjIwn {
  color: var(--gray-666);
  line-height: 2.74rem;
  margin-bottom: 1rem;
}

.ProphetCRMintegrationComponet_pointItem__VND52 {
  display: flex;
  column-gap: 1.6rem;
  align-items: center;
}
.ProphetCRMintegrationComponet_flexDirection__TW\\+-3 {
  flex-direction: row-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/nssplPartials/ProphetCRMintegrationComponet/ProphetCRMintegrationComponet.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;EACV,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".innerContainer {\n  max-width: 145rem;\n  width: 90%;\n  margin: 10rem auto;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  column-gap: 10rem;\n}\n\n.imageContainer {\n  max-width: 55.6rem;\n  width: 50%;\n}\n.imageContainer > img {\n  width: 100%;\n}\n\n.textContainer {\n  max-width: 61.5rem;\n  padding-top: 1.2rem;\n  width: 50%;\n}\n.heading {\n  font-weight: 600;\n  margin-bottom: 2.6rem;\n}\n\n.aboutDescriptionText {\n  color: var(--gray-666);\n  line-height: 2.74rem;\n  margin-bottom: 1rem;\n}\n\n.pointItem {\n  display: flex;\n  column-gap: 1.6rem;\n  align-items: center;\n}\n.flexDirection {\n  flex-direction: row-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `ProphetCRMintegrationComponet_innerContainer__PSi0x`,
	"imageContainer": `ProphetCRMintegrationComponet_imageContainer__Fq6sP`,
	"textContainer": `ProphetCRMintegrationComponet_textContainer__BGUsx`,
	"heading": `ProphetCRMintegrationComponet_heading__D7SpM`,
	"aboutDescriptionText": `ProphetCRMintegrationComponet_aboutDescriptionText__EjIwn`,
	"pointItem": `ProphetCRMintegrationComponet_pointItem__VND52`,
	"flexDirection": `ProphetCRMintegrationComponet_flexDirection__TW+-3`
};
export default ___CSS_LOADER_EXPORT___;
