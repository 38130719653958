import React from "react";
import styles from "./../../ErpPage/ERPSystem/ErpSystem.module.css";
import img from "./../../../../assets/img/WebConferenceSolutions/Teams.png";
import { Link } from "react-router-dom";

const ConnectingTeams = () => {
  return (
    <div className={styles.innerContainer}>
      <img src={img} alt="" className={styles.img} />

      <div className={styles.TextContainer}>
        <h3 className={styles.heading}>
          Connecting Teams Worldwide - Breaking Barriers and Driving Global
          Productivity
        </h3>
        <h4 className="heading2 caption">
          Simplified online meeting software with enterprise-grade security
          – perfect for a work-wherever world.
        </h4>
        <Link to={"/contact"}>
          <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
        </Link>
      </div>
    </div>
  );
};

export default ConnectingTeams;
