import React from "react";
import styles from "./SocialMedia.module.css";
import img from "./../../../../assets//img/SEOSupport/Social.png";

const SocialMedia = () => {
  return (
    <section className={styles.sectionBgColor}>
      <div className={styles.innerContainer}>
        <img src={img} alt="" className={styles.img} />

        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            Social media tools at your fingertips
          </h3>
          <h4 className="heading2 caption">
            Vueporteq offers reliable AI and ML development services that
            convert your vision into reality. Our expert team delivers
            intelligent ML solutions, Unlocking data’s true potential for
            enabling businesses to achieve their true success.
          </h4>
          <button className={`btnPrimary ${styles.btn}`}>FREE DEMO</button>
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
