import React from "react";
import img from "./../../../../assets/img/CRM/CustomizeCard.png";
import styles from "./CustomizeCard.module.css";
import { Link } from "react-router-dom";

const CustomizeCard = () => {
  return (
    <section className={styles.sectionBgColor}>
      <div className={styles.innerContainer}>
        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            Customize, upscale, and deploy with ease
          </h3>
          <h4 className="heading2 caption">
            Vueporteq CRM is yours—and we mean it. With a dedicated admin panel,
            state-of-the-art customization, hassle-free migration, consultant
            support, and a powerful developer platform, you can truly
            use Vueporteq CRM to the fullest.
          </h4>
          <Link to={"/contact"}>
            <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
          </Link>
        </div>

        <img src={img} alt="" className={styles.img} />
      </div>
    </section>
  );
};

export default CustomizeCard;
