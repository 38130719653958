import React from "react";

const InstaIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2008_516)">
        <path
          d="M6.99902 4.6311C5.10616 4.6311 3.57938 6.13403 3.57938 7.99731C3.57938 9.86059 5.10616 11.3635 6.99902 11.3635C8.89188 11.3635 10.4187 9.86059 10.4187 7.99731C10.4187 6.13403 8.89188 4.6311 6.99902 4.6311ZM6.99902 10.1858C5.77581 10.1858 4.77581 9.20434 4.77581 7.99731C4.77581 6.79028 5.77283 5.80884 6.99902 5.80884C8.22521 5.80884 9.22223 6.79028 9.22223 7.99731C9.22223 9.20434 8.22223 10.1858 6.99902 10.1858ZM11.3562 4.49341C11.3562 4.92993 10.999 5.27856 10.5585 5.27856C10.1151 5.27856 9.76093 4.927 9.76093 4.49341C9.76093 4.05981 10.1181 3.70825 10.5585 3.70825C10.999 3.70825 11.3562 4.05981 11.3562 4.49341ZM13.621 5.29028C13.5704 4.23852 13.3264 3.30688 12.5437 2.53931C11.7639 1.77173 10.8175 1.53149 9.74902 1.47876C8.64783 1.41724 5.34724 1.41724 4.24605 1.47876C3.18057 1.52856 2.23414 1.7688 1.4514 2.53638C0.668666 3.30395 0.427595 4.23559 0.374023 5.28735C0.311523 6.37134 0.311523 9.62036 0.374023 10.7043C0.424619 11.7561 0.668666 12.6877 1.4514 13.4553C2.23414 14.2229 3.17759 14.4631 4.24605 14.5159C5.34724 14.5774 8.64783 14.5774 9.74902 14.5159C10.8175 14.4661 11.7639 14.2258 12.5437 13.4553C13.3234 12.6877 13.5675 11.7561 13.621 10.7043C13.6835 9.62036 13.6835 6.37427 13.621 5.29028ZM12.1984 11.8674C11.9663 12.4416 11.5169 12.884 10.9306 13.1155C10.0526 13.4582 7.96926 13.3791 6.99902 13.3791C6.02878 13.3791 3.94247 13.4553 3.06747 13.1155C2.48414 12.887 2.03474 12.4446 1.79962 11.8674C1.4514 11.0032 1.53176 8.95239 1.53176 7.99731C1.53176 7.04223 1.45438 4.98852 1.79962 4.1272C2.03176 3.55298 2.48117 3.11059 3.06747 2.87915C3.94545 2.53638 6.02878 2.61548 6.99902 2.61548C7.96926 2.61548 10.0556 2.53931 10.9306 2.87915C11.5139 3.10767 11.9633 3.55005 12.1984 4.1272C12.5466 4.99145 12.4663 7.04223 12.4663 7.99731C12.4663 8.95239 12.5466 11.0061 12.1984 11.8674Z"
          fill="#131313"
        />
      </g>
      <defs>
        <clipPath id="clip0_2008_516">
          <rect
            width="13.3333"
            height="15"
            fill="white"
            transform="translate(0.32959 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstaIcon;
