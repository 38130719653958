import React from "react";
import AimlHeader from "../components/app/AI/MLPage/AimlHeader";
import styles from "./../components/app/CrmPage/Main.module.css";
import SideBarHeader from "../components/app/CrmPage/SideBarHeader/SideBarHeader";
import DecisionMaking from "../components/app/AI/MLPage/DecisionMaking/DecisionMaking";
import IndustryUsecases from "../components/app/AI/MLPage/IndustryUsecases/IndustryUsecases";
import CrmIntegrationsCard from "../components/app/AI/MLPage/CrmIntegrationsCard/CrmIntegrationsCard";
import Pricing from "../components/app/CrmPage/Pricing/Pricing";
import Services from "../components/app/AI/MLPage/Services/Services";

const AimlPage = () => {
  return (
    <>
      <AimlHeader />
      <DecisionMaking />
      <IndustryUsecases />
      <Services />
      <CrmIntegrationsCard />
      {/* <Pricing /> */}
    </>
  );
};

export default AimlPage;
