import React from "react";

const CustomerServiceIcon2 = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_540_18748)">
        <path
          d="M29 1.93327L29.8661 0.204868C29.5973 0.0701482 29.3007 0 29 0C28.6993 0 28.4027 0.0701482 28.1339 0.204868L29 1.93327ZM17.4 32.8666V30.9333H15.4667V32.8666H17.4ZM32.8667 32.8666H34.8V30.9333H32.8667V32.8666ZM0 57.9999H58V54.1333H0V57.9999ZM28.1339 0.204868L4.93387 11.8049L6.66613 15.2617L29.8661 3.66167L28.1339 0.204868ZM0 23.1999H58V19.3333H0V23.1999ZM53.0661 11.8049L29.8661 0.204868L28.1339 3.66167L51.3339 15.2617L53.0661 11.8049ZM3.86667 21.2666V56.0666H7.73333V21.2666H3.86667ZM50.2667 21.2666V56.0666H54.1333V21.2666H50.2667ZM19.3333 56.0666V32.8666H15.4667V56.0666H19.3333ZM17.4 34.7999H32.8667V30.9333H17.4V34.7999ZM30.9333 32.8666V56.0666H34.8V32.8666H30.9333Z"
          fill="#1CB9C8"
        />
      </g>
      <defs>
        <clipPath id="clip0_540_18748">
          <rect width="58" height="58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomerServiceIcon2;
