import React from "react";

const CustomerServiceIcon3 = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 14.5H18.125V21.75H14.5V14.5ZM14.5 25.375H18.125V32.625H14.5V25.375ZM25.375 14.5H29V21.75H25.375V14.5ZM25.375 25.375H29V32.625H25.375V25.375ZM14.5 36.25H18.125V43.5H14.5V36.25ZM25.375 36.25H29V43.5H25.375V36.25Z"
        fill="#1CB9C8"
      />
      <path
        d="M54.375 25.375C54.375 24.4136 53.9931 23.4916 53.3133 22.8117C52.6334 22.1319 51.7114 21.75 50.75 21.75H39.875V7.25C39.875 6.28859 39.4931 5.36656 38.8133 4.68674C38.1334 4.00692 37.2114 3.625 36.25 3.625H7.25C6.28859 3.625 5.36656 4.00692 4.68674 4.68674C4.00692 5.36656 3.625 6.28859 3.625 7.25V54.375H54.375V25.375ZM7.25 7.25H36.25V50.75H7.25V7.25ZM39.875 50.75V25.375H50.75V50.75H39.875Z"
        fill="#1CB9C8"
      />
    </svg>
  );
};

export default CustomerServiceIcon3;
