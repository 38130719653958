import React from "react";

const BulletPointIcon = () => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="23.6053" height="22.6199" rx="11.31" fill="#CDDDFF" />
      <path
        d="M7.36523 11.047L11.5736 6.83862L15.782 11.047L11.5736 15.2553L7.36523 11.047Z"
        fill="#0071BC"
      />
      <path
        d="M11.5736 4.73446C12.8221 4.73446 14.0426 5.10469 15.0807 5.79832C16.1188 6.49195 16.9279 7.47783 17.4056 8.6313C17.8834 9.78476 18.0084 11.054 17.7649 12.2785C17.5213 13.503 16.9201 14.6278 16.0373 15.5106C15.1544 16.3935 14.0296 16.9947 12.8051 17.2382C11.5806 17.4818 10.3114 17.3568 9.15791 16.879C8.00445 16.4012 7.01856 15.5922 6.32493 14.5541C5.6313 13.516 5.26108 12.2955 5.26108 11.047C5.26303 9.37341 5.92872 7.76892 7.11213 6.58552C8.29554 5.40211 9.90003 4.73641 11.5736 4.73446ZM11.5736 3.68237C10.117 3.68237 8.69315 4.1143 7.48205 4.92354C6.27094 5.73277 5.327 6.88297 4.76959 8.22868C4.21218 9.57439 4.06633 11.0552 4.3505 12.4838C4.63466 13.9124 5.33608 15.2246 6.36604 16.2546C7.396 17.2845 8.70825 17.986 10.1368 18.2701C11.5654 18.5543 13.0462 18.4084 14.3919 17.851C15.7376 17.2936 16.8878 16.3497 17.6971 15.1386C18.5063 13.9275 18.9382 12.5036 18.9382 11.047C18.9382 9.09378 18.1623 7.22056 16.7812 5.83942C15.4001 4.45829 13.5268 3.68237 11.5736 3.68237Z"
        fill="#0071BC"
      />
    </svg>
  );
};

export default BulletPointIcon;
