// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FiservOndot_InnerContainer__PlOw- {
  max-width: 1400px;
  margin: auto;
  margin-top: 5rem;
  display: flex;
}
.FiservOndot_imgContainer__SWOsI {
  max-width: 55%;
  width: 100%;
}
.FiservOndot_imgContainer__SWOsI img {
  width: 100%;
}
@media (max-width: 1450px) {
  .FiservOndot_InnerContainer__PlOw- {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .FiservOndot_imgContainer__SWOsI img {
    max-width: 65rem;
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .FiservOndot_ImgContainer__FQou7 {
    max-width: 70rem;
  }
}
@media (max-width: 768px) {
  .FiservOndot_InnerContainer__PlOw- {
    flex-direction: column-reverse;
  }
  .FiservOndot_imgContainer__SWOsI {
    margin: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CaseStudyPage/FiservOndot/FiservOndot.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,8BAA8B;EAChC;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".InnerContainer {\n  max-width: 1400px;\n  margin: auto;\n  margin-top: 5rem;\n  display: flex;\n}\n.imgContainer {\n  max-width: 55%;\n  width: 100%;\n}\n.imgContainer img {\n  width: 100%;\n}\n@media (max-width: 1450px) {\n  .InnerContainer {\n    margin-left: 3rem;\n    margin-right: 3rem;\n  }\n  .imgContainer img {\n    max-width: 65rem;\n    width: 100%;\n  }\n}\n@media (max-width: 1200px) {\n  .ImgContainer {\n    max-width: 70rem;\n  }\n}\n@media (max-width: 768px) {\n  .InnerContainer {\n    flex-direction: column-reverse;\n  }\n  .imgContainer {\n    margin: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InnerContainer": `FiservOndot_InnerContainer__PlOw-`,
	"imgContainer": `FiservOndot_imgContainer__SWOsI`,
	"ImgContainer": `FiservOndot_ImgContainer__FQou7`
};
export default ___CSS_LOADER_EXPORT___;
