// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErpItSolutions_borderBottom__Xhjo1 {
  border-bottom: 1px solid #e7e7e7;
}
.ErpItSolutions_innerContainer__-sbSF {
  max-width: 150.9rem;
  width: 85%;
  margin: 7rem auto;
}
.ErpItSolutions_cardContainer__lR8ph {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}
.ErpItSolutions_cards__HeNZD {
  display: flex;
  column-gap: 2.2rem;
  max-width: 49rem;
}

.ErpItSolutions_heading__6wLhK {
  color: #232323;
  font-weight: 800;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  line-height: 2.1rem;
  margin-bottom: 1rem;
}
.ErpItSolutions_pay__if9uf {
  line-height: 2.6rem;
  font-size: 1.4rem;
}
.ErpItSolutions_bgblueColor__K\\+7XR {
  min-width: 5.5rem;
  max-width: 5.5rem;
  height: 5.5rem;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f2f2f2;
}

@media (max-width: 1024px) {
  .ErpItSolutions_innerContainer__-sbSF {
    width: 95%;
  }
}
@media (max-width: 900px) {
  .ErpItSolutions_innerContainer__-sbSF {
    margin: 4rem auto;
  }
  .ErpItSolutions_cardContainer__lR8ph {
    grid-template-columns: 1fr;
  }
  .ErpItSolutions_cards__HeNZD {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/ErpPage/ERPITSolutions/ErpItSolutions.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;AACA;EACE,mBAAmB;EACnB,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,qCAAqC;EACrC,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".borderBottom {\n  border-bottom: 1px solid #e7e7e7;\n}\n.innerContainer {\n  max-width: 150.9rem;\n  width: 85%;\n  margin: 7rem auto;\n}\n.cardContainer {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 3rem;\n}\n.cards {\n  display: flex;\n  column-gap: 2.2rem;\n  max-width: 49rem;\n}\n\n.heading {\n  color: #232323;\n  font-weight: 800;\n  font-size: 2rem;\n  font-family: \"Montserrat\", sans-serif;\n  line-height: 2.1rem;\n  margin-bottom: 1rem;\n}\n.pay {\n  line-height: 2.6rem;\n  font-size: 1.4rem;\n}\n.bgblueColor {\n  min-width: 5.5rem;\n  max-width: 5.5rem;\n  height: 5.5rem;\n  border-radius: 20%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 2px solid #f2f2f2;\n}\n\n@media (max-width: 1024px) {\n  .innerContainer {\n    width: 95%;\n  }\n}\n@media (max-width: 900px) {\n  .innerContainer {\n    margin: 4rem auto;\n  }\n  .cardContainer {\n    grid-template-columns: 1fr;\n  }\n  .cards {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderBottom": `ErpItSolutions_borderBottom__Xhjo1`,
	"innerContainer": `ErpItSolutions_innerContainer__-sbSF`,
	"cardContainer": `ErpItSolutions_cardContainer__lR8ph`,
	"cards": `ErpItSolutions_cards__HeNZD`,
	"heading": `ErpItSolutions_heading__6wLhK`,
	"pay": `ErpItSolutions_pay__if9uf`,
	"bgblueColor": `ErpItSolutions_bgblueColor__K+7XR`
};
export default ___CSS_LOADER_EXPORT___;
