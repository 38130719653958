import React from "react";

const ProjectIcon: React.FC<{
  imgColor1: string;
}> = ({ imgColor1 }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13"
        cy="13"
        r="12"
        fill="white"
        stroke={imgColor1}
        stroke-width="2"
      />
      <path
        d="M19.563 13C19.563 16.6242 16.6215 19.5656 12.9973 19.5656C9.37306 19.5656 6.43164 16.6242 6.43164 13C6.43164 9.37574 9.37306 6.43433 12.9973 6.43433C16.6215 6.43433 19.563 9.37574 19.563 13Z"
        stroke={imgColor1}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4346 15.0879L13.3993 13.8732C13.0447 13.6631 12.7559 13.1576 12.7559 12.7439V10.052"
        stroke={imgColor1}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ProjectIcon;
