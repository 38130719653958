import React from "react";
import style from "./BusinessProcessCard.module.css";
import RIghtClickArrow from "../../../icons/RIghtClickArrow";
import img from "./../../../../assets/img/about/Business.png";

const BusinessProcessCard = () => {
  return (
    <section>
      <div className={style.innerContainer}>
        <div className={style.main}>
          <div className={style.ContainerWidth}>
            <h3 className="heading">
              We are expert at Business process improvements by leveraging the
              next generation technologies !
            </h3>
            <h4 className="heading caption">
              As one of the leading Information
              Technology consulting company, Vueporteq IT
              Solutions delivers tailored IT solutions to organizations
              Globally.
            </h4>
            <div className={style.icon}>
              <div>
                <RIghtClickArrow imgColor="" />
              </div>
              <div>
                <p className={`${style.partnerHeding} caption`}>
                  Vueporteq as your partner
                </p>
                <p className={`${style.heading2} caption`}>
                  With Vueporteq as your partner, we can leap your organization
                  forward with highly effective IT strategies and implement
                  innovative Cloud solutions.
                </p>
              </div>
            </div>

            <div className={style.icon}>
              <div>
                <RIghtClickArrow imgColor="" />
              </div>
              <div>
                <p className={`${style.partnerHeding} caption`}>Our Focus</p>
                <p className={`${style.heading2} caption`}>
                  Vueporteq is focused on developing highly secure and scalable
                  Mobile and Web based cloud solutions for our Global Clients
                  with complete Vertical support.
                </p>
              </div>
            </div>
          </div>
          <img src={img} alt="" className={style.img} />
        </div>
      </div>
    </section>
  );
};

export default BusinessProcessCard;
