// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AvidianEmbeddedOutlook_embeddedInnerContainer__Po6o\\+ {
  margin-bottom: 2rem;
}

.AvidianEmbeddedOutlook_embeddedInnerContainer__Po6o\\+ div:has(img) {
  max-width: 80rem !important;
}

.AvidianEmbeddedOutlook_embeddedCard3InnerContainer__gMt8X div:has(img) {
  max-width: 65rem !important;
}
.AvidianEmbeddedOutlook_mobileCardInnerContainer__64kA9 {
  margin-bottom: 0 !important;
}
.AvidianEmbeddedOutlook_mobileCardInnerContainer__64kA9 div:has(img) {
  max-width: 50rem !important;
}

.AvidianEmbeddedOutlook_embeddedPointsContainer__S5WVG {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.AvidianEmbeddedOutlook_embeddedTextContainer__jgoqz {
  max-width: 60rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/AvidianComponents/AvidianEmbeddedOutlook/AvidianEmbeddedOutlook.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".embeddedInnerContainer {\n  margin-bottom: 2rem;\n}\n\n.embeddedInnerContainer div:has(img) {\n  max-width: 80rem !important;\n}\n\n.embeddedCard3InnerContainer div:has(img) {\n  max-width: 65rem !important;\n}\n.mobileCardInnerContainer {\n  margin-bottom: 0 !important;\n}\n.mobileCardInnerContainer div:has(img) {\n  max-width: 50rem !important;\n}\n\n.embeddedPointsContainer {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n}\n\n.embeddedTextContainer {\n  max-width: 60rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"embeddedInnerContainer": `AvidianEmbeddedOutlook_embeddedInnerContainer__Po6o+`,
	"embeddedCard3InnerContainer": `AvidianEmbeddedOutlook_embeddedCard3InnerContainer__gMt8X`,
	"mobileCardInnerContainer": `AvidianEmbeddedOutlook_mobileCardInnerContainer__64kA9`,
	"embeddedPointsContainer": `AvidianEmbeddedOutlook_embeddedPointsContainer__S5WVG`,
	"embeddedTextContainer": `AvidianEmbeddedOutlook_embeddedTextContainer__jgoqz`
};
export default ___CSS_LOADER_EXPORT___;
