import React from "react";
import BgContainerImg from "../../../../partials/BgContainerCard/BgContainerImg";
import img from "../../../../../assets/img/mtc/header.png";

const MtcPageHeader = () => {
  return (
    <BgContainerImg
      btns={["HOME ", "PRODUCTS", "MTC"]}
      img={img}
      heading="My true cloud"
      isTab={true}
    />
  );
};

export default MtcPageHeader;
