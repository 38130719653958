import React from "react";

const ErpItSolution4 = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_544_2864)">
        <path
          d="M13 6.50004C15.8383 6.50004 18.2867 8.51504 18.8392 11.2992L19.1642 12.9242L20.8217 13.0434C21.636 13.0981 22.3995 13.4594 22.9582 14.0543C23.517 14.6493 23.8297 15.4338 23.8333 16.25C23.8333 18.0375 22.3708 19.5 20.5833 19.5H6.5C4.10583 19.5 2.16667 17.5609 2.16667 15.1667C2.16667 12.9459 3.82417 11.0934 6.02333 10.8659L7.1825 10.7467L7.72417 9.71754C8.22237 8.74772 8.97853 7.93417 9.90938 7.36648C10.8402 6.7988 11.9097 6.49898 13 6.50004ZM13 4.33337C9.86917 4.33337 7.15 6.11004 5.79583 8.71004C4.20359 8.88211 2.73109 9.63652 1.66128 10.8283C0.591469 12.0201 -0.00018601 13.5652 4.38672e-08 15.1667C4.38672e-08 18.7525 2.91417 21.6667 6.5 21.6667H20.5833C23.5733 21.6667 26 19.24 26 16.25C26 13.39 23.7792 11.0717 20.9625 10.8767C20.5986 9.03257 19.6057 7.37197 18.1535 6.17856C16.7012 4.98514 14.8797 4.33295 13 4.33337Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_544_2864">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErpItSolution4;
