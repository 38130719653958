// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextHeading_textHeadingContainer__V5\\+ZT {
  max-width: 90rem;
  margin: auto;
  margin-bottom: 6rem;
  text-align: center;
  width: 90%;
}
.TextHeading_pay__dcMfM {
  line-height: 26px;
  max-width: 80rem;
  margin: auto;
}
.TextHeading_borderContainer__TlY8d {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
}
.TextHeading_BoderBlack__9Woqg {
  border: 2px solid #232323;
  width: 25px;
  display: block;
}
.TextHeading_BoderBlue__tGQjx {
  border: 2px solid #1cb9c8;
  width: 50px;
  display: block;
}

@media (max-width: 1024px) {
  .TextHeading_textHeadingContainer__V5\\+ZT {
    margin-bottom: 3rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/TextHeading/TextHeading.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".textHeadingContainer {\n  max-width: 90rem;\n  margin: auto;\n  margin-bottom: 6rem;\n  text-align: center;\n  width: 90%;\n}\n.pay {\n  line-height: 26px;\n  max-width: 80rem;\n  margin: auto;\n}\n.borderContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  column-gap: 1rem;\n  margin-bottom: 1rem;\n}\n.BoderBlack {\n  border: 2px solid #232323;\n  width: 25px;\n  display: block;\n}\n.BoderBlue {\n  border: 2px solid #1cb9c8;\n  width: 50px;\n  display: block;\n}\n\n@media (max-width: 1024px) {\n  .textHeadingContainer {\n    margin-bottom: 3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textHeadingContainer": `TextHeading_textHeadingContainer__V5+ZT`,
	"pay": `TextHeading_pay__dcMfM`,
	"borderContainer": `TextHeading_borderContainer__TlY8d`,
	"BoderBlack": `TextHeading_BoderBlack__9Woqg`,
	"BoderBlue": `TextHeading_BoderBlue__tGQjx`
};
export default ___CSS_LOADER_EXPORT___;
