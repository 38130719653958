import React from "react";

const Technology: React.FC<{
  imgColor: string;
}> = ({ imgColor }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill={imgColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 5.41667L13 3.25M8.66667 17.3333L17.3333 8.66667M13 22.75L15.1667 20.5833M17.875 20.5833C18.5933 20.5833 19.2822 20.298 19.7901 19.7901C20.298 19.2822 20.5833 18.5933 20.5833 17.875C20.5833 17.1567 20.298 16.4678 19.7901 15.9599C19.2822 15.452 18.5933 15.1667 17.875 15.1667C17.1567 15.1667 16.4678 15.452 15.9599 15.9599C15.452 16.4678 15.1667 17.1567 15.1667 17.875C15.1667 18.5933 15.452 19.2822 15.9599 19.7901C16.4678 20.298 17.1567 20.5833 17.875 20.5833ZM8.125 10.8333C8.84329 10.8333 9.53217 10.548 10.0401 10.0401C10.548 9.53217 10.8333 8.84329 10.8333 8.125C10.8333 7.40671 10.548 6.71783 10.0401 6.20992C9.53217 5.70201 8.84329 5.41667 8.125 5.41667C7.40671 5.41667 6.71783 5.70201 6.20992 6.20992C5.70201 6.71783 5.41667 7.40671 5.41667 8.125C5.41667 8.84329 5.70201 9.53217 6.20992 10.0401C6.71783 10.548 7.40671 10.8333 8.125 10.8333ZM5.95833 22.75C6.67663 22.75 7.3655 22.4647 7.87341 21.9567C8.38133 21.4488 8.66667 20.76 8.66667 20.0417C8.66667 19.3234 8.38133 18.6345 7.87341 18.1266C7.3655 17.6187 6.67663 17.3333 5.95833 17.3333C5.24004 17.3333 4.55116 17.6187 4.04325 18.1266C3.53534 18.6345 3.25 19.3234 3.25 20.0417C3.25 20.76 3.53534 21.4488 4.04325 21.9567C4.55116 22.4647 5.24004 22.75 5.95833 22.75ZM20.0417 8.66667C20.76 8.66667 21.4488 8.38133 21.9567 7.87341C22.4647 7.3655 22.75 6.67663 22.75 5.95833C22.75 5.24004 22.4647 4.55116 21.9567 4.04325C21.4488 3.53534 20.76 3.25 20.0417 3.25C19.3234 3.25 18.6345 3.53534 18.1266 4.04325C17.6187 4.55116 17.3333 5.24004 17.3333 5.95833C17.3333 6.67663 17.6187 7.3655 18.1266 7.87341C18.6345 8.38133 19.3234 8.66667 20.0417 8.66667Z"
        stroke={imgColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Technology;
