import React from "react";
import styles from "./TextFlexCard.module.css";
import img from "./../../../assets/img/SEOSupport/monitor.png";
import effectImg from "./../../../assets/img/SEOSupport/Effect.png";

const TextFlexCard: React.FC<{
  Heading: string;
  para: string;
  img: string;
  btns?: { text: String }[];
}> = ({ Heading, img, para, btns }) => {
  return (
    <section>
      <div className={styles.innerContainer}>
        <div className={`${styles.CardConainer}`}>
          <div className={styles.TextContainer}>
            <h3 className={styles.heading}>{Heading}</h3>
            <h4 className="heading2 caption">{para}</h4>
            {btns &&
              btns.map((item, i) => (
                <button className={`btnPrimary ${styles.btn}`}>
                  {item.text}
                </button>
              ))}
          </div>
          <div className={styles.imgContainer}>
            <img src={img} alt="" className={styles.img} />
            {/* <img src={effectImg} alt="" className={styles.effectImg} /> */}
          </div>
        </div>
      </div>
      <div className={styles.border}></div>
    </section>
  );
};

export default TextFlexCard;
