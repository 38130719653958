import React from "react";

const TickIcon = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.65656 10.3746L1.18842 5.90649C0.91925 5.63733 0.91925 5.17975 1.18842 4.91058L2.15741 3.94159C2.42657 3.67242 2.85724 3.67242 3.1264 3.94159L6.16797 6.95624L12.6279 0.496277C12.8971 0.227112 13.3278 0.227112 13.5969 0.496277L14.5659 1.46527C14.8351 1.73444 14.8351 2.19202 14.5659 2.46118L6.65247 10.3746C6.3833 10.6438 5.92572 10.6438 5.65656 10.3746Z"
        fill="#1CB9C8"
      />
    </svg>
  );
};

export default TickIcon;
