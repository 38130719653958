import React, { useEffect, useRef, useState } from "react";
import styles from "./Nav.module.css";
import logo from "../../../assets/img/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import CloseIcon from "../../icons/CloseIcon";
import HamburgerIcon from "../../icons/HamburgerIcon";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const links = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Services",
    dropdown: [
      { text: "App Development", link: "services/appdev" },
      { text: "WEB Development", link: "services/webdev" },
      { text: "AI/ML", link: "aiml" },
      { text: "SEO/SMM Support", link: "seo-support" },
    ],
  },
  {
    title: "Products",
    dropdown: [
      { text: "CRM", link: "/crm" },
      { text: "Web Conference Solutions", link: "web-conference-solutions" },
      { text: "Document Management System", link: "/dms" },
      { text: "ERP", link: "erp" },
      { text: "Avidian", link: "avidian" },
      // { text: "MTC", link: "mtc" },
    ],
  },
  {
    title: "Case Studies",
    link: "/case-study",
    // dropdown: [{ text: "Case Study", link: "case-study" }],
  },
  {
    title: "About",
    link: "about",
  },
  {
    title: "Contact",
    link: "/contact",
  },
];

const Nav = () => {
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("");
  const [activeDropdownHeight, setActiveDropdownHeight] = useState(0);
  const dropdownLinkRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (!activeDropdown) {
      setActiveDropdownHeight(0);
      return;
    }
    const linkHeight = dropdownLinkRef.current.offsetHeight;
    const totalChilds = links
      .filter((item) => item.title === activeDropdown)
      ?.pop()?.dropdown.length;

    const height = linkHeight * totalChilds + totalChilds * 5;
    console.log("height: ", height);
    setActiveDropdownHeight(height);
  }, [activeDropdown]);

  useEffect(() => {
    setShowSidebar(false);
    setActiveDropdown("");
    window.scrollTo({ top: 0 });
  }, [location]);

  const handleActiveDropdown = (title: string) => {
    if (activeDropdown === title) {
      setActiveDropdown("");
      return;
    }
    setActiveDropdown(title);
  };

  return (
    <nav className={styles.nav}>
      <div className={styles.innerContainer}>
        <Link to={"/"}>
          <img src={logo} className={styles.logo} alt="logo" />
        </Link>

        <button
          onClick={() => {
            setShowSidebar(true);
          }}
          className={`lg:hidden cursor-pointer ${styles.hamburgerBtn}`}
        >
          <HamburgerIcon />
        </button>

        <div className={styles.linkContainer}>
          {links.map((item, i) => {
            return item.dropdown ? (
              <div key={i} className={styles.dropdownContainer}>
                <a>{item.title}</a>
                <div className={styles.dropdown}>
                  <div className={styles.dropdownInnerContainer}>
                    {item.dropdown.map((point, j) => (
                      <NavLink key={j} to={point.link}>
                        {point.text}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <NavLink key={item.title} to={item.link}>
                {item.title}
              </NavLink>
            );
          })}
        </div>
      </div>

      <div
        className={` ${
          showSidebar ? styles.showSidebar : styles.hideDropdown
        } ${styles.sidebar}`}
      >
        <button
          onClick={() => {
            setShowSidebar(false);
          }}
          className={`${styles.hamburgerBtn}`}
        >
          <CloseIcon />
        </button>

        <div
          className={`flex flex-col max-md:gap-y-6 gap-y-8 mt-10 ${styles.sidebarLinkContainer}`}
        >
          {links.map((item, i) =>
            item.link ? (
              <Link to={item.link} key={i}>
                {item.title}
              </Link>
            ) : (
              <div key={i}>
                <p
                  onClick={() => {
                    handleActiveDropdown(item.title);
                  }}
                  className={`mb-2 ${styles.sidebarItemLinkContainer}`}
                >
                  {item.title}
                  {item.title === activeDropdown ? (
                    <ArrowDropUp />
                  ) : (
                    <ArrowDropDown />
                  )}
                </p>
                <div
                  className="flex flex-col gap-y-2 pl-4 duration-200 overflow-hidden"
                  style={{
                    height:
                      activeDropdown === item.title ? activeDropdownHeight : 0,
                  }}
                >
                  {item.dropdown?.map((option, j) => (
                    <Link ref={dropdownLinkRef} key={j} to={option.link}>
                      {option.text}
                    </Link>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div
        className={`duration-500 h-full fixed bg-black/80 top-0 left-0 z-10 ${
          showSidebar ? "w-full" : "w-0"
        }`}
      />
    </nav>
  );
};

export default Nav;
