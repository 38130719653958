import React from "react";
import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../../assets/img/about/AboutHeader.png";

const AboutHeader = () => {
  return (
    <section>
      <BgContainerImg
        btns={["HOME ", "SERVICES", "SEO/SMM SUPPORT"]}
        img={img}
        heading="About US"
        isTab={false}
      />
    </section>
  );
};

export default AboutHeader;
