// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IndustryUsecases_innerContainer__AkAZ1 {
  max-width: 150.9rem;
  /* width: 95%; */
  /* margin: 6rem 0px 5rem auto; */
  margin: auto;
  margin-bottom: 5rem;
  background-color: #ffff;
}

.IndustryUsecases_TextContainer__3RwjJ {
  max-width: 98.6rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/AI/MLPage/IndustryUsecases/IndustryUsecases.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,gCAAgC;EAChC,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".innerContainer {\n  max-width: 150.9rem;\n  /* width: 95%; */\n  /* margin: 6rem 0px 5rem auto; */\n  margin: auto;\n  margin-bottom: 5rem;\n  background-color: #ffff;\n}\n\n.TextContainer {\n  max-width: 98.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `IndustryUsecases_innerContainer__AkAZ1`,
	"TextContainer": `IndustryUsecases_TextContainer__3RwjJ`
};
export default ___CSS_LOADER_EXPORT___;
