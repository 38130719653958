import React from "react";
import TextHeading from "../../../partials/TextHeading/TextHeading";
import styles from "./ChooseVueporteq.module.css";
import ChooseVueporteqIcon1 from "../../../icons/ChooseVueporteqIcon1";
import parse from "html-react-parser";
import ChooseVueporteqIcon3 from "../../../icons/ChooseVueporteqIcon3";
import ChooseVueporteqIcon2 from "../../../icons/ChooseVueporteqIcon2";

const cardData = [
  {
    icon: <ChooseVueporteqIcon1 />,
    number: "01",
    heading: "18+ Years in Business",
    pay: " Grow your online business <br /> successful",
  },
  {
    icon: <ChooseVueporteqIcon2 />,
    number: "02",
    heading: "100+ in house teams",
    pay: " Grow your online business <br /> successful",
  },
  {
    icon: <ChooseVueporteqIcon3 />,
    number: "03",
    heading: "120+ Satisfied Customers",
    pay: "Find exact business requirement.",
  },
];

const ChooseVueporteq = () => {
  return (
    <section className={styles.borderBottom}>
      <div className={styles.innerContainer}>
        <TextHeading
          heading="Why Choose Vueporteq IT Solutions As your CRM Service Provider"
          text="While mirth large of on front. Ye he greater related adapted proceed entered an. Through it examine express promise no.
                Past add size game cold girl off how old"
        />
        <div className={styles.cardContainer}>
          {cardData.map((item, i) => (
            <div className={styles.cards}>
              <div className={styles.bgblueColor}>{item.icon}</div>
              <div>
                <h3 className={styles.headingNmber}>{item.number}</h3>
                <p className={styles.heading}>{item.heading}</p>
                <p className={`${styles.pay} heading2`}>{parse(item.pay)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ChooseVueporteq;
