// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CrmSystemsCard_borderBottom__JxcA6 {
  border-bottom: 1px solid #e7e7e7;
}

.CrmSystemsCard_innerContainer__F3klU {
  max-width: 150.9rem;
  width: 95%;
  margin: 6rem auto 5rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3.4rem;
}

.CrmSystemsCard_TextContainer__Z2KLl {
  max-width: 76.6rem;
}
.CrmSystemsCard_heading__IF1wq {
  font-weight: 600;
  color: #232323;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
}
.CrmSystemsCard_heading__IF1wq span {
  color: #1cb9c8;
}

.CrmSystemsCard_btn__f3RDV {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1px;
}
.CrmSystemsCard_btn__f3RDV:hover {
  cursor: pointer;
}

.CrmSystemsCard_img__D5xcz {
  max-width: 35.8rem;
}

@media (max-width: 1280px) {
  .CrmSystemsCard_innerContainer__F3klU {
    column-gap: 5rem;
  }
  .CrmSystemsCard_innerContainer__F3klU > div,
  .CrmSystemsCard_innerContainer__F3klU > img {
    max-width: 50%;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .CrmSystemsCard_innerContainer__F3klU {
    flex-direction: column-reverse;
    row-gap: 3rem;
  }
  .CrmSystemsCard_innerContainer__F3klU > div {
    max-width: 100%;
  }
  .CrmSystemsCard_innerContainer__F3klU > img {
    max-width: 55rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CrmPage/CrmSystemsCard/CrmSystemsCard.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,2BAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;;IAEE,cAAc;IACd,WAAW;EACb;AACF;;AAEA;EACE;IACE,8BAA8B;IAC9B,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".borderBottom {\n  border-bottom: 1px solid #e7e7e7;\n}\n\n.innerContainer {\n  max-width: 150.9rem;\n  width: 95%;\n  margin: 6rem auto 5rem auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 3.4rem;\n}\n\n.TextContainer {\n  max-width: 76.6rem;\n}\n.heading {\n  font-weight: 600;\n  color: #232323;\n  font-family: \"Montserrat\", sans-serif;\n  margin-bottom: 2rem;\n  letter-spacing: 0.3px;\n}\n.heading span {\n  color: #1cb9c8;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  color: #ffffff;\n  font-weight: 500;\n  letter-spacing: 1px;\n}\n.btn:hover {\n  cursor: pointer;\n}\n\n.img {\n  max-width: 35.8rem;\n}\n\n@media (max-width: 1280px) {\n  .innerContainer {\n    column-gap: 5rem;\n  }\n  .innerContainer > div,\n  .innerContainer > img {\n    max-width: 50%;\n    width: 100%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .innerContainer {\n    flex-direction: column-reverse;\n    row-gap: 3rem;\n  }\n  .innerContainer > div {\n    max-width: 100%;\n  }\n  .innerContainer > img {\n    max-width: 55rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderBottom": `CrmSystemsCard_borderBottom__JxcA6`,
	"innerContainer": `CrmSystemsCard_innerContainer__F3klU`,
	"TextContainer": `CrmSystemsCard_TextContainer__Z2KLl`,
	"heading": `CrmSystemsCard_heading__IF1wq`,
	"btn": `CrmSystemsCard_btn__f3RDV`,
	"img": `CrmSystemsCard_img__D5xcz`
};
export default ___CSS_LOADER_EXPORT___;
