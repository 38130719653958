import React from "react";
import styles from "./CaseStudiesBgCard.module.css";
import phone1 from "./../../../../assets/img/CaseStudies/Phone1.png";
import phone2 from "./../../../../assets/img/CaseStudies/phone2.png";
import phone3 from "./../../../../assets/img/CaseStudies/phone3.png";
import phone4 from "./../../../../assets/img/CaseStudies/phone4.png";
import phone5 from "./../../../../assets/img/CaseStudies/phone5.png";
import phone6 from "./../../../../assets/img/CaseStudies/phone6.png";
import Blackicon from "./../../../../assets/img/CaseStudies/Blackicon.png";
import CountryIcon from "../../../icons/CountryIcon";
import ProjectIcon from "../../../icons/ProjectIcon";
import { useNavigate } from "react-router-dom";

const cardData = [
  {
    bgColor: "#f9c18a",
    img: phone1,
    ImgWidth: "54.0rem",
    title: "FINTECH",
    heading: "FISERV - ONDOT",
    text: `Constant Upgrade / Deployment of Applicaton on all platforms with
    Quality standards for Banking Apps. Improved delivery times, TAT is
    reduced considerable due to quality and fast development processs`,
    IconBgColor: "#f8F1EA",
    iconImg: ProjectIcon,
    // iconColor: "#F2994A",
    iconHeading: "Project Duration",
    iconText: "9 Years ( Ongoing )",
    iconImg1: CountryIcon,
    IconBgColor1: "#f8F1EA",
    iconColor1: "#F2994A",
    iconHeading1: "Country",
    iconText1: "USA",
    path: "/fiser-vondot",
  },
  {
    bgColor: "#5AABBF",
    img: phone2,
    ImgWidth: "33rem",
    title: "AUTOMOTIVE",
    heading: "PREMON",
    text: `Increased efficiency for OEMs and Auto dealers Interaction of New technology to enhance speed and efficiency of the system. ROI on support is excellent as India team manages critical tasks at reasonable cost.`,
    IconBgColor: "#E7EFF1",
    iconImg: ProjectIcon,
    // iconColor: "#5AABBF",
    iconHeading: "Project Duration",
    iconTextColor: "#5AABBF",
    iconText: "4 Years ( Ongoing )",
    iconImg1: CountryIcon,
    IconBgColor1: "#E7EFF1",
    iconColor1: "#5AABBF",
    iconTextColor1: "#5AABBF",
    iconHeading1: "Country",
    iconText1: "Singapore",
    path: "/premon",
  },
  {
    bgColor: "#4F7E62",
    img: phone3,
    ImgWidth: "45.0rem",
    title: "MEDIA",
    heading: "SPORTS TAK (TVTN)",
    text: `SportsTak is a multisport platform that caters to all your sporting needs international and national. From IPL to Pro Kabaddi League, experience the thrill with SportsTak's comprehensive coverage, including match highlights, player stats, and engaging polls`,
    IconBgColor: "#E6EBE8",
    iconImg: ProjectIcon,
    // iconColor: "red",
    iconHeading: "Project Duration",
    iconTextColor: "#4F7E62",
    iconText: "2 Years ( Completed )",
    iconImg1: CountryIcon,
    IconBgColor1: "#E6EBE8",
    iconColor1: "#4F7E62",
    iconTextColor1: "#4F7E62",
    iconHeading1: "Country",
    iconText1: "India",
    path: "/sport",
  },
  {
    bgColor: "#417394",
    img: phone4,
    ImgWidth: "32rem",
    title: "IOT",
    heading: "MODIFI",
    text: `Create a workflow app in minutes to collect and calibrate task information from throughout your organisation. Use the powerful analytic dashboard system to drive more thoughtful decisions.`,
    IconBgColor: "#E6EBE8",
    iconImg: ProjectIcon,
    // iconColor: "#417394",
    iconHeading: "Project Duration",
    iconTextColor: "#417394",
    iconText: "2 Years ( Completed )",
    iconImg1: CountryIcon,
    IconBgColor1: "#E6EBE8",
    iconColor1: "#417394",
    iconTextColor1: "#417394",
    iconHeading1: "Country",
    iconText1: "India",
    path: "/Modifi",
  },
  {
    bgColor: "#FFF0BD",
    img: phone5,
    ImgWidth: "32rem",
    title: "RECONSTRUCTION",
    heading: "ALL NEW AGAIN",
    text: `All New Again app is a social app for both individuals and entrepreneurs over which they can share their posts, videos, and thoughts and even promote their respective business links.`,
    IconBgColor: "#E6EBE8",
    iconImg: ProjectIcon,
    iconColor: "red",
    iconHeading: "Project Duration",
    iconTextColor: "#FFC604",
    iconText: "7 Years ( Ongoing )",
    iconImg1: CountryIcon,
    IconBgColor1: "#E6EBE8",
    iconColor1: "#ECBA10",
    iconTextColor1: "#FFC604",
    iconHeading1: "Country",
    iconText1: "India",
    path: "/all-new-again",
  },
  {
    bgColor: "#8FC8F8",
    img: phone6,
    ImgWidth: "32rem",
    title: "MEDIA",
    heading: "MILAP",
    text: `Created a Perfect App with all the animation’s and rotation of the newspaper in different direction. Integration of New technology to enhance speed and efficiency of the system.`,
    IconBgColor: "#E6EBE8",
    iconImg: ProjectIcon,
    // iconColor: "red",
    iconHeading: "Project Duration",
    iconTextColor: "#47ABFF",
    iconText: "2 Years ( Ongoing )",
    iconImg1: CountryIcon,
    IconBgColor1: "#E6EBE8",
    iconColor1: "#38A4FF",
    iconTextColor1: "#47ABFF",
    iconHeading1: "Country",
    iconText1: "India",
    path: "/all-new-again",
  },
];

const CaseStudiesBgCard = () => {
  const navigate = useNavigate();
  const NavigateHandle = (path: any) => {
    navigate(path);
  };
  return (
    <section>
      {cardData.map((item, i) => (
        <div className={styles.main}>
          <div
            className={`${styles.BgColorContainer} ${
              (i + 1) % 2 === 0 ? `${styles.rightSide}` : ""
            }`}
            style={{ backgroundColor: item.bgColor }}
          ></div>
          <div
            className={`${styles.innerContainer} ${
              (i + 1) % 2 == 0 ? styles.row_reverse : ""
            }`}
          >
            <img
              src={item.img}
              alt=""
              className={styles.ImgContainer}
              style={{ width: item.ImgWidth }}
            />
            <div className={styles.BgGreyColorContainer}>
              <div className={styles.fontechContainer}>
                <img src={Blackicon} alt="" />
                <p className={`${styles.fintechHeading} heading2 overlay`}>
                  {item.title}
                </p>
              </div>
              <h3 className="body3">{item.heading}</h3>
              <p className={`${styles.text} heading2 marginTop caption`}>
                {item.text}
              </p>

              <div className={styles.cardContainer}>
                <div className={styles.cards}>
                  <div
                    className={styles.bgblueColor}
                    style={{ background: item.IconBgColor }}
                  >
                    <ProjectIcon imgColor1={item.iconColor1} />
                  </div>
                  <div>
                    <p
                      className={styles.heading}
                      style={{ color: item.iconTextColor }}
                    >
                      {item.iconHeading}
                    </p>
                    <p className={`${styles.pay} heading2`}>{item.iconText}</p>
                  </div>
                </div>
                <div className={styles.cards}>
                  <div
                    className={styles.bgblueColor}
                    style={{ background: item.IconBgColor1 }}
                  >
                    <CountryIcon imgColor={item.iconColor1} />
                  </div>
                  <div>
                    <p
                      className={styles.heading}
                      style={{ color: item.iconTextColor1 }}
                    >
                      {item.iconHeading1}
                    </p>
                    <p className={`${styles.pay} heading2`}>{item.iconText1}</p>
                  </div>
                </div>

                <button
                  className={`${styles.btn} btnPrimary overlay`}
                  onClick={() => NavigateHandle(item.path)}
                >
                  View Case study
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default CaseStudiesBgCard;
