// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../../../assets//img//ERP/TechnologyImgCard.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TechnologyImgCard_bgContainer__KWLoG {
  height: 244px;
}
.TechnologyImgCard_bgImg__8dhnT {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.TechnologyImgCard_textHeadingContainer__rXQgQ {
  max-width: 107.3rem;
  margin: auto;
}
.TechnologyImgCard_heading__JTYCM {
  margin-bottom: 2rem;
  color: #fff;
  letter-spacing: 0.3px;
  font-weight: 600;
}

.TechnologyImgCard_btn__ukBJp {
  padding: 1.3rem 4rem;
  margin-top: 1rem;
  background-color: #ffffff;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--color-primary);
  text-transform: uppercase;
}
.TechnologyImgCard_btn__ukBJp:hover {
  cursor: pointer;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/NssplMtcPage/MtcPageComponents/TechnologyImgCard/TechnologyImgCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,yDAAgF;EAChF,YAAY;EACZ,4BAA4B;EAC5B,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".bgContainer {\n  height: 244px;\n}\n.bgImg {\n  background-image: url(\"./../../../../../assets//img//ERP/TechnologyImgCard.png\");\n  height: 100%;\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.textHeadingContainer {\n  max-width: 107.3rem;\n  margin: auto;\n}\n.heading {\n  margin-bottom: 2rem;\n  color: #fff;\n  letter-spacing: 0.3px;\n  font-weight: 600;\n}\n\n.btn {\n  padding: 1.3rem 4rem;\n  margin-top: 1rem;\n  background-color: #ffffff;\n  font-weight: 500;\n  letter-spacing: 1px;\n  color: var(--color-primary);\n  text-transform: uppercase;\n}\n.btn:hover {\n  cursor: pointer;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgContainer": `TechnologyImgCard_bgContainer__KWLoG`,
	"bgImg": `TechnologyImgCard_bgImg__8dhnT`,
	"textHeadingContainer": `TechnologyImgCard_textHeadingContainer__rXQgQ`,
	"heading": `TechnologyImgCard_heading__JTYCM`,
	"btn": `TechnologyImgCard_btn__ukBJp`
};
export default ___CSS_LOADER_EXPORT___;
