import React from "react";
import img from "./../../../../assets/img/ContactForm.png";
import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";

const ContactHeader = () => {
  return (
    <BgContainerImg
      btns={["HOME ", "PRODUCTS", "CONTACT US"]}
      img={img}
      heading="Conntact US"
      isTab={false}
    />
  );
};

export default ContactHeader;
