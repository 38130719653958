// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpportunityManagement_section__Qp4Zq {
  position: relative;
  overflow: hidden;
}
.OpportunityManagement_innerContainer__CDBlA {
  max-width: 145rem;
  width: 80%;
  margin: 10rem auto 7rem auto;
  display: flex;
  align-items: center;
  column-gap: 17rem;
}

.OpportunityManagement_imageContainer__U-Npp {
  max-width: 55.6rem;
  width: 50%;
  margin: auto;
}
.OpportunityManagement_imageContainer__U-Npp > img {
  width: 100%;
}

/* .effect {
  position: absolute;
  max-width: 50rem;
  z-index: -1;
  right: -4%;
  bottom: 0%;
} */

.OpportunityManagement_effectBorderImg__Q40QD {
  width: 37rem;
  height: 100rem;
  position: absolute;
  max-width: 50rem;
  z-index: -1;
  right: -9%;
  bottom: 10%;
  background-color: #1cb9c84f;
  transform: rotate(-45deg);
  top: 36px;
  border-radius: 10px;
}
.OpportunityManagement_textContainer__14Rmv {
  max-width: 61rem;
  padding-top: 1.2rem;
  width: 50%;
}
.OpportunityManagement_heading__zsvum {
  font-weight: 600;
  margin-bottom: 2.6rem;
}

.OpportunityManagement_aboutDescriptionText__910F\\+ {
  color: var(--gray-666);
  line-height: 2.74rem;
  margin-bottom: 1rem;
}

.OpportunityManagement_pointItem__W56-K {
  display: flex;
  column-gap: 1.6rem;
  align-items: center;
}
.OpportunityManagement_border__0E5U8 {
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/nssplPartials/OpportunityManagementCard/OpportunityManagement.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,UAAU;EACV,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;AACA;EACE,WAAW;AACb;;AAEA;;;;;;GAMG;;AAEH;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,UAAU;EACV,WAAW;EACX,2BAA2B;EAC3B,yBAAyB;EACzB,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,WAAW;AACb","sourcesContent":[".section {\n  position: relative;\n  overflow: hidden;\n}\n.innerContainer {\n  max-width: 145rem;\n  width: 80%;\n  margin: 10rem auto 7rem auto;\n  display: flex;\n  align-items: center;\n  column-gap: 17rem;\n}\n\n.imageContainer {\n  max-width: 55.6rem;\n  width: 50%;\n  margin: auto;\n}\n.imageContainer > img {\n  width: 100%;\n}\n\n/* .effect {\n  position: absolute;\n  max-width: 50rem;\n  z-index: -1;\n  right: -4%;\n  bottom: 0%;\n} */\n\n.effectBorderImg {\n  width: 37rem;\n  height: 100rem;\n  position: absolute;\n  max-width: 50rem;\n  z-index: -1;\n  right: -9%;\n  bottom: 10%;\n  background-color: #1cb9c84f;\n  transform: rotate(-45deg);\n  top: 36px;\n  border-radius: 10px;\n}\n.textContainer {\n  max-width: 61rem;\n  padding-top: 1.2rem;\n  width: 50%;\n}\n.heading {\n  font-weight: 600;\n  margin-bottom: 2.6rem;\n}\n\n.aboutDescriptionText {\n  color: var(--gray-666);\n  line-height: 2.74rem;\n  margin-bottom: 1rem;\n}\n\n.pointItem {\n  display: flex;\n  column-gap: 1.6rem;\n  align-items: center;\n}\n.border {\n  border-bottom: 1px solid #e7e7e7;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `OpportunityManagement_section__Qp4Zq`,
	"innerContainer": `OpportunityManagement_innerContainer__CDBlA`,
	"imageContainer": `OpportunityManagement_imageContainer__U-Npp`,
	"effectBorderImg": `OpportunityManagement_effectBorderImg__Q40QD`,
	"textContainer": `OpportunityManagement_textContainer__14Rmv`,
	"heading": `OpportunityManagement_heading__zsvum`,
	"aboutDescriptionText": `OpportunityManagement_aboutDescriptionText__910F+`,
	"pointItem": `OpportunityManagement_pointItem__W56-K`,
	"border": `OpportunityManagement_border__0E5U8`
};
export default ___CSS_LOADER_EXPORT___;
