import React from "react";
import Slider from "react-slick";
import styles from "./../../HomePage/Testimonial/Testimonial.module.css";

import fintechIcon from "../../../../assets/img/testimonial/fintech-icon.svg";
import socialMedia from "../../../../assets/img/testimonial/social-media-icon.svg";
import healthIcon from "../../../../assets/img/testimonial/health-care-icon.svg";
import edTechIcon from "../../../../assets/img/testimonial/online-learning-icon.svg";
import transportIcon from "../../../../assets/img/testimonial/shipped-icon.svg";
import ArrowRight from "../../../icons/ArrowRight";
import ArrowLeft from "../../../icons/ArrowLeft";
import TextHeading from "../../../partials/TextHeading/TextHeading";

const data = [
  {
    icon: fintechIcon,
    title: "Fintech",
    link: "#",
  },
  {
    icon: socialMedia,
    title: "Social Media",
    link: "#",
  },
  {
    icon: healthIcon,
    title: "Health",
    link: "#",
  },
  {
    icon: edTechIcon,
    title: "Ed-tech",
    link: "#",
  },
  {
    icon: transportIcon,
    title: "Transport",
    link: "#",
  },
];

const Card: React.FC<{ icon: string; title: string }> = ({ icon, title }) => {
  return (
    <div className={styles.card}>
      <img src={icon} alt="icon" className={styles.cardImg} />
      <h6 className={`body2 ${styles.cardHeading}`}>{title}</h6>
      {/* <a href="#" className={`overlay ${styles.readMoreBtn}`}>
        Read More
      </a> */}
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: (
    <button id="sliderBtn">
      <ArrowRight />
    </button>
  ),
  prevArrow: (
    <button id="sliderBtn">
      <ArrowLeft />
    </button>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const VerticalTestimonial = () => {
  return (
    <section className={styles.section}>
      <div className={styles.innerContainer}>
        <TextHeading heading="Vertical Exposure" text="" />
        <Slider {...settings}>
          {data.map((item, i) => (
            <Card key={i} {...item} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default VerticalTestimonial;
