// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SeoServices_innerContainer__sF5um {
  max-width: 150.9rem;
  width: 90%;
  margin: auto;
  padding: 5rem 0px;
}

.SeoServices_imgContainer__rxVju {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
}
.SeoServices_card__Ef-IU {
  max-width: 30rem;
  width: 100%;
  background-color: #ffffff;
  padding: 3rem;
  box-shadow: 1px 4px 20px -2px #0000001a;
  /* /margin-bottom: 1rem;
  /border-bottom: 2px solid #1cb9c8; */
  border-radius: 10px;
}

.SeoServices_text__vnJvJ {
  color: #232323;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.SeoServices_pay__uEoiF {
  font-size: 1.2rem;
  margin-bottom: 0%;
}

/* /AVIDIAN CRM section /  */
.SeoServices_bgblueColor__O\\+A97 {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f2f2f2;
  margin-bottom: 1.5rem;
}

@media (max-width: 1024px) {
  .SeoServices_imgContainer__rxVju {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    width: 95%;
  }
}

@media (max-width: 768px) {
  .SeoServices_imgContainer__rxVju {
    grid-template-columns: repeat(2, 1fr);
  }
  .SeoServices_innerContainer__sF5um {
    padding: 2rem 0;
  }
}

@media (max-width: 480px) {
  .SeoServices_imgContainer__rxVju {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/SEO/SeoServices/SeoServices.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sCAAsC;EACtC,SAAS;AACX;AACA;EACE,gBAAgB;EAChB,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,uCAAuC;EACvC;sCACoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA,4BAA4B;AAC5B;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE;IACE,qCAAqC;IACrC,qBAAqB;IACrB,UAAU;EACZ;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".innerContainer {\n  max-width: 150.9rem;\n  width: 90%;\n  margin: auto;\n  padding: 5rem 0px;\n}\n\n.imgContainer {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 2rem;\n}\n.card {\n  max-width: 30rem;\n  width: 100%;\n  background-color: #ffffff;\n  padding: 3rem;\n  box-shadow: 1px 4px 20px -2px #0000001a;\n  /* /margin-bottom: 1rem;\n  /border-bottom: 2px solid #1cb9c8; */\n  border-radius: 10px;\n}\n\n.text {\n  color: #232323;\n  margin-bottom: 0.5rem;\n  margin-top: 0.5rem;\n}\n.pay {\n  font-size: 1.2rem;\n  margin-bottom: 0%;\n}\n\n/* /AVIDIAN CRM section /  */\n.bgblueColor {\n  width: 6rem;\n  height: 6rem;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 2px solid #f2f2f2;\n  margin-bottom: 1.5rem;\n}\n\n@media (max-width: 1024px) {\n  .imgContainer {\n    grid-template-columns: repeat(3, 1fr);\n    justify-items: center;\n    width: 95%;\n  }\n}\n\n@media (max-width: 768px) {\n  .imgContainer {\n    grid-template-columns: repeat(2, 1fr);\n  }\n  .innerContainer {\n    padding: 2rem 0;\n  }\n}\n\n@media (max-width: 480px) {\n  .imgContainer {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `SeoServices_innerContainer__sF5um`,
	"imgContainer": `SeoServices_imgContainer__rxVju`,
	"card": `SeoServices_card__Ef-IU`,
	"text": `SeoServices_text__vnJvJ`,
	"pay": `SeoServices_pay__uEoiF`,
	"bgblueColor": `SeoServices_bgblueColor__O+A97`
};
export default ___CSS_LOADER_EXPORT___;
