import React from "react";

const FeaturesIcon2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.944 1.25H13.056C14.894 1.25 16.35 1.25 17.489 1.403C18.661 1.561 19.61 1.893 20.359 2.641C21.107 3.39 21.439 4.339 21.597 5.511C21.75 6.651 21.75 8.106 21.75 9.944V14.056C21.75 15.894 21.75 17.35 21.597 18.489C21.439 19.661 21.107 20.61 20.359 21.359C19.61 22.107 18.661 22.439 17.489 22.597C16.349 22.75 14.894 22.75 13.056 22.75H10.944C9.106 22.75 7.65 22.75 6.511 22.597C5.339 22.439 4.39 22.107 3.641 21.359C2.893 20.61 2.561 19.661 2.403 18.489C2.25 17.349 2.25 15.894 2.25 14.056V9.944C2.25 8.106 2.25 6.65 2.403 5.511C2.561 4.339 2.893 3.39 3.641 2.641C4.39 1.893 5.339 1.561 6.511 1.403C7.651 1.25 9.106 1.25 10.944 1.25ZM6.71 2.89C5.704 3.025 5.124 3.279 4.7 3.702C4.278 4.125 4.024 4.705 3.889 5.711C3.751 6.739 3.749 8.093 3.749 10V14C3.749 15.907 3.751 17.262 3.889 18.29C4.024 19.295 4.278 19.875 4.701 20.298C5.124 20.721 5.704 20.975 6.71 21.11C7.738 21.248 9.092 21.25 10.999 21.25H12.999C14.906 21.25 16.261 21.248 17.289 21.11C18.294 20.975 18.874 20.721 19.297 20.298C19.72 19.875 19.974 19.295 20.109 18.289C20.247 17.262 20.249 15.907 20.249 14V10C20.249 8.093 20.247 6.739 20.109 5.71C19.974 4.705 19.72 4.125 19.297 3.702C18.874 3.279 18.294 3.025 17.288 2.89C16.261 2.752 14.906 2.75 12.999 2.75H10.999C9.092 2.75 7.739 2.752 6.71 2.89ZM7.25 10C7.25 9.80109 7.32902 9.61032 7.46967 9.46967C7.61032 9.32902 7.80109 9.25 8 9.25H16C16.1989 9.25 16.3897 9.32902 16.5303 9.46967C16.671 9.61032 16.75 9.80109 16.75 10C16.75 10.1989 16.671 10.3897 16.5303 10.5303C16.3897 10.671 16.1989 10.75 16 10.75H8C7.80109 10.75 7.61032 10.671 7.46967 10.5303C7.32902 10.3897 7.25 10.1989 7.25 10ZM7.25 14C7.25 13.8011 7.32902 13.6103 7.46967 13.4697C7.61032 13.329 7.80109 13.25 8 13.25H13C13.1989 13.25 13.3897 13.329 13.5303 13.4697C13.671 13.6103 13.75 13.8011 13.75 14C13.75 14.1989 13.671 14.3897 13.5303 14.5303C13.3897 14.671 13.1989 14.75 13 14.75H8C7.80109 14.75 7.61032 14.671 7.46967 14.5303C7.32902 14.3897 7.25 14.1989 7.25 14Z"
        fill="#721CC8"
      />
    </svg>
  );
};

export default FeaturesIcon2;
