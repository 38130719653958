import React from "react";

const LinkedinIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2008_520)">
        <path
          d="M3.6283 15V4.87953H0.197992V15H3.6283ZM1.91359 3.49692C3.10979 3.49692 3.85438 2.71978 3.85438 1.74862C3.83209 0.755565 3.10984 0 1.93629 0C0.762918 0 -0.00439453 0.75558 -0.00439453 1.74862C-0.00439453 2.71983 0.740004 3.49692 1.89119 3.49692H1.91348H1.91359ZM5.52696 15H8.95726V9.34824C8.95726 9.04576 8.97955 8.7436 9.07013 8.52738C9.31812 7.92304 9.88254 7.29712 10.8301 7.29712C12.0714 7.29712 12.568 8.2252 12.568 9.5857V14.9999H15.9981V9.19695C15.9981 6.08833 14.3058 4.64193 12.0488 4.64193C10.1983 4.64193 9.38582 5.65626 8.93444 6.34711H8.95734V4.87932H5.52703C5.57205 5.82897 5.52703 14.9998 5.52703 14.9998L5.52696 15Z"
          fill="#131313"
        />
      </g>
      <defs>
        <clipPath id="clip0_2008_520">
          <rect
            width="16"
            height="15"
            fill="white"
            transform="translate(-0.00439453)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedinIcon;
