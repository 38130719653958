import React from "react";
import BgContainerImg from "../../partials/BgContainerCard/BgContainerImg";
import img from "./../../../assets/img/avidianCrm/avidianCrmHeader.png";

const AvidianCrmHeader = () => {
  return (
    <>
      <BgContainerImg
        btns={["HOME ", "PRODUCTS", "AVIDIAN CRM"]}
        img={img}
        heading="AVIDIAN CRM"
        isTab={true}
      />
    </>
  );
};

export default AvidianCrmHeader;
