import styles from "./AvidianEmbeddedOutlook.module.css";

import EmbeddedOutlookCard from "../../../partials/EmbeddedOutlook/EmbeddedOutlookCard";
import OpportunityManagementCard from "../../../partials/OpportunityManagementCard/OpportunityManagementCard";
import BulletPointIcon from "../../../icons/BulletPointIcon";

import Embedded from "./../../../../assets/img/avidian/cards-img/img-1.png";
import Reporting from "./../../../../assets/img/avidianCrm/Reporting.png";
import DocumentImg from "./../../../../assets/img/avidianCrm/Document.png";
import effectImg from "./../../../../assets/img/avidianCrm/EffectCircle.png";
import img1 from "./../../../../assets/img/avidianCrm/opportunity.png";
import card2 from "./../../../../assets/img/avidian/cards-img/img-2.png";
import card3 from "./../../../../assets/img/avidian/cards-img/img-3.png";
import card4 from "./../../../../assets/img/avidian/cards-img/img-4.png";

const points = [
  {
    text: "Reporting and analytics dashboards",
    icon: <BulletPointIcon />,
  },
  {
    text: "Document generation",
    icon: <BulletPointIcon />,
  },
  {
    text: "Sales automation and workflow",
    icon: <BulletPointIcon />,
  },
  {
    text: "CRM mobile app",
    icon: <BulletPointIcon />,
  },
];

const AvidianEmbeddedOutlook = () => {
  return (
    <>
      <EmbeddedOutlookCard
        img={Embedded}
        effectImg={effectImg}
        Heading="Avid CRM"
        points={points}
        innerContainerClasses={styles.embeddedInnerContainer}
        pointsContainerClasses={styles.embeddedPointsContainer}
        textContainerClasses={styles.embeddedTextContainer}
        text="Avid CRM is an easy-to-use, fast-to-implement CRM and contact manager embedded in Microsoft Outlook.It de-clutters and expedites your workflow with intuitive features such as:"
      />
      <OpportunityManagementCard
        hideImgEffect
        showRoundedEffect
        img={card2}
        Heading="Avid DocuWorks"
        text="Avidian’s ultra secure cloud-based solutions address the issue of data security to remove a significant barrier in the adoption of an aggressive cloud strategy. Avidian’s solutions are ideal for any organization that must meet high regulatory standards (such as HIPAA, SOX and GLBA) regarding data privacy and documentation."
      />
      <EmbeddedOutlookCard
        innerContainerClasses={styles.embeddedCard3InnerContainer}
        img={card3}
        effectImg={""}
        Heading="Avid WEB CONFERENCES"
        text="The key is Collaboration among all of the participants. Being able to share documents, have open dialogue, sharing an electronic whiteboard and creating projects and tasks to manage takeaways from that meeting. Then, going a step further, using Avidian’s video conferencing capabilities, participants can see one another’s face, gestures and body language to create the necessary energy and teamwork that will drive results."
      />
      <OpportunityManagementCard
        innerContainerClasses={styles.mobileCardInnerContainer}
        img={card4}
        hideImgEffect
        Heading="Avid ERP"
        text="Avid ERP has a wide range of feature suitable for many business particularly distributed businesses in a wholesale, distribution and manufacturing environment. When combined with a 3rd party interactive desktop Point of Sale System with a Rewards program it can form the hub of a dispersed multi-branch retail management system."
      />
    </>
  );
};

export default AvidianEmbeddedOutlook;
