// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pricing_innerContainer__sRWjp {
  margin-top: 6rem;
}

@media (max-width: 640px) {
  .Pricing_innerContainer__sRWjp {
    margin-top: 4rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/app/CrmPage/Pricing/Pricing.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".innerContainer {\n  margin-top: 6rem;\n}\n\n@media (max-width: 640px) {\n  .innerContainer {\n    margin-top: 4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `Pricing_innerContainer__sRWjp`
};
export default ___CSS_LOADER_EXPORT___;
