import React from "react";

const ComputerVisionIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5175 2.5C7.795 2.5 5.43375 2.5 3.9675 3.965C2.50125 5.43 2.5 7.78625 2.5 12.5C2.5 17.2138 2.5 19.5713 3.9675 21.035C5.435 22.4988 7.795 22.5 12.5175 22.5H17.5263C22.2488 22.5 24.61 22.5 26.0763 21.035C27.0888 20.025 27.4025 18.59 27.5 16.25M15 22.5V27.5M10 27.5H20M13.75 18.75H16.25"
        stroke="#1BA9B7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.375 12.5V15M23.125 12.5V15M19.375 2.5V5M23.125 2.5V5M17.5 6.875H15M17.5 10.625H15M27.5 6.875H25M27.5 10.625H25M22.5 5H20C18.8213 5 18.2325 5 17.8663 5.36625C17.5 5.7325 17.5 6.32125 17.5 7.5V10C17.5 11.1788 17.5 11.7675 17.8663 12.1338C18.2325 12.5 18.8213 12.5 20 12.5H22.5C23.6788 12.5 24.2675 12.5 24.6338 12.1338C25 11.7675 25 11.1788 25 10V7.5C25 6.32125 25 5.7325 24.6338 5.36625C24.2675 5 23.6788 5 22.5 5Z"
        stroke="#1BA9B7"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ComputerVisionIcon;
