// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImgTextFlexComponent_innerContainer__dXls4 {
  max-width: 145rem;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  column-gap: 5rem;
  row-gap: 2rem;
}

.ImgTextFlexComponent_imageContainer__m63GK {
  max-width: 58.6rem;
  width: 50%;
  /* / margin: auto; / */
}
.ImgTextFlexComponent_imageContainer__m63GK > img {
  width: 100%;
}

.ImgTextFlexComponent_textContainer__v7XkM {
  max-width: 61.6rem;
  width: 50%;
}

.ImgTextFlexComponent_aboutTitle__VLEMG {
  color: var(--color-primary);
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.ImgTextFlexComponent_heading__zdZvL {
  font-weight: 600;
  margin-bottom: 2.6rem;
}

.ImgTextFlexComponent_aboutDescriptionText__EHnLE {
  color: var(--gray-666);
  line-height: 2.74rem;
}
.ImgTextFlexComponent_aboutDescription__4I-Nj {
  margin-bottom: 2.2rem;
}

.ImgTextFlexComponent_pointsContainer__ZnFq7 {
  display: flex;
  flex-direction: column;
  row-gap: 2.6rem;
  margin-bottom: 4rem;
}

.ImgTextFlexComponent_pointItem__kvAQ1 {
  display: flex;
  column-gap: 1.8rem;
}

.ImgTextFlexComponent_pointItemHeading__NeIyx {
  font-weight: 600;
  margin-bottom: 1rem;
}

@media (max-width: 1024px) {
  .ImgTextFlexComponent_innerContainer__dXls4 {
    width: 95%;
  }
}
@media (max-width: 800px) {
  .ImgTextFlexComponent_innerContainer__dXls4 {
    flex-direction: column;
    align-items: center;
  }
  .ImgTextFlexComponent_textContainer__v7XkM {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/partials/ImgTextFlexComponent/ImgTextFlexComponent.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;AACtB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".innerContainer {\n  max-width: 145rem;\n  width: 90%;\n  margin: auto;\n  display: flex;\n  justify-content: space-between;\n  column-gap: 5rem;\n  row-gap: 2rem;\n}\n\n.imageContainer {\n  max-width: 58.6rem;\n  width: 50%;\n  /* / margin: auto; / */\n}\n.imageContainer > img {\n  width: 100%;\n}\n\n.textContainer {\n  max-width: 61.6rem;\n  width: 50%;\n}\n\n.aboutTitle {\n  color: var(--color-primary);\n  font-weight: bold;\n  margin-bottom: 1.2rem;\n}\n\n.heading {\n  font-weight: 600;\n  margin-bottom: 2.6rem;\n}\n\n.aboutDescriptionText {\n  color: var(--gray-666);\n  line-height: 2.74rem;\n}\n.aboutDescription {\n  margin-bottom: 2.2rem;\n}\n\n.pointsContainer {\n  display: flex;\n  flex-direction: column;\n  row-gap: 2.6rem;\n  margin-bottom: 4rem;\n}\n\n.pointItem {\n  display: flex;\n  column-gap: 1.8rem;\n}\n\n.pointItemHeading {\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n\n@media (max-width: 1024px) {\n  .innerContainer {\n    width: 95%;\n  }\n}\n@media (max-width: 800px) {\n  .innerContainer {\n    flex-direction: column;\n    align-items: center;\n  }\n  .textContainer {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `ImgTextFlexComponent_innerContainer__dXls4`,
	"imageContainer": `ImgTextFlexComponent_imageContainer__m63GK`,
	"textContainer": `ImgTextFlexComponent_textContainer__v7XkM`,
	"aboutTitle": `ImgTextFlexComponent_aboutTitle__VLEMG`,
	"heading": `ImgTextFlexComponent_heading__zdZvL`,
	"aboutDescriptionText": `ImgTextFlexComponent_aboutDescriptionText__EHnLE`,
	"aboutDescription": `ImgTextFlexComponent_aboutDescription__4I-Nj`,
	"pointsContainer": `ImgTextFlexComponent_pointsContainer__ZnFq7`,
	"pointItem": `ImgTextFlexComponent_pointItem__kvAQ1`,
	"pointItemHeading": `ImgTextFlexComponent_pointItemHeading__NeIyx`
};
export default ___CSS_LOADER_EXPORT___;
