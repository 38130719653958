import React from "react";
import styles from "./../../../../../components/app/SEO/SeoServices/SeoServices.module.css";
import TextHeading from "../../../../partials/TextHeading/TextHeading";
import ErpItSolution3 from "../../../../icons/ErpIcons/ErpItSolution3";
import FeaturesIcon1 from "../../../../icons/FeaturesIcon1";
import FeaturesIcon2 from "../../../../icons/FeaturesIcon2";
import FeaturesIcon3 from "../../../../icons/FeaturesIcon3";

const data = [
  {
    icon: <FeaturesIcon1 />,
    heading: "Reporting and analytics dashboards",
    pay: "Users can monitor their cloud resources in real-time and access analytics to gain insights",
    BgColor: "#B9E8F2",
  },
  {
    icon: <FeaturesIcon2 />,
    heading: "Document generation",
    pay: "Uploading, organizing, and managing files in the cloud, including folders, file sharing, and version control.",
    BgColor: "#EEDDFF",
  },
  {
    icon: <ErpItSolution3 />,
    heading: "Sales automation and workflow",
    pay: "The product ensures accessibility for users with disabilities and promotes inclusivity in its design and features.",
    BgColor: "#DDE0FF",
  },
  {
    icon: <FeaturesIcon3 />,
    heading: "CRM mobile app",
    pay: "The product ensures accessibility for users with disabilities and promotes inclusivity in its design and features.",
    BgColor: "#DDFFDE",
  },
];
const Features = () => {
  return (
    <section style={{ backgroundColor: "#F7F7F7" }}>
      <div className={styles.innerContainer}>
        <TextHeading
          heading="Features"
          text="Whether you’re looking to fill gaps or find a turn-key solution, our customizable search engine optimization and social media marketing services can help."
        />
        <div className={styles.imgContainer}>
          {data.map((item) => (
            <div className={styles.card}>
              <div
                className={styles.bgblueColor}
                style={{ background: item.BgColor }}
              >
                {item.icon}
              </div>
              <h4 className={`${styles.text} body1 heading2`}>
                {item.heading}
              </h4>
              <p className={`${styles.pay} heading2`}>{item.pay}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
