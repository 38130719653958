import React from "react";
import styles from "../styles/MtcPage.module.css";
import MtcPageHeader from "../components/app/Products/MtcPageComponents/MtcPageHeader/MtcPageHeader";
import MtcPageAboutSection from "../components/app/Products/MtcPageComponents/MtcPageAboutSection/MtcPageAboutSection";
import MtcHelpSection from "../components/app/Products/MtcPageComponents/MtcHelpSection/MtcHelpSection";
import MtcQuoteForm from "../components/app/Products/MtcPageComponents/MtcHelpSection/MtcQuoteForm/MtcQuoteForm";
import TechnologyImgCard from "../components/app/ErpPage/TechnologyImgCard/TechnologyImgCard";
import MtcAvidianCrm from "../components/app/Products/MtcPageComponents/MtcAvidianCrm/MtcAvidianCrm";

const MtcPage = () => {
  return (
    <>
      <MtcPageHeader />
      <MtcPageAboutSection />
      <MtcHelpSection />
      <TechnologyImgCard
        bgImageClass={styles.technologyCardImg}
        text="Experience seamless integration with our partner brands, ensuring optimal performance and collaboration on the <span className='colorPrimary' >MTC platform</span>."
        btnText="View plans"
      />
      <MtcQuoteForm />
      <MtcAvidianCrm />
    </>
  );
};

export default MtcPage;
