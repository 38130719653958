import React from "react";
import styles from "./DecisionMaking.module.css";
import img from "./../../../../../assets/img/Aiml/DecisionMaking.png";
import { Link } from "react-router-dom";

const DecisionMaking = () => {
  return (
    <section>
      <div className={styles.innerContainer}>
        <div className={styles.TextContainer}>
          <h3 className={styles.heading}>
            Leverage AI & ML Solutions for Process Automation and Smart
            Decision-Making
          </h3>
          <h4 className="heading2 caption">
            With Vueporteq’s Artificial Intelligence & Machine Learning
            services, you can automate processes, strengthen customer relations,
            leverage enhanced analytics, and future-proof your business
            processes; ensuring you stay relevant to the evolving market and
            customer needs. Leveraging our AI & ML consulting and solutions, the
            possibilities become endless, where you can draw new insights and
            tap into new business revenue streams by making the best use of your
            data.
          </h4>
          <Link to={"/contact"}>
            <button className={`btnPrimary ${styles.btn}`}>GET STARTED</button>
          </Link>
        </div>
      </div>
      <div className={styles.imgContainer}>
        <img src={img} alt="" className={styles.img} />
        <div className={styles.bgColor}>
          <p className={styles.heading2}>Vueporteq as your partner</p>
          <p className={styles.heading2}>
            With Vueporteq as your partner, we can leap your organization
            forward with highly effective IT strategies and implement innovative
            Cloud solutions.With Vueporteq as your partner, we can leap your
            organization forward with highly effective IT strategies and
            implement innovative Cloud solutions. With Vueporteq as your
            partner, we can leap your organization forward with highly effective
            IT strategies and implement innovative Cloud solutions.
          </p>
        </div>
      </div>
    </section>
  );
};

export default DecisionMaking;
