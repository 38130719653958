import React from "react";
import ContactForm from "../components/app/ContactPage/ContactForm";
import ContactHeader from "../components/app/HomePage/Contact/ContactHeader";

const ContactPage = () => {
  return (
    <>
      <ContactHeader />
      <ContactForm />
    </>
  );
};

export default ContactPage;
