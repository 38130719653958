import React from "react";
import styles from "./Modifi.module.css";
import BgContainerImg from "../../../partials/BgContainerCard/BgContainerImg";
import CaseStudyPageComponet, {
  CaseStudyFlexComponet,
} from "../CaseStudyPageComponet/CaseStudyPageComponet";
import img from "./../../../../assets/img/CaseStudies/modifi/header.png";
import Antivirus from "../../../icons/Antivirus";
import NodeJsIcon from "../../../icons/CaseStudy/NodeJsIcon";
import Azure from "../../../icons/CaseStudy/Azure";
import Apple from "../../../icons/Apple";
import MySql from "../../../icons/CaseStudy/MySql";
import Aws from "../../../icons/CaseStudy/Aws";
import imgBlue from "./../../../../assets/img/CaseStudies/imgBlue.png";
import img1 from "./../../../../assets/img/CaseStudies/modifi/img1.png";
import img2 from "./../../../../assets/img/CaseStudies/modifi/img2.png";
import img3 from "./../../../../assets/img/CaseStudies/modifi/img3.png";
import img4 from "./../../../../assets/img/CaseStudies/modifi/img4.png";

import massage from "./../../../../assets/img/mtc/Security.svg";
import project from "./../../../../assets/img/mtc/Security.svg";
import Workflow from "./../../../../assets/img/mtc/Security.svg";
import Calendar from "./../../../../assets/img/mtc/Security.svg";
import Security from "./../../../../assets/img/mtc/Security.svg";
import File from "./../../../../assets/img/mtc/Security.svg";

const ImgTextData = [
  {
    problem: "01",
    problemHeading: "problem",
    img: img2,
    circleBgColor: "#f9c18a85",
    heading: "Problem",
    IconColor: "#5AABBF",
    list: [
      "Create 'Work Flow' templates with pre-set components using web platform",
      "Publish the template on Android and IOS Platforms as mobile Application using IOT",
      "Address Data Publishing challenge by keeping connection open for publishing data through IOT using MQTT",
      "Manage huge data size spanning 2-3 days",
      "Implement offline and online connectivity management with data sync",
    ],
  },
  {
    problem: "02",
    problemHeading: "Solutions",
    img: img2,
    circleBgColor: "#f9c18a85",
    heading: "Solutions",
    IconColor: "#5AABBF",
    list: [
      "Development of the Android / IOS based mobile application",
      "Tight sprints for support and development",
      "Implement analytics module to track workflow and data on web and mobile",
      "Project Live, duration 6 months",
      "Ensure client and NSS development teams work seamlessly",
      "Handle extensive data processing and client engagements",
    ],
  },
];
const Data = [
  {
    img: massage,
    heading: "Real time communications",
    pay: `Flawless user ready mobile application`,
    BgColor: "#EDEBFD",
  },
  {
    img: project,
    heading: "Task and project management",
    pay: " Increased efficiency for OEMs and Auto dealers",
    BgColor: "#E5FFEC",
  },
  {
    img: Workflow,
    heading: "Workflow Automation",
    pay: `Integration of New technology to enhance speed and efficiency of the system.`,
    BgColor: "#FFF1F1",
  },
];
const Data1 = [
  {
    img: File,
    heading: "File sharing and document collaboration",
    pay: ` ROI on support is excellent as India team manages critical tasks at reasonable cost`,
    BgColor: "#FFEFDD",
  },
  {
    img: Calendar,
    heading: "Calendar and scheduling",
    pay: `Premon got many big car manufacturers on board based on our quality work`,
    BgColor: "#EAEAEA",
  },
  {
    img: Security,
    heading: "Access Control and Security",
    pay: `Robust security measures, such as encryption, access controls, and
      user authentication, help safeguard sensitive information and ensure compliance with data privacy
      regulations.`,
    BgColor: "#fff8EA",
  },
];
const Modifi = () => {
  return (
    <section className={styles.section}>
      <BgContainerImg
        img={img}
        heading="MODIFI"
        isTab={true}
        btns={["HOME ", "CASE STUDIES", "MODIFI"]}
      />
      <div className={`${styles.InnerContainer} ${styles.FISERV}`}>
        <CaseStudyFlexComponet
          heading="MODIFI"
          heading1="Project Overview"
          text="Create a workflow app in minutes to collect and calibrate task information from throughout your organisation. Use the powerful analytic dashboard system to drive more thoughtful decisions."
          color={{
            mainHeading: "#417394",
            manHeadingLine: "#417394",
            heading: "#417394",
            iconBg: "#ECF1F4",
            // iconColor: "#ff9955",
            imgColor: "#417394",
          }}
          iconLog={[
            <Antivirus />,
            <Apple />,
            <NodeJsIcon />,
            <Azure />,
            <MySql />,
            <Aws />,
          ]}
        />
        <div className={styles.imgContainer}>
          <img src={img1} alt="" className="" />
        </div>
      </div>
      <CaseStudyPageComponet
        ImgTextData={ImgTextData}
        Data1={Data1}
        Data={Data}
        color={{
          problem: "#5AABBF",
          circle: "#5AABBF59",
          img: img3,
          ScreenshotsImg: img4,
          processIconColor: "#417394",
        }}
        imgBlue={imgBlue}
      />
    </section>
  );
};

export default Modifi;
