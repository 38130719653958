import React from "react";
import TextHeading from "../../../partials/TextHeading/TextHeading";
import styles from "./Pricing.module.css";

const Pricing = () => {
  return (
    <div className={styles.innerContainer}>
      <TextHeading
        heading="Pricing"
        text="Our broader objective is to make quality tech available, accessible, and affordable to all. In doing so, we meet the needs of organizations of all sizes."
      />
    </div>
  );
};

export default Pricing;
